import React, { useEffect, useState } from "react";
import { GiBowlOfRice, GiSushis, GiHotMeal } from "react-icons/gi";
import SubscribePlanCard from "../../components/elements/card/subscribe-plan-card";
import Container from "../../components/layout/container";
import { Get } from "../../services";

export default function Subscription() {
  const [isLoading, setIsLoading] = useState(true);
  const [planList, setPlanList] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    Get(`/subscription/listing`)
      .then((response) => {
        if (response.code_status === 200) {
          setPlanList(response.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  }, []);

  return (
    <Container loading={isLoading} seo={{ title: "FoodHeart | Subscribe" }}>
      <div className="py-10 sm:py-16 px-6 sm:px-12 overflow-hidden">
        <div className="w-full max-w-container1200 mx-auto relative flex flex-col">
          <h1 className="text-2xl sm:text-3xl font-bold text-center mb-6">
            Subscription Plan
          </h1>
          <div className="flex flex-row flex-wrap items-center justify-center mb-6">
            <div className="flex flex-row items-center my-2 mx-6">
              <GiBowlOfRice className="text-2xl mr-1" />
              <span className="text-sm sm:text-base ml-2 mt-1.5">Chinese</span>
            </div>
            <div className="flex flex-row items-center my-2 mx-6">
              <GiHotMeal className="text-2xl mr-1" />
              <span className="text-sm sm:text-base ml-2 mt-1.5">Western</span>
            </div>
            <div className="flex flex-row items-center my-2 mx-6">
              <GiSushis className="text-2xl mr-1" />
              <span className="text-sm sm:text-base ml-2 mt-1.5">Japanese</span>
            </div>
          </div>

          {!isLoading &&
            (planList.length > 0 ? (
              <div className="grid grid-cols-subscribeCard gap-6">
                {planList
                  .sort((a, b) => b.day - a.day)
                  .map((item, i) => (
                    <SubscribePlanCard key={i} data={item} />
                  ))}
              </div>
            ) : (
              <div>No content available</div>
            ))}

          <p className="text-xs sm:text-sm mt-4 text-right">
            * Price shown is for 1 pax
          </p>
        </div>
      </div>
    </Container>
  );
}
