import React, { useState } from "react";
import { useCart } from "../../../hook/useCart";
import AddToCartModal from "../modal/add-to-cart";
import { TbShoppingCartPlus } from "react-icons/tb";
import { addDays, format, isSameDay } from "date-fns";

export default function MenuCard({ data }) {
  const cart = useCart();
  const [open, setOpen] = useState(false);

  return (
    <div
      className="menu-card bg-white shadow-md rounded-md overflow-hidden border border-solid cursor-pointer"
      onClick={() => setOpen(!open)}
    >
      <div className="relative flex flex-col ">
        <img
          className="w-full aspect-square object-cover object-center bg-grey-light"
          src={data.image}
          alt={data.title}
        />
        {data.daytime && (
          <div className="absolute w-full h-full flex flex-col">
            <p className="text-xs sm:text-sm font-bold text-white text-center py-2 px-3 bg-black-40">
              {isSameDay(new Date(data.date), new Date())
                ? `Today's ${data.daytime === "day" ? "Lunch" : "Dinner"}`
                : isSameDay(new Date(data.date), addDays(new Date(), 1))
                ? `Tomorrow's ${data.daytime === "day" ? "Lunch" : "Dinner"}`
                : `${format(new Date(data.date), "dd MMM yyyy, E")} ${
                    data.daytime === "day" ? "(Lunch)" : "(Dinner)"
                  }`}
            </p>
          </div>
        )}
      </div>
      <div className="pt-3 pb-1 px-3">
        <p
          className="title text-sm sm:text-base !leading-[120%] font-bold overflow-hidden text-ellipsis h-[34px] sm:h-10"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title={data?.title || data?.name}
        >
          {data?.title || data?.name}
        </p>
      </div>
      <div className="pb-3 px-3 flex justify-between items-center">
        <span className="text-xs sm:text-sm font-medium">
          RM {cart.convertPriceWithThousandSeparator(data.price)}
        </span>
        <div className="-m-2">
          <button
            className="p-2 rounded-full ease-in-out"
            onClick={() => setOpen(true)}
          >
            <TbShoppingCartPlus className="text-2xl  text-primary " />
          </button>
        </div>
      </div>
      <AddToCartModal open={open} setOpen={setOpen} data={data} />
    </div>
  );
}
