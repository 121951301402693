import React from "react";
import { page } from "../assets/copy/page";
import Container from "../components/layout/container";

export default function Terms() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container seo={{ title: `FoodHeart | ${page.TERMS}` }}>
      <div className="pt-10 sm:pt-16 pb-8 px-6 sm:px-12 overflow-hidden">
        <div className="w-full max-w-container1200 mx-auto relative flex flex-col">
          <div className="mb-8">
            <h1 className="text-2xl sm:text-3xl font-bold">{page.TERMS}</h1>
          </div>
          <div className="mb-8">
            <p className="mb-5">
              These Terms and Conditions ("T&C") govern your use of our website
              located at www.foodheart.com.my operated by FOODHEART SDN BHD
              (“FoodHeart”, “our”, “we”, “us”).
            </p>

            <p className="mb-5">
              Please read the T&C set out below carefully before using
              foodheart.com.my (“Website”) and FoodHeart mobile application
              (“App”) to order any Goods or Services. By ordering any Goods or
              Services from the Website or the App, you agree to be bound by
              these Terms and Conditions.
            </p>
            <ol>
              {/* 1 */}
              <li>
                <p className="mb-2">Ordering</p>
                <ol>
                  <li>
                    For the supply of Goods or Services from the website, any
                    contact is between you and FoodHeart. You agree to take
                    particular care when providing us with your details and
                    warrant that these details are accurate and complete at the
                    time of ordering. You also warrant that the credit or debit
                    card details that you provide are of your own credit or
                    debit card and that you have sufficient funds to make the
                    payment.
                  </li>
                  <li>
                    When ordering from the Website or the App, you are required
                    to provide certain information to register for an account,
                    and information required includes your name, date of birth,
                    e-mail address, credit card number, phone number and
                    delivery address. You warrant that you have the legal
                    capacity to use and to order through the Website or the App.
                    You agree that the information you provide to us is accurate
                    and that you will keep it accurate and up-to-date. You are
                    solely responsible for maintaining the confidentiality of
                    your account and password. You agree to accept
                    responsibility for all activities that occur under your
                    account.
                  </li>
                  <li>
                    We will take all reasonable care, in so far as it is in our
                    power to do so, to secure your orders and personal details,
                    but in the absence of negligence on our part we cannot be
                    held liable for any loss you may suffer if a third party
                    procures unauthorized access to any data you provide when
                    accessing or ordering from the Website or the App.
                  </li>
                  <li>
                    You may order meals, drinks or such other products or
                    services available at the website or the App, and we will do
                    our utmost best to make deliveries on the specified time and
                    date that you have selected to your delivery address. Orders
                    must be received before the specified cut-off time. You
                    shall review your order carefully before placing it as we
                    shall not be held responsible for any failure to do so or
                    changes after the order is made to the time, delivery
                    address or items.
                  </li>
                  <li>
                    Any order that you place with us is subject to product
                    availability, delivery capacity and acceptance by us. When
                    you place your order online, we will send you an email to
                    confirm that we have received it. This email confirmation
                    will be produced automatically so that you have confirmation
                    of your order details. You must inform us immediately if any
                    details are incorrect. The fact that you receive an
                    automatic confirmation does not necessarily mean that we
                    will be able to fill your order. Once we have sent the
                    confirmation email we will check availability and delivery
                    capacity.
                  </li>
                  <li>
                    If the details of the order are correct, the contract for
                    the Goods or Services will be confirmed by FoodHeart to your
                    default email address.
                  </li>
                  <li>
                    We may however, in our sole discretion, choose to not
                    process or to cancel your orders in certain circumstances.
                    This may occur, for example, when the meals or products you
                    wish to order is no longer available, out of coverage for
                    delivery, or in other unforeseen circumstances such as
                    operational outages. We will not charge you in these cases
                    or refund the charges for orders that we do not process or
                    cancel.
                  </li>
                  <li>
                    In case there is a shortage of ingredients or materials for
                    preparing a particular ordered food, we reserve the absolute
                    right to replace or substitute such ingredients and
                    materials that were not available with similar ingredients
                    or materials of similar value without notice. Such items as
                    but not limited to vegetables, root vegetables and fruits.
                  </li>
                </ol>
              </li>
              {/* 2 */}
              <li>
                <p className="mb-2">Price and Payment</p>
                <ol>
                  <li>
                    All prices are in Malaysian Ringgit (MYR) unless otherwise
                    stated. The prices are correct as shown on the Website and
                    the App, and are not inclusive of applicable tax and
                    delivery charges. We reserve the right to review the prices
                    from time to time. We also reserve the right to alter the
                    Goods or Services available for sale on the Website or the
                    App, and to stop listing Goods or Services.
                  </li>
                  <li>
                    The total price for Goods or Services ordered, delivery
                    charges and other charges, will be displayed on the Website
                    when you place your order. Full payment must be made before
                    your orders are being processed. Payment can be made online
                    (via Credit Card, Debit Card, Affin Online, Am Online, Bank
                    Rakyat, CIMB Clicks, FPX bank transfer, Maybank2U, Hong
                    Leong Connect, RHB, MyBSN, Boost E-wallet, Graypay, Touch N
                    Go E-Wallet).
                  </li>
                </ol>
              </li>
              {/* 3 */}
              <li>
                <p className="mb-2">Cancellation</p>
                <ol>
                  <li>
                    Orders are considered final as soon as they are placed and
                    modification/cancellation is generally not permitted. We do
                    make exceptions on a case-by-case basis and please inform us
                    at least 2 days in advance. In the event of cancellation, we
                    may credit the cost of such order to your account balance
                    which can be applied to your next order. We are not liable
                    or responsible for the time of an outside delays in outside
                    payment gateway to charge or refund you or if your bank
                    account/credit card has been used in a fraudulent manner.
                  </li>
                  <li>
                    We may cancel your order if the product is not available for
                    whatsoever reason. We will notify you through email that an
                    order has been cancelled or if a product is unavailable at
                    any time. The order shall be automatically cancelled and we
                    will reimburse you for any payment already made to your
                    account balance which can be applied to your next order.
                  </li>
                </ol>
              </li>
              {/* 4 */}
              <li>
                <p className="mb-2">Use of Information</p>
                <ol>
                  <li>
                    You authorize us to use, store or otherwise process your
                    personal information in order to provide the Goods or
                    Services to you and for marketing and credit control
                    purposes (the "Purpose"). The Purpose may include the
                    disclosure of your personal information to selected third
                    parties from time to time where we believe that the services
                    offered by such third parties may be of interest to you or
                    where this is required by law or in order to provide the
                    Food Delivery, Goods or Service to you. More information can
                    be found in our Privacy Policy.
                  </li>
                </ol>
              </li>
              {/* 5 */}
              <li>
                <p className="mb-2">Liability</p>
                <ol>
                  <li>
                    By using or ordering via the Website or the App, you
                    acknowledge and agree that the use of the Website or the App
                    is at your own risk and the maximum extent permitted
                    according to the applicable law, in no circumstances, shall
                    we be liable for any direct, indirect, incidental, special,
                    consequential, or punitive damages, losses, costs or
                    expenses nor for any loss of profit that results from the
                    use of, or inability to use the Website or the App, or any
                    application or material on any site linked to this Website
                    or the App (including but not limited to any viruses, bugs
                    or any other errors or defects or failures in computer
                    transmissions or network communications) even if we have
                    been advised of the possibility of such damage. In addition,
                    no liability can be accepted by us in respect of any changes
                    made to the content of the Website or the App by
                    unauthorized third parties. All express or implied
                    warranties or representations are excluded to the maximum
                    extent permitted according to the applicable law.
                  </li>
                  <li>
                    We disclaim any and all liability to you for the supply of
                    the delivery and products to the fullest extent permissible
                    under applicable laws unless the loss or damage is due to
                    our willful misconduct, or gross negligence. This however
                    shall not affect your statutory rights as a consumer under
                    applicable laws. In the event that we are found liable for
                    any loss or damage, such liability is limited to the amount
                    that you have paid for the relevant products. We shall not
                    be liable for any consequential, indirect or special damage,
                    howsoever arising.
                  </li>
                  <li>
                    In the event of reasonable belief that there exists an abuse
                    of promotional event and/or suspects instances of fraud, we
                    may cause the suspected user to be blocked immediately and
                    reserves the right to refuse future services. Additionally,
                    should there exist an abuse of vouchers or discount codes,
                    we reserve the right to seek compensation or damages from
                    any and all violators. Any premonitions or offers are
                    subject to our sole discretion and may be withdrawn at any
                    time and without prior notice.
                  </li>
                  <li>
                    The Website or the App may include content, information or
                    links to third parties or third parties’ sites. We shall not
                    be held responsible for the content of any such sites or
                    neither for the content of any third parties’ advertising or
                    sponsorship nor for compliance of such with any regulations.
                    The links may be accessed at your own risk and we mane no
                    representations or warranties about the content,
                    completeness, or accuracy of these links or the sites
                    hyperlinked to the Website or the App. You agree to hold
                    harmless and relieve us from any liability whatsoever
                    arising from your use of information from a third party or
                    your use of any third-party website. You agree to indemnify,
                    defend and hold us harmless from and against all losses,
                    expenses, damages and costs, including reasonable attorneys’
                    fees, resulting from any use of the Website or the App, or
                    as a result of violation of this Terms and Conditions by you
                    through use of your account.
                  </li>
                </ol>
              </li>
              {/* 6 */}
              <li>
                <p className="mb-2">Intellectual Property Rights</p>
                <ol>
                  <li>
                    All intellectual property rights in connection with the
                    Website and the App (including but not limited to, the
                    recipe, design, layout, look, text, appearance and graphics)
                    are owned by us and must not be reproduced without our prior
                    written consent. You may for your own personal use, to print
                    and download the materials or bookmark or share links
                    directing others to the content on the Website and the App,
                    provided that you do not modify, reproduce or republish any
                    content either online or offline, without our consent.
                  </li>
                </ol>
              </li>
              {/* 7 */}
              <li>
                <p className="mb-2">Prohibitions</p>
                <ol>
                  <li>
                    You are not allowed to use or launch any automated system or
                    program in connection with the Website or the App or its
                    online ordering functionality. You may not collect or
                    harvest any personally identifiable information from the
                    Website or the App, use communication systems provided by
                    the Website or the App for any commercial solicitation
                    purposes, solicit for any reason whatsoever any users of the
                    Website or the App with respect to their submission to the
                    Website or the App, or publish or distribute any vouchers or
                    codes in connection with the website, or scrape or hack the
                    Website or the App.
                  </li>
                </ol>
              </li>
              {/* 8 */}
              <li>
                <p className="mb-2">Law and Jurisdiction</p>
                <ol>
                  <li>
                    The Terms & Conditions, and any disputes arising from or
                    under the Terms & Conditions, shall be governed by Malaysia
                    law. You agree to submit to the exclusive jurisdiction of
                    the courts of Malaysia regarding any such dispute. A person
                    who is not a party to Terms & Conditions shall have no right
                    under the Contract (Rights of Third Parties) Act to enforce
                    any part of Terms & Conditions.
                  </li>
                </ol>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </Container>
  );
}
