import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { CgProfile } from "react-icons/cg";
import { FaPlus, FaSpinner } from "react-icons/fa";
import { useAuth } from "../../../hook/useAuth";
import { alertModal } from "../modal/alert";
import { useRouter } from "../../../hook/useRouter";
import { path } from "../../../assets/copy/path";
import { Get } from "../../../services";
import { BiUser } from "react-icons/bi";
import { useCart } from "../../../hook/useCart";
import { page } from "../../../assets/copy/page";
import {
  MdHistory,
  MdLogout,
  MdOutlineAccountBalanceWallet,
  MdOutlineListAlt,
} from "react-icons/md";

export default function ProfileMenu({ styles }) {
  const auth = useAuth();
  const cart = useCart();
  const { location, navigate, state } = useRouter();
  const ref = useRef(null);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [profilePhoto, setProfilePhoto] = React.useState(null);
  const [profileData, setProfileData] = React.useState({});
  const [balance, setBalance] = React.useState(0);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!ref.current?.contains(event.target)) {
        if (!show) return;
        setShow(false);
      }
    };
    window.addEventListener("click", handleOutsideClick);
    return () => window.removeEventListener("click", handleOutsideClick);
  }, [show, ref]);

  React.useEffect(() => {
    if (show) {
      document.body.classList.add("overflow-hidden", "sm:overflow-y-scroll");
    } else document.body.classList.remove("overflow-hidden");
  }, [show]);

  const toggle = () => {
    setShow(!show);
  };

  const handleClick = async (val) => {
    setLoading(true);
    toggle();
    !show && getProfile();
  };

  const getProfile = () => {
    Get(`/user/profile`)
      .then((response) => {
        if (response.code_status === 200) {
          setProfilePhoto(response.data.image);
          setBalance(Number(response.data.wallets?.sum || 0));
          setProfileData(response.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error: ", error);
        if (error.code_status === 401) {
          auth.logout();
          alertModal
            .fire({
              title: "Session Expired",
              text: "Please login to continue",
              icon: "error",
              confirmButtonText: "Ok",
            })
            .then((result) => {
              if (result.isConfirmed) {
                // Go to login page
                navigate(path.LOGIN, {
                  state: {
                    ...state,
                    redirectTo: location,
                  },
                });
              }
            });
        } else {
          alertModal.fire("Error", error.message, "error", "Ok");
        }
        setLoading(false);
      });
  };

  const handleLogout = () => {
    alertModal
      .fire({
        title: "Logout?",
        text: "Are you sure you want to logout?",
        icon: "question",
        confirmButtonText: "Yes",
        showCancelButton: true,
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          auth.logout();
          navigate(path.HOME, {
            state: {
              ...state,
              redirectTo: location,
            },
          });
        }
      });
  };

  return (
    <div
      ref={ref}
      className={`[&>div.dropdown-menu]:h-[calc(100%-80px)] sm:[&>div.dropdown-menu]:!h-auto [&>div.dropdown-menu]:max-h-[calc(100%-80px)] transition duration-150 ease-in-out ${
        show ? "open" : ""
      }`}
    >
      <button
        type="button"
        id="options-menu"
        aria-haspopup="true"
        data-bs-toggle="dropdown"
        aria-expanded={show ? "true" : undefined}
        className={`${styles.menuItemIcon} relative`}
        onClick={handleClick}
      >
        <CgProfile className="text-2xl" />
      </button>
      {show && (
        <div
          className="dropdown-menu w-full sm:w-[400px] max-w-screen h-0 invisible opacity-0 fixed top-20 left-0 sm:left-auto right-0 bottom-auto bg-white sm:rounded-b-md overflow-y-scroll overflow-x-hidden shadow z-40"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          {loading ? (
            <div className="w-full h-full flex justify-center items-center p-20">
              <FaSpinner className="text-2xl text-grey animate-spin" />
            </div>
          ) : (
            <div className="w-full h-full flex flex-col py-3 px-6">
              <div className="flex flex-row items-center mb-4">
                <div className="w-10 h-10 rounded-full shadow-md overflow-hidden cursor-pointer">
                  {profilePhoto ? (
                    <img
                      className="w-10 h-10 object-cover object-center"
                      src={profilePhoto}
                      alt=""
                    />
                  ) : (
                    <div className="w-10 h-10 bg-grey-extra-light flex justify-center items-center">
                      <BiUser className="text-2xl text-grey-dark" />
                    </div>
                  )}
                </div>
                <div className="ml-4">
                  <p className="text-lg font-semibold capitalize -mb-2">
                    {profileData.first_name}
                  </p>
                  <span className="text-3xs sm:text-xs text-grey-dark">
                    @{profileData.username}
                  </span>
                </div>
              </div>
              <div className="shadow-profile rounded-md relative">
                <div className="text-center py-3 px-4">
                  <p className="text-xs sm:text-sm">Available Balance</p>
                  <div className="flex justify-center items-center">
                    <p className="text-lg font-semibold ml-7">
                      RM {cart.convertPriceWithThousandSeparator(balance)}
                    </p>
                    <div className="rounded-full p-1 bg-primary ml-2">
                      <Link
                        onClick={toggle}
                        to={path.EWALLETTOPUP}
                        state={{ scrollTo: "topup" }}
                      >
                        <FaPlus className="text-3xs text-white" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <ul>
                  <li>
                    <Link
                      onClick={toggle}
                      to={path.PROFILE}
                      state={{ scrollTo: "profile" }}
                      className={`${navStyles.navItem} ${
                        location.pathname === path.PROFILE
                          ? navStyles.selectedNavItem
                          : ""
                      }`}
                    >
                      <BiUser className={navStyles.navIcon} />
                      {page.PROFILE}
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={toggle}
                      to={path.EWALLET}
                      state={{ scrollTo: "ewallet" }}
                      className={`${navStyles.navItem} ${
                        location.pathname === path.EWALLET
                          ? navStyles.selectedNavItem
                          : ""
                      }`}
                    >
                      <MdOutlineAccountBalanceWallet
                        className={navStyles.navIcon}
                      />
                      {page.EWALLET}
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={toggle}
                      to={path.ORDERHISTORY}
                      state={{ scrollTo: "order-history" }}
                      className={`${navStyles.navItem} ${
                        location.pathname === path.ORDERHISTORY
                          ? navStyles.selectedNavItem
                          : ""
                      }`}
                    >
                      <MdHistory className={navStyles.navIcon} />
                      {page.ORDERHISTORY}
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={toggle}
                      to={path.TRANSACTIONHISTORY}
                      state={{ scrollTo: "transaction-history" }}
                      className={`${navStyles.navItem} ${
                        location.pathname === path.TRANSACTIONHISTORY
                          ? navStyles.selectedNavItem
                          : ""
                      }`}
                    >
                      <MdOutlineListAlt className={navStyles.navIcon} />
                      {page.TRANSACTIONHISTORY}
                    </Link>
                  </li>
                  <li className={navStyles.navItem} onClick={handleLogout}>
                    <MdLogout className={navStyles.navIcon} />
                    Logout
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

const navStyles = {
  navItem:
    "flex flex-row items-center py-2 px-4 text-sm sm:text-base cursor-pointer",
  selectedNavItem: "font-bold text-primary",
  navIcon: "text-2xl mr-4",
};
