import React from "react";
import { useField } from "formik";
import { MdErrorOutline } from "react-icons/md";

function Checkbox(props) {
  const [field, meta] = useField(props);
  const { label, name, containerStyle, labelStyle, handleChange, disabled } =
    props;
  const showError = meta.touched && meta.error;

  return (
    <>
      <div className="relative flex-1" style={containerStyle}>
        <label
          htmlFor={name}
          className="text-sm sm:text-base pl-8 cursor-pointer"
          style={labelStyle}
        >
          <input
            className="absolute top-0.5 left-0 h-5 w-5 p-0 m-0 bg-white border border-grey rounded cursor-pointer appearance-none"
            type="checkbox"
            id={name}
            name={name}
            onChange={handleChange}
            checked={field.value}
            disabled={disabled}
          />
          {label}
        </label>
      </div>
      {showError && (
        <div className="flex items-center mt-1 text-xs sm:text-sm text-red">
          <MdErrorOutline className="mr-1" /> <span>{meta.error}</span>
        </div>
      )}
    </>
  );
}

export default Checkbox;
