import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { TbShoppingCart } from "react-icons/tb";
import { useCart } from "../../../../hook/useCart";
import CartItem from "./cart-item";
import { path } from "../../../../assets/copy/path";
import { FaSpinner } from "react-icons/fa";

export default function CartMenu({ styles }) {
  const cart = useCart();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [canProceed, setCanProceed] = useState(false);

  const ref = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!ref.current?.contains(event.target)) {
        if (!show) return;
        setShow(false);
      }
    };
    window.addEventListener("click", handleOutsideClick);
    return () => window.removeEventListener("click", handleOutsideClick);
  }, [show, ref]);

  React.useEffect(() => {
    if (show) {
      document.body.classList.add("overflow-hidden", "sm:overflow-y-scroll");
    } else document.body.classList.remove("overflow-hidden");
  }, [show]);

  useEffect(() => {
    if (cart.cartList.length > 0) {
      let isValid = true;
      cart.cartList.map((item) => {
        if (item.message) isValid = false;
      });
      setCanProceed(isValid);
    }
  }, [cart.cartList]);

  const toggle = () => {
    setShow(!show);
  };

  const handleClick = async (val) => {
    setLoading(true);
    toggle();
    !show && (await cart.getCart());
    setLoading(false);
  };

  return (
    <div
      ref={ref}
      className={`[&>div.dropdown-menu]:h-[calc(100%-80px)] sm:[&>div.dropdown-menu]:!h-auto [&>div.dropdown-menu]:max-h-[calc(100%-80px)] transition duration-150 ease-in-out ${
        show ? "open" : ""
      }`}
    >
      {/* [&>div.dropdown-menu]:h-[calc(100%-80px)] sm:[&>div.dropdown-menu]:h-full [&>div.dropdown-menu]:min-h-[50vh] */}
      <button
        type="button"
        id="options-menu"
        aria-haspopup="true"
        data-bs-toggle="dropdown"
        aria-expanded={show ? "true" : undefined}
        className={`${styles.menuItemIcon} relative`}
        onClick={handleClick}
      >
        <TbShoppingCart className="text-2xl" />
        {cart.cartList?.length > 0 && (
          <span className="absolute top-0 right-0 flex justify-center items-center w-4 h-4 p-1 rounded-full bg-primary text-3xs text-white">
            {cart.cartList.length}
          </span>
        )}
      </button>
      {show && (
        <div
          className="dropdown-menu w-full sm:w-[400px] max-w-screen h-0 invisible opacity-0 fixed top-20 left-0 sm:left-auto right-0 bottom-auto bg-white sm:rounded-b-md overflow-y-scroll overflow-x-hidden shadow z-40"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          {loading ? (
            <div className="w-full h-full flex justify-center items-center p-20">
              <FaSpinner className="text-2xl text-grey animate-spin" />
            </div>
          ) : (
            <div className="w-full h-full flex flex-col justify-between">
              {cart.cartList.length > 0 ? (
                <div className="flex-1 py-3 px-6 sm:max-h-[400px] overflow-y-auto overflow-x-hidden shadow-sm">
                  {cart.cartList.map((item, i) => (
                    <CartItem key={i} data={item} />
                  ))}
                </div>
              ) : (
                <div className="flex justify-center items-center py-3 px-6 text-sm sm:text-base w-full h-full sm:max-h-[400px] shadow-sm overflow-hidden">
                  Your cart is currently empty
                </div>
              )}
              <div className="flex flex-col">
                {/* <hr className="bg-grey-light" /> */}
                <div className="flex flex-row justify-between py-3 px-6 text-sm sm:text-base">
                  <span>Total</span>{" "}
                  <span className="font-medium">
                    RM {cart.convertPriceWithThousandSeparator(cart.totalPrice)}
                  </span>
                </div>
                <Link
                  to={path.CART}
                  className={`w-full text-sm sm:text-base font-bold text-center text-white bg-primary hover:bg-primary-dark capitalize border-2 border-solid border-primary shadow py-3 px-6 transition duration-300 ease ${
                    !cart.cartList.length || !canProceed
                      ? "!opacity-60 !pointer-events-none"
                      : ""
                  }`}
                >
                  View Order Summary
                </Link>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
