import React from "react";
import { useRouter } from "../../hook/useRouter";
import { path } from "../../assets/copy/path";
import Container from "../../components/layout/container";
import { differenceInDays, format, isFuture } from "date-fns";
import { FaEdit } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function OrderDetails() {
  const { state, navigate, query } = useRouter();
  const [isLoading, setIsLoading] = React.useState(true);
  const [data, setData] = React.useState({});

  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (state?.data && query?.id == state?.data.detail.id) {
      setData(state.data);
      setIsLoading(false);
    } else navigate(path.ORDERHISTORY);
  }, []);

  const CartItem = ({ item }) => (
    <tr className="align-top [&>td]:first:pt-4 [&>td]:last:pb-4 text-sm sm:text-base">
      <td className="text-left py-3 px-2 sm:px-4 flex flex-col sm:flex-row">
        <div className="flex flex-col justify-start">
          <p className="font-medium">{item.name}</p>
          {item.type === "menu" && (
            <>
              <p className="text-3xs sm:text-xs mt-2">
                {format(new Date(item.date), "dd MMM yyyy, E")}{" "}
                {item.daytime === 1 ? "(Lunch)" : "(Dinner)"}
              </p>
              <p className="text-3xs sm:text-xs mt-2">
                Add On:{" "}
                {item.add_on.length > 0
                  ? item.add_on.map((addOnItem, j) => (
                      <span key={j}>
                        {addOnItem}
                        {item.add_on.length !== j + 1 ? ", " : ""}
                      </span>
                    ))
                  : "-"}
              </p>
            </>
          )}
        </div>
      </td>
      <td className="text-center py-3 px-2 sm:px-4">{item.quantity}</td>
      <td className="text-center py-3 px-2 sm:px-4">{item.status}</td>
    </tr>
  );

  const CartSubscriptionItem = ({ item }) => {
    const sortedData = Object.entries(item.item)
      .sort(([date1], [date2]) => new Date(date1) - new Date(date2))
      .reduce((acc, [date, value]) => {
        acc[date] = value;
        return acc;
      }, {});

    return (
      <>
        <tr className="align-top [&>td]:first:pt-4 [&>td]:last:pb-4 text-sm sm:text-base">
          <td className="text-left py-3 px-2 sm:px-4 flex flex-col sm:flex-row">
            <p className="font-medium">{item.name}</p>
            <p>
              {Object.values(item.item)[0][0].daytime === 1
                ? "(Lunch)"
                : "(Dinner)"}
            </p>
          </td>
          <td></td>
          <td></td>
        </tr>

        {Object.entries(sortedData).map(([key, value], i) => {
          return (
            <>
              <tr key={i} className="align-top text-sm sm:text-base">
                <td className="text-left ml-10 p-2 flex flex-col ">
                  <ul className="list-disc">
                    <li className="">
                      <div className="flex flex-row text-3xs sm:text-xs">
                        <p className="font-bold">
                          {format(new Date(key), "dd MMM yyyy, E")}
                        </p>
                        {isFuture(new Date(key)) &&
                          differenceInDays(new Date(key), new Date()) >= 2 && (
                            <Link
                              to={`${path.EDITORDER}/${value[0].cart_id}`}
                              state={{
                                date: key,
                                data: value,
                              }}
                              className={`flex items-center ml-2 rounded-full text-primary`}
                            >
                              <FaEdit className="" />
                            </Link>
                          )}
                      </div>
                    </li>
                  </ul>
                </td>
                <td></td>
                <td></td>
              </tr>
              {value.map((val, j) => (
                <tr>
                  <td className="flex flex-row items-start ml-10 p-2 text-3xs sm:text-xs">
                    <p className="font-medium whitespace-nowrap">
                      Pax {j + 1}:{" "}
                    </p>
                    <p className="ml-1">
                      {val.name}
                      <br />
                      Add On:{" "}
                      {val.add_on.length > 0
                        ? val.add_on.map((addOnItem, j) => (
                            <span key={j}>
                              {addOnItem}
                              {val.add_on.length !== j + 1 ? ", " : ""}
                            </span>
                          ))
                        : "-"}
                    </p>
                  </td>
                  <td className="text-center p-2">{val.quantity}</td>
                  <td className="text-center p-2">{val.status}</td>
                </tr>
              ))}
            </>
          );
        })}
      </>
    );
  };

  return (
    <Container loading={isLoading} seo={{ title: "FoodHeart | Subscribe" }}>
      <div className="py-10 sm:py-16 px-6 sm:px-12 overflow-hidden">
        <div className="w-full max-w-container1200 mx-auto relative flex flex-col">
          <h1 className="text-2xl sm:text-3xl font-bold text-center pb-6">
            Order Details
          </h1>
          {!isLoading &&
            (Object.entries(data).length > 0 ? (
              <div className="">
                <div>
                  <table className="w-full">
                    <thead>
                      <tr className="align-top bg-primary text-sm sm:text-base text-white">
                        <th className="w-2/4 text-left px-2 py-4 sm:px-4 rounded-l-3xl overflow-hidden">
                          Item{" "}
                        </th>
                        <th className="w-1/4 text-center px-2 py-4 sm:px-4 ">
                          Quantity
                        </th>
                        <th className="w-1/4 text-center px-2 py-4 sm:px-4 rounded-r-3xl overflow-hidden">
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.order_history.length > 0 ? (
                        data.order_history.map((item, i) => {
                          if (item.type === "subscription")
                            return <CartSubscriptionItem key={i} item={item} />;
                          else return <CartItem key={i} item={item} />;
                        })
                      ) : (
                        <tr className="align-top">
                          <td className="text-center py-2 px-4" colSpan={4}>
                            No item available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <hr className="bg-grey-light my-6" />
                <div>
                  <div className="flex flex-row justify-between gap-8">
                    <p>Delivery address</p>
                    <div>
                      <p>{data.address.address_1}</p>
                      {data.address.address_2 ? (
                        <p>{data.address.address_2}</p>
                      ) : null}
                      <p>
                        {data.address.zip_code} {data.address.city}
                      </p>
                      <p>
                        {data.address.state}, {data.address.country}
                      </p>
                    </div>
                  </div>
                </div>
                <hr className="bg-grey-light my-6" />
                <div>
                  <div className="flex flex-row justify-between items-center gap-8">
                    <p>Order ID:</p>
                    <p>{data.detail.id}</p>
                  </div>
                  <div className="flex flex-row justify-between items-center gap-8">
                    <p>Order Time:</p>
                    <p>
                      {format(
                        new Date(data.detail.created_at),
                        "dd-MM-yyyy HH:mm:ss"
                      )}
                    </p>
                  </div>
                  <div className="flex flex-row justify-between items-center gap-8">
                    <p>Total:</p>
                    <p>RM {data.detail.amount}</p>
                  </div>
                  <div className="flex flex-row justify-between items-center gap-8">
                    <p>Shipping:</p>
                    <p>RM {data.detail.delivery_fee}</p>
                  </div>
                  <div className="flex flex-row justify-between items-center gap-8">
                    <p>Voucher:</p>
                    <p>RM {data.detail.discount}</p>
                  </div>
                  <div className="flex flex-row justify-between items-center gap-8">
                    <p>Grand Total:</p>
                    <p>RM {data.detail.sub_total}</p>
                  </div>
                </div>
              </div>
            ) : (
              <div>No content available</div>
            ))}
        </div>
      </div>
    </Container>
  );
}
