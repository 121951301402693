import React from "react";
import { Accordion, AccordionItem, AccordionPanel } from ".";
import { MdDeleteOutline } from "react-icons/md";
import SubscribeMenuPaxCard from "../card/subscribe-menu-card";

export default function SubscribeMenuPax({
  index,
  value,
  pax,
  paxMenu,
  setPaxMenu,
  data,
  maxDay,
  handleRemovePax,
  dateSelected,
  setDateSelected,
  calculatePrice,
  selectedAll,
  setSelectedAll,
}) {
  const [selectedDay, setSelectedDay] = React.useState(0);

  return (
    <Accordion className="border border-solid border-grey rounded-md mb-10">
      <AccordionItem
        className="flex flex-row justify-between items-center py-[16px] px-[24px] rounded-2xl focus:outline-none"
        accordionIcon={true}
      >
        <div className="flex flex-row items-center">
          <div className="text-xl font-semibold">
            <p className="text-xl font-semibold">Pax {index + 1}</p>
            <div className="flex items-center">
              <span className="text-xs sm:text-sm font-normal">
                Selected: {selectedDay}/{maxDay}{" "}
              </span>
              {index !== 0 && (
                <button
                  className="text-primary ml-3"
                  onClick={() => handleRemovePax(index)}
                >
                  <MdDeleteOutline className="text-2xl" />
                </button>
              )}
            </div>
          </div>
        </div>
      </AccordionItem>
      <AccordionPanel className="">
        <div className="p-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {Object.entries(data).map(([date, menuList], i) => {
            return (
              <SubscribeMenuPaxCard
                key={i}
                cardIndex={i} // index of card
                pax={pax}
                paxIndex={index} // index of paxMenu = index of pax
                paxMenu={paxMenu}
                data={data}
                selectedDay={selectedDay}
                setSelectedDay={setSelectedDay}
                maxDay={maxDay}
                dateSelected={dateSelected}
                setDateSelected={setDateSelected}
                date={date}
                menu={menuList.menu}
                defaultMenu={menuList.default}
                addOn={menuList.add_on}
                drinks={menuList.drinks}
                calculatePrice={calculatePrice}
                selectedAll={selectedAll}
                setSelectedAll={setSelectedAll}
              />
            );
          })}
        </div>
      </AccordionPanel>
    </Accordion>
  );
}
