import React, { useEffect, useState } from "react";
import { ModalBody, ModalWrapper } from ".";
import Button from "../button";
import { alertModal } from "./alert";
import { Get, PostUrlEncoding } from "../../../services";
import { SingleInput } from "../form/single-input";
import { BiChevronRight } from "react-icons/bi";
import { MdErrorOutline } from "react-icons/md";
import { useAuth } from "../../../hook/useAuth";
import { useRouter } from "../../../hook/useRouter";
import { path } from "../../../assets/copy/path";

export default function CreatePinModal({ open, setOpen }) {
  const auth = useAuth();
  const { location, navigate, state } = useRouter();
  const inputOtpRef = React.useRef([]);
  const inputPinRef = React.useRef([]);
  const inputConfirmPinRef = React.useRef([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(null);
  const [step, setStep] = React.useState(0);
  const [otp, setOtp] = React.useState("");
  const [otpError, setOtpError] = React.useState("");
  const [pin, setPin] = React.useState("");
  const [pinError, setPinError] = React.useState("");
  const [confirmPin, setConfirmPin] = React.useState("");
  const [confirmPinError, setConfirmPinError] = React.useState("");
  const [resendDisabled, setResendDisabled] = React.useState(false);
  const [countdown, setCountdown] = React.useState(60);

  React.useEffect(() => {
    if (step === 1) {
      inputOtpRef.current[0].focus();
    } else if (step === 2) {
      inputPinRef.current[0].focus();
    } else if (step === 3) {
      inputConfirmPinRef.current[0].focus();
    }
  }, [step]);

  useEffect(() => {
    setIsOpen(open);
    if (open) {
      handleRequestOtp();
      setOtp("");
      setOtpError("");
      setPin("");
      setPinError("");
      setConfirmPin("");
      setConfirmPinError("");
      setStep(1);
    }
  }, [open]);

  const toggle = () => {
    setOpen(!isOpen);
    setIsOpen(!isOpen);
  };

  // OTP
  React.useEffect(() => {
    const lastSentTime = localStorage.getItem("lastSentTime");
    if (lastSentTime) {
      const timeElapsed = Math.floor((Date.now() - lastSentTime) / 1000);
      if (timeElapsed < 60) {
        setCountdown(60 - timeElapsed);
        setResendDisabled(true);
      }
    }
  }, []);

  React.useEffect(() => {
    let intervalId = null;
    if (countdown > 0 && resendDisabled) {
      intervalId = setInterval(() => {
        setCountdown(countdown - 1);
      }, 1000);
    } else if (countdown === 0 && resendDisabled) {
      setResendDisabled(false);
      setCountdown(60);
    }
    return () => clearInterval(intervalId);
  }, [countdown, resendDisabled]);

  const handleInputChange = (e, index, key, setKey, inputRef) => {
    setOtpError("");
    setPinError("");
    setConfirmPinError("");
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      // only allow digits
      let newKey = key;
      const keyArr = newKey.split("");
      keyArr[index] = value;
      newKey = keyArr.join("");
      setKey(newKey);

      if (value) {
        inputRef.current[index + 1]?.focus(); // focus on next input if exists
      }
    }
  };

  const handleInputKeyDown = (e, index, key, inputRef) => {
    if (e.key === "Backspace" && ![key][index]) {
      inputRef.current[index - 1].focus(); // focus on previous input
    }
  };

  const handleRequestOtp = () => {
    Get(`/verification/code`)
      .then((response) => {
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error: ", error);
        if (error.code_status === 401) {
          auth.logout();
          alertModal
            .fire({
              title: "Session Expired", // Title
              text: "Please login to continue", // Text
              icon: "error", // Icon
              confirmButtonText: "Ok", // confirmButtonText
            })
            .then((result) => {
              if (result.isConfirmed) {
                // Go to login page
                navigate(path.LOGIN, {
                  state: {
                    ...state,
                    redirectTo: location,
                  },
                });
              }
            });
        }
        setIsLoading(false);
      });
  };

  const handleSubmitOtp = () => {
    if (otp.length === 6) {
      setStep(2);
    } else {
      setOtpError("Invalid verification code");
    }
  };

  const handleResendOtp = () => {
    setIsLoading(true);
    Get(`/verification/code`)
      .then((response) => {
        setResendDisabled(true);
        setCountdown(60);
        localStorage.setItem("lastSentTime", Date.now());
        alertModal.fire({
          title: "Success", // Title
          text: "A new verification code has been sent to your email.", // Text
          icon: "success", // Icon
          confirmButtonText: "Ok", // confirmButtonText
        });
      })
      .catch((error) => {
        console.error("Error: ", error);
        if (error.code_status === 401) {
          auth.logout();
          alertModal
            .fire({
              title: "Session Expired", // Title
              text: "Please login to continue", // Text
              icon: "error", // Icon
              confirmButtonText: "Ok", // confirmButtonText
            })
            .then((result) => {
              if (result.isConfirmed) {
                // Go to login page
                navigate(path.LOGIN, {
                  state: {
                    ...state,
                    redirectTo: location,
                  },
                });
              }
            });
        } else {
          alertModal.fire(
            "Error", // Title
            error.message, // Text
            "error", // Icon
            "Ok" // confirmButtonText
          );
        }
        setIsLoading(false);
      });

    setIsLoading(false);
  };

  const handleSubmitPin = () => {
    if (pin.length === 6) {
      setStep(3);
    } else {
      setPinError("Invalid PIN");
    }
  };

  const handleSubmitConfirmPin = () => {
    let isValid = true;
    if (confirmPin.length !== 6) {
      isValid = false;
      setConfirmPinError("Invalid PIN");
    } else if (pin !== confirmPin) {
      isValid = false;
      setConfirmPinError("Confirm PIN not match.");
    }
    if (isValid) handleSubmit();
  };

  const handleSubmit = () => {
    setIsSubmitting(true);
    PostUrlEncoding(`/user/security`, {
      code: otp,
      pin: pin,
    })
      .then((response) => {
        setIsSubmitting(false);
        setStep(4);
      })
      .catch((error) => {
        console.error("Error: ", error);
        if (error.code_status === 401) {
          auth.logout();
          alertModal
            .fire({
              title: "Session Expired", // Title
              text: "Please login to continue", // Text
              icon: "error", // Icon
              confirmButtonText: "Ok", // confirmButtonText
            })
            .then((result) => {
              if (result.isConfirmed) {
                // Go to login page
                navigate(path.LOGIN, {
                  state: {
                    ...state,
                    redirectTo: location,
                  },
                });
              }
            });
        } else if (
          error.code_status === 403 &&
          error.message === "Invalid OTP Code."
        ) {
          alertModal
            .fire({
              title: "Invalid Verification Code", // Title
              text: "Please enter the correct verification code and try again.", // Text
              icon: "error", // Icon
              confirmButtonText: "Ok", // confirmButtonText
            })
            .then((result) => {
              if (result.isConfirmed) {
                setOtp("");
                setPin("");
                setConfirmPin("");
                setStep(1);
              }
            });
        } else {
          alertModal.fire(
            "Error", // Title
            error.message, // Text
            "error", // Icon
            "Ok" // confirmButtonText
          );
        }
        setIsSubmitting(false);
      });
  };

  return (
    <ModalWrapper
      open={isOpen}
      toggle={toggle}
      className="w-full"
      disableCloseButton={true}
    >
      <ModalBody className="p-10">
        <h2 className="text-xl sm:text-2xl font-bold text-center mb-4">
          Create New e-Wallet PIN
        </h2>
        <StepProgress step={step} />
        <div className={step === 1 ? "block" : "hidden"}>
          <p className="text-sm sm:text-base font-bold text-center mb-2">
            Enter Verification Code
          </p>
          <p className="mb-4">
            Please enter the 6 digit verification code that has been sent to
            your email to verify your identity.
          </p>
          <div className="flex flex-col items-center">
            <div className="flex flex-row gap-x-4">
              {Array.from({ length: 6 }, (_, i) => (
                <SingleInput
                  className=""
                  type="number"
                  key={i}
                  value={otp[i] ?? ""}
                  onChange={(e) =>
                    handleInputChange(e, i, otp, setOtp, inputOtpRef)
                  }
                  onKeyDown={(e) => handleInputKeyDown(e, i, otp, inputOtpRef)}
                  autoFocus={i === 0}
                  disabled={step !== 1}
                  ref={(el) => (inputOtpRef.current[i] = el)}
                />
              ))}
            </div>
            {otpError && (
              <div className="flex items-center mt-2 text-xs sm:text-sm text-red">
                <MdErrorOutline className="mr-1" /> <span>{otpError}</span>
              </div>
            )}
            <div className="text-sm sm:text-base text-center mt-6">
              Didn't receive the code?&nbsp;
              <button
                className="font-bold text-primary disabled:opacity-50"
                disabled={resendDisabled}
                onClick={handleResendOtp}
              >
                {resendDisabled ? `Resend in ${countdown}s` : "Resend"}
              </button>
            </div>
          </div>
          <Button
            type="button"
            name="Submit"
            color="primary"
            buttonClassName="mt-4"
            disabled={otp.length < 6}
            onClick={handleSubmitOtp}
          />
        </div>

        <div className={step === 2 ? "block" : "hidden"}>
          <p className="text-sm sm:text-base font-bold text-center mb-2">
            Enter New PIN
          </p>
          <p className="mb-4">Please enter your new e-wallet PIN.</p>
          <div className="flex flex-col items-center">
            <div className="flex flex-row gap-x-4">
              {Array.from({ length: 6 }, (_, i) => (
                <SingleInput
                  className=""
                  type="password"
                  key={i}
                  value={pin[i] ?? ""}
                  onChange={(e) =>
                    handleInputChange(e, i, pin, setPin, inputPinRef)
                  }
                  onKeyDown={(e) => handleInputKeyDown(e, i, pin, inputPinRef)}
                  autoFocus={i === 0}
                  disabled={step !== 2}
                  ref={(el) => (inputPinRef.current[i] = el)}
                />
              ))}
            </div>
            {pinError && (
              <div className="flex items-center mt-2 text-xs sm:text-sm text-red">
                <MdErrorOutline className="mr-1" /> <span>{pinError}</span>
              </div>
            )}
          </div>
          <Button
            type="button"
            name="Confirm"
            color="primary"
            buttonClassName="mt-8"
            disabled={pin.length < 6}
            onClick={handleSubmitPin}
          />
        </div>

        <div className={step === 3 ? "block" : "hidden"}>
          <p className="text-sm sm:text-base font-bold text-center mb-2">
            Confirm New PIN
          </p>
          <p className="mb-4">Please re-enter your new e-wallet PIN.</p>
          <div className="flex flex-col items-center">
            <div className="flex flex-row gap-x-4">
              {Array.from({ length: 6 }, (_, i) => (
                <SingleInput
                  className=""
                  type="password"
                  key={i}
                  value={confirmPin[i] ?? ""}
                  onChange={(e) =>
                    handleInputChange(
                      e,
                      i,
                      confirmPin,
                      setConfirmPin,
                      inputConfirmPinRef
                    )
                  }
                  onKeyDown={(e) =>
                    handleInputKeyDown(e, i, confirmPin, inputConfirmPinRef)
                  }
                  autoFocus={i === 0}
                  disabled={step !== 3}
                  ref={(el) => (inputConfirmPinRef.current[i] = el)}
                />
              ))}
            </div>
            {confirmPinError && (
              <div className="flex items-center mt-2 text-xs sm:text-sm text-red">
                <MdErrorOutline className="mr-1" />{" "}
                <span>{confirmPinError}</span>
              </div>
            )}
          </div>
          <Button
            type="button"
            name="Submit"
            color="primary"
            buttonClassName="mt-8"
            disabled={confirmPin.length < 6 || isSubmitting}
            onClick={handleSubmitConfirmPin}
          />
        </div>

        <div className={step === 4 ? "block" : "hidden"}>
          <h2 className="text-xl sm:text-2xl font-bold text-center mb-2">
            Success
          </h2>
          <p>Your e-wallet PIN has been created successfully.</p>
          <Button
            type="button"
            name="Ok"
            color="primary"
            buttonClassName="mt-6"
            onClick={toggle}
          />
        </div>

        {isLoading && (
          <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center bg-[rgba(0,0,0,0.5)]">
            <div className="border-4 border-grey-light border-t-4 border-t-white rounded-full animate-spin h-8 w-8"></div>
          </div>
        )}
      </ModalBody>
    </ModalWrapper>
  );
}

const StepProgress = ({ step }) => {
  const items = [
    {
      step: 1,
      label: "Verification",
    },
    {
      step: 2,
      label: "Enter New PIN",
    },
    {
      step: 3,
      label: "Confirm New PIN",
    },
  ];
  const style = {
    stepWrap: `flex flex-col items-center flex-1 mx-1`,
    step: `rounded-full border border-primary p-1 w-8 h-8 font-bold mb-1`,
    primary: `bg-primary text-white`,
    secondary: `bg-white text-primary`,
  };
  return (
    <div className="flex flex-row justify-center max-w-container400 mx-auto mb-6">
      {items.map((item, key) => {
        return (
          <>
            <div key={key} className={style.stepWrap}>
              <div
                className={`${style.step} ${
                  item.step <= step ? style.primary : style.secondary
                }`}
              >
                {item.step}
              </div>
              <p className="text-2xs">{item.label}</p>
            </div>
            {key < items.length - 1 ? (
              <BiChevronRight className="text-2xl text-grey self-center" />
            ) : null}
          </>
        );
      })}
    </div>
  );
};
