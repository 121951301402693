import React, { useEffect, useState } from "react";
import { ModalBody, ModalWrapper } from ".";
import Button from "../button";
import { MdCheckCircle } from "react-icons/md";
import { path } from "../../../assets/copy/path";
import { useRouter } from "../../../hook/useRouter";
import { useCart } from "../../../hook/useCart";

export default function CartChangeAddressModal({
  open,
  setOpen,
  // data,
  addressList,
  selectedAddress,
  setSelectedAddress,
}) {
  const cart = useCart();
  const [isOpen, setIsOpen] = useState(false);
  const { navigate } = useRouter();

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const toggle = () => {
    setOpen(!isOpen);
    setIsOpen(!isOpen);
  };

  const handleUpdateCartAddress = async () => {
    await cart.getCart(selectedAddress.id);
    toggle();
  };

  return (
    <ModalWrapper open={isOpen} toggle={toggle} closeOnClickOutside={true}>
      <ModalBody className="p-10">
        <h2 className="text-xl sm:text-2xl font-bold text-left mb-4">
          Choose Address
        </h2>
        {addressList?.length > 0 ? (
          addressList.map((item, i) => (
            <div
              className={`flex flex-row mb-4 py-2 px-4 border-2 rounded-md text-left cursor-pointer ${
                selectedAddress.id === item.id
                  ? "border-primary"
                  : "border-grey"
              }`}
              onClick={() => setSelectedAddress(item)}
            >
              <div className="mr-4">
                <MdCheckCircle
                  className={`text-2xl ${
                    selectedAddress.id === item.id
                      ? "text-primary"
                      : "text-grey"
                  }`}
                />
              </div>
              <div>
                <p>{item.address_1}</p>
                {item.address_2 && <p>{item.address_2}</p>}
                <p>
                  {item.zip_code} {item.city}
                </p>
                <p>
                  {item.state}, {item.country}
                </p>
                <div className="flex flex-row">
                  {item.default ? (
                    <div className="mt-2">
                      <span className="text-3xs sm:text-xs text-white mt-1 py-1 px-2.5 bg-primary rounded-3xl">
                        Default Address
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="mb-4 py-2 px-4">
            <p>No Address Available</p>
          </div>
        )}
        <div className="flex flex-row flex-wrap justify-center items-center">
          <Button
            type="button"
            name="Add new address"
            color="secondary"
            buttonClassName="mt-6"
            onClick={() =>
              navigate(path.PROFILE, {
                state: {
                  scrollTo: "address",
                  action: "Add address",
                },
              })
            }
          />
          <Button
            type="button"
            name="Confirm"
            color="primary"
            buttonClassName="mt-6 ml-4"
            onClick={handleUpdateCartAddress}
            // disabled={addressList.length === 0}
          />
        </div>
      </ModalBody>
    </ModalWrapper>
  );
}
