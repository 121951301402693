import React from "react";
import { useField } from "formik";
import Select from "react-select";
import { MdErrorOutline } from "react-icons/md";

// useField() returns [formik.getFieldProps(), formik.getFieldMeta()] which we can spread on <input>. We can use field meta to show an error message if the field is invalid and it has been touched (i.e. visited)

function SelectField(props) {
  const [field, meta, helpers] = useField(props);
  const { label, name, options, remarkText, required, placeholder } = props;
  const showError = meta.touched && meta.error;

  const customStyles = {
    dropdownIndicator: (styles) => ({
      ...styles,
      color: "#656C72!important",
    }),
    control: (styles, state) => ({
      ...styles,
      backgroundColor: "#fff",
      border: showError ? "1px solid #d21c1c" : "1px solid #CBD0D6",
      borderRadius: 8,
      boxShadow: "none",
      ":hover": {
        ...styles[":hover"],
        border: "1px solid #CBD0D6",
      },
    }),
    valueContainer: (styles) => ({
      ...styles,
      padding: "0.5rem 0.75rem",
    }),
    option: (styles, { isDisabled, isSelected }) => ({
      ...styles,
      backgroundColor: "#fff",
      color: isSelected ? "#1976d2" : "#1C1C1C",
      fontWeight: isSelected ? "bold" : "400",
      cursor: isDisabled ? "not-allowed" : "pointer",
      ":hover": {
        ...styles[":hover"],
        backgroundColor: "#fbf8f8",
      },
    }),
    menu: (styles) => ({
      ...styles,
      margin: "4px 0",
    }),
  };
  return (
    <>
      {label && (
        <label className="text-sm sm:text-base font-medium mb-1" htmlFor={name}>
          {label}
          {required && <span className="text-red">*</span>}
        </label>
      )}

      <Select
        {...field}
        {...props}
        options={options}
        name={field.name}
        defaultValue={field.value}
        value={
          options ? options.find((option) => option.value === field.value) : ""
        }
        onChange={(option) => helpers.setValue(option.value)}
        onBlur={field.onBlur}
        isMulti={false}
        closeMenuOnSelect={true}
        hideSelectedOptions={false}
        isClearable={false}
        maxMenuHeight={248}
        placeholder={placeholder}
        components={{
          IndicatorSeparator: () => null,
        }}
        styles={customStyles}
        onClick={(e) => e.stopPropagation()}
      />
      {showError && (
        <div className="flex items-center mt-1 text-xs sm:text-sm text-red">
          <MdErrorOutline className="mr-1" /> <span>{meta.error}</span>
        </div>
      )}
      {remarkText && (
        <div className="flex items-center text-xs sm:text-sm mt-1">
          {remarkText}
        </div>
      )}
    </>
  );
}

export default SelectField;
