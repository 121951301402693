import React from "react";
import { page } from "../assets/copy/page";
import Container from "../components/layout/container";

export default function Privacy() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container seo={{ title: `FoodHeart | ${page.PRIVACY}` }}>
      <div className="pt-10 sm:pt-16 pb-8 px-6 sm:px-12 overflow-hidden">
        <div className="w-full max-w-container1200 mx-auto relative flex flex-col">
          <div className="mb-8">
            <h1 className="text-2xl sm:text-3xl font-bold">{page.PRIVACY}</h1>
          </div>
          <div className="mb-8">
            <p className="mb-5">
              This Privacy Policy explains how FoodHeart Sdn Bhd (here in after
              referred to as "FoodHeart"), the company of
              http://www.foodheart.com.my, uses your personal information which
              you provided to FoodHeart when interacting with FoodHeart or by
              using our service, including but not limited to our FoodHeart
              website http://www.foodheart.com.my (“Website”) and mobile
              applications (Apps). In this respect, you shall be responsible for
              providing accurate, not misleading, complete and up-to-date
              personal information to FoodHeart and consent to the processing of
              these personal information by FoodHeart in Malaysia and outside
              Malaysia.
            </p>
            <p className="mb-5">
              In the event of any conflict between the English and any other
              language version in the website, the English version shall
              prevail. FoodHeart shall have the right to modify, update or amend
              the terms of this Privacy Policy at any time by placing the
              updated Privacy Policy on the Website. By continuing to interact
              with FoodHeart or by continuing to use FoodHeart’s services
              following the modifications, updates or amendments to this Privacy
              Policy shall signify your acceptance of such modifications,
              updates or amendments.
            </p>
            <p className="mb-5">
              “Personal Information” refers to personal data which you have
              provided to FoodHeart and any information that can be used to
              identify you.
            </p>
            <p className="mb-5">
              “Processing” refers to collecting, holding, storing, recording,
              organizing, adapting, using, disclosing or correcting Personal
              Information.
            </p>

            <hr className="bg-grey-light my-8" />

            <h4 className="text-xl font-bold mb-5">
              WHAT PERSONAL INFORMATION FOODHEART COLLECTS FROM YOU?
            </h4>
            <p className="mb-5">
              FoodHeart collects Personal Information from you when you register
              with FoodHeart, when you order goods or services from FoodHeart or
              use the Website or the App such as but not limited to your name,
              address, telephone number, NRIC/passport no., date of birth, email
              address and credit/debit card details. FoodHeart also collects
              Personal Information when you complete any customer survey.
              Website usage information may also be collected using cookies.
            </p>
            <p className="mb-5">
              The provision of your Personal Information is voluntary. However,
              in the event you do not provide the required Personal Information,
              FoodHeart may not be able to communicate with you and/or to
              provide you with the services requested.
            </p>

            <hr className="bg-grey-light my-8" />

            <h4 className="text-xl font-bold mb-5">
              COOKIES AND GOOGLE ANALYTICS
            </h4>
            <p className="mb-5">
              Cookies are small text files that are placed on your computer by
              websites that you visit. They are widely used in order to make
              websites work, or work more efficiently, as well as to provide
              information to the owners of the site. This Website uses Google
              Analytics, a web analytics service provided by Google, Inc.
              (“Google”). Google Analytics uses “cookies”, which are text files
              saved on your computer, to help the Website analyses how you use
              the site. The information generated by the cookie about your use
              of the Website will be transmitted to and stored by Google on
              servers in the United States. If this Website anonymizes IP
              addresses, your IP address will be truncated by Google within a EU
              member state or other EEA state before being transmitted to the
              US. Only in exceptional situations will your full IP address be
              transmitted to Google servers in the United States and truncated
              there. Google will use this information for the purpose of
              evaluating your use of the Website, compiling reports on Website
              activity for Website operators and providing other services
              relating to Website activity and internet usage.
            </p>
            <p className="mb-5">
              Google will not associate your IP address with any other data held
              by Google. You may refuse the use of cookies by selecting the
              appropriate settings on your browser, however please note that if
              you do this you may not be able to use the full functionality of
              this Website. By using this Web-site, you consent to the
              processing of Personal Information about you by Google in the
              manner and for the purposes set out above.
            </p>
            <p className="mb-5">
              You can also prevent Google from collecting Personal Information
              (including your IP address) via cookies and processing this
              Personal Information by downloading this browser plugin and
              installing it: https://tools.google.com/dlpage/gaoptout. Most web
              browsers allow some control of most cookies through the browser
              settings. To find out more about cookies, including how to see
              what cookies have been set and how to manage and delete them,
              visit www.allaboutcookies.org.
            </p>

            <hr className="bg-grey-light my-8" />

            <h4 className="text-xl font-bold mb-5">
              HOW WILL FOODHEART USE THE PERSONAL INFORMATION COLLECTED FROM
              YOU?
            </h4>
            <p className="mb-5">
              Personal Information collected by FoodHeart is used to process
              your order and to manage your account. FoodHeart may also use your
              Personal Information to email you about other products or services
              that FoodHeart thinks may be of interest to you and to comply with
              its obligations under applicable laws, where required.
            </p>

            <hr className="bg-grey-light my-8" />

            <h4 className="text-xl font-bold mb-5">
              TRANSFER AND DISCLOSURE OF PERSONAL INFORMATION
            </h4>
            <p className="mb-5">
              As FoodHeart’s storage facilities and servers may be located in
              other jurisdictions; your Personal Information may be transferred
              to, stored, used and processed in a jurisdiction other than
              Malaysia. In processing your order, FoodHeart may send your
              Personal Information to credit reference and fraud prevention
              agencies where permitted, under applicable laws.
            </p>

            <hr className="bg-grey-light my-8" />

            <h4 className="text-xl font-bold mb-5">
              RETENTION OF YOUR PERSONAL INFORMATION
            </h4>
            <p className="mb-5">
              FoodHeart will retain your Personal Information for the duration
              necessary to fulfill the foregoing purposes and until you delete
              your FoodHeart account.
            </p>

            <hr className="bg-grey-light my-8" />

            <h4 className="text-xl font-bold mb-5">OTHER WEBSITES</h4>
            <p className="mb-5">
              Our website may have links to other websites. This Privacy Policy
              only applies to this Website. You should therefore read the
              privacy policies of the other websites when you are using those
              sites.
            </p>
          </div>
        </div>
      </div>
    </Container>
  );
}
