import { useState } from "react";
import Button from "../button";
import { GiBowlOfRice, GiSushis, GiHotMeal, GiPerson } from "react-icons/gi";
import SubscribePlanModal from "../modal/subscribe-plan";

export default function SubscribePlanCard({ data }) {
  const [plan, setPlan] = useState(null);
  const [planDay, setPlanDay] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClickPlan = (id, day) => {
    setPlan(id);
    setPlanDay(day);
    setOpen(true);
  };

  return (
    <div>
      <div className="flex flex-col justify-between items-center h-full p-6 bg-white border border-solid border-[#e5e5e5] shadow-lg rounded-md hover:border-primary-light relative">
        <h2 className="text-sm sm:text-base font-bold mb-6 pb-1 border-b-2 border-solid border-primary ease-in-out">
          <span className="text-[62px] !leading-[50px]">{data.day}</span> Days
          Plan
        </h2>
        <div>
          <table className="w-full mb-6">
            <thead>
              <tr>
                <th></th>
                <th className="text-center p-2">
                  <div className="flex flex-row justify-center">
                    <GiPerson className="text-xl" />
                  </div>
                </th>
                <th className="text-center p-2">
                  <div className="flex flex-row justify-center">
                    <GiPerson className="text-xl" />
                    <GiPerson className="text-xl -ml-3" />
                  </div>
                </th>
                <th className="text-center p-2">
                  <div className="flex flex-row justify-center">
                    <GiPerson className="text-xl" />
                    <GiPerson className="text-xl -ml-3" />
                    <GiPerson className="text-xl -ml-3" />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-center p-2 pr-4">
                  <GiBowlOfRice className="text-[32px] mx-auto" />
                </td>
                <td className="text-center p-2 text-sm">
                  RM {data.prices.Chinese.pax_1}
                </td>
                <td className="text-center p-2 text-sm">
                  RM {data.prices.Chinese.pax_2}
                </td>
                <td className="text-center p-2 text-sm">
                  RM {data.prices.Chinese.pax_3}
                </td>
              </tr>
              <tr>
                <td className="text-center p-2 pr-4">
                  <GiHotMeal className="text-[32px] mx-auto" />
                </td>
                <td className="text-center p-2 text-sm">
                  RM {data.prices.Western.pax_1}
                </td>
                <td className="text-center p-2 text-sm">
                  RM {data.prices.Western.pax_2}
                </td>
                <td className="text-center p-2 text-sm">
                  RM {data.prices.Western.pax_3}
                </td>
              </tr>
              <tr>
                <td className="text-center p-2 pr-4">
                  <GiSushis className="text-[32px] mx-auto" />
                </td>
                <td className="text-center p-2 text-sm">
                  RM {data.prices.Japanese.pax_1}
                </td>
                <td className="text-center p-2 text-sm">
                  RM {data.prices.Japanese.pax_2}
                </td>
                <td className="text-center p-2 text-sm">
                  RM {data.prices.Japanese.pax_3}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="flex flex-row">
          <Button
            type="button"
            name="Subscribe Now"
            color="primary"
            buttonClassName="w-full"
            onClick={() => handleClickPlan(data.id, data.day)}
          />
        </div>
        <SubscribePlanModal
          open={open}
          setOpen={setOpen}
          plan={plan}
          planDay={planDay}
        />
      </div>
    </div>
  );
}
