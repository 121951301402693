import React, { useEffect, useState } from "react";
import Container from "../../components/layout/container";
import { path } from "../../assets/copy/path";
import { useRouter } from "../../hook/useRouter";
import { useAuth } from "../../hook/useAuth";
import Button from "../../components/elements/button";
import { alertModal } from "../../components/elements/modal/alert";

export default function VerifyEmail() {
  const auth = useAuth();
  const { location, navigate, query } = useRouter();
  const { id, expires, signature } = query;
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState(400);
  const [message, setMessage] = React.useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    async function fetchData() {
      if (expires && id && signature) await auth.verify(id, expires, signature);
      setIsLoading(false);
    }
    fetchData();
  }, []);

  // const onClickResend = async () => {
  //   // auth.resendVerify({ email: body.email });
  // };

  return (
    <Container loading={isLoading}>
      {id && expires && signature && response && (
        <div className="py-10 sm:py-16 px-6 sm:px-12 overflow-hidden">
          {/* <div className="w-full max-w-container600 m-auto p-10 relative flex flex-col items-center bg-white rounded-3xl shadow-login"> */}
          {/* {response === 200 ? (
              <>
                <div class="sa">
                  <div class="sa-success">
                    <div class="sa-success-tip"></div>
                    <div class="sa-success-long"></div>
                    <div class="sa-success-placeholder"></div>
                    <div class="sa-success-fix"></div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div class="sa">
                  <div class="sa-error">
                    <div class="sa-error-x">
                      <div class="sa-error-left"></div>
                      <div class="sa-error-right"></div>
                    </div>
                    <div class="sa-error-placeholder"></div>
                    <div class="sa-error-fix"></div>
                  </div>
                </div>
              </>
            )} */}
          {/* <h2 className="text-xl sm:text-2xl font-bold text-black-primary text-center uppercase">
              {response === 200
                ? "Verification Success"
                : "Verification Failed"}
            </h2> */}

          {/* <p className="mt-2 text-center">
              {response === 200 ? (
                <>
                  Your email has been verified.
                  <br />
                  You can now login with your new account.
                </>
              ) : (
                <>
                  The verification link is invalid. <br />
                  Please click the resend button to receive new verification
                  email.
                </>
              )}
            </p> */}
          {/* <div className="mt-10">
              <Button
                type="button"
                name={response === 200 ? "Login" : "Resend Verification Email"}
                color="primary"
                buttonClassName=""
                onClick={() =>
                  response === 200 ? navigate(path.LOGIN) : onClickResend()
                }
              />
            </div> */}

          {/* Expired */}
          {/* <p className="mt-2 text-center">
              Ops! The email verification link has expired.
            </p>
            <Button
              type="button"
              name="Resend"
              color="primary"
              buttonClassName="w-full"
              onClick={() => onClickResend()}
            /> */}
          {/* <div className="w-full mt-8">
              <div className="text-sm sm:text-base text-center mt-6">
                Didn't receive email?{" "}
                <span
                  className="font-bold text-primary"
                  onClick={() => onClickResend()}
                >
                  Resend
                </span>
              </div>
            </div> */}
          {/* </div> */}
        </div>
      )}
    </Container>
  );
}
