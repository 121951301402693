import React from "react";
import ProfileNav from "../../components/elements/profile-nav";
import Container from "../../components/layout/container";
import { Post } from "../../services";
import { alertModal } from "../../components/elements/modal/alert";
import { useAuth } from "../../hook/useAuth";
import { useCart } from "../../hook/useCart";
import { useRouter } from "../../hook/useRouter";
import { format } from "date-fns";
import Pagination from "@mui/material/Pagination";
import { page } from "../../assets/copy/page";
import { path } from "../../assets/copy/path";
import { FaEye, FaSpinner } from "react-icons/fa";
import { Link } from "react-router-dom";

function OrderHistory() {
  const auth = useAuth();
  const cart = useCart();
  const { location, navigate, state } = useRouter();
  const [isLoading, setIsLoading] = React.useState(true);
  const [isReady, setIsReady] = React.useState(false);
  const [data, setData] = React.useState({});
  const [list, setList] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(1);

  React.useEffect(() => {
    const body = getBody();
    Post(`/order/history/search`, body)
      .then((response) => {
        setData(response);
        setList(response.data);
        setIsReady(true);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error: ", error);
        if (error.code_status === 401) {
          auth.logout();
          alertModal
            .fire({
              title: "Session Expired", // Title
              text: "Please login to continue", // Text
              icon: "error", // Icon
              confirmButtonText: "Ok", // confirmButtonText
            })
            .then((result) => {
              if (result.isConfirmed) {
                // Go to login page
                navigate(path.LOGIN, {
                  state: {
                    ...state,
                    redirectTo: location,
                  },
                });
              }
            });
        } else {
          alertModal.fire(
            "Error", // Title
            error.message, // Text
            "error", // Icon
            "Ok" // confirmButtonText
          );
        }
      });
    if (state?.scrollTo) {
      const section = document.getElementById(state.scrollTo);
      section && scrollToSection(section);
    } else window.scrollTo(0, 0);
  }, [currentPage]);

  const scrollToSection = (section) => {
    const headerOffset = document.getElementById("header").offsetHeight;
    const sectionPosition = section.getBoundingClientRect().top;
    const offsetPosition = sectionPosition + window.pageYOffset - headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
    // navigate(path.ORDERHISTORY, { replace: true });
  };

  const getBody = () => {
    return {
      limit: 15,
      page: currentPage,
      order_by: {
        created_at: "desc",
      },
    };
  };

  const handleChange = (e, value) => {
    setIsReady(false);
    setCurrentPage(value);
  };

  return (
    <Container
      loading={isLoading}
      seo={{ title: `FoodHeart | ${page.ORDERHISTORY}` }}
    >
      <div className="px-6 sm:px-12 overflow-hidden">
        <div className="py-5 sm:py-8 w-full max-w-container1200 mx-auto relative flex flex-col md:flex-row justify-center">
          <ProfileNav hideMobile={true} />
          <div
            className="py-5 sm:py-8 w-full md:w-3/5 flex flex-col"
            id="order-history"
          >
            <h1 className="text-2xl sm:text-3xl font-bold">
              {page.ORDERHISTORY}
            </h1>

            {/* Listing */}
            <div className="py-10">
              {isReady ? (
                <table className="w-full">
                  <thead>
                    <tr className="bg-primary text-sm sm:text-base text-white">
                      <th className="w-1/5 text-center px-2 py-4 sm:px-4 rounded-l-3xl overflow-hidden">
                        Order time
                      </th>
                      <th className="w-2/5 text-center px-2 py-4 sm:px-4">
                        Item
                      </th>
                      <th className="w-1/5 text-center px-2 py-4 sm:px-4">
                        Subtotal
                      </th>
                      {/* <th className="w-1/5 text-center px-2 py-4 sm:px-4">
                        Status
                      </th> */}
                      <th className="w-1/5 text-center px-2 py-4 sm:px-4 rounded-r-3xl overflow-hidden"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {list.length > 0 ? (
                      list.map((item, i) => (
                        <tr
                          key={i}
                          className="align-top [&>td]:first:pt-4 [&>td]:last:pb-4 text-sm sm:text-base border-b border-b-grey-light"
                        >
                          <td className="text-xs sm:text-sm text-grey-dark text-left py-3 px-2 sm:px-4">
                            {format(
                              new Date(item.detail.created_at),
                              "dd-MM-yyyy HH:mm:ss"
                            )}
                          </td>
                          <td className="text-xs sm:text-sm text-grey-dark text-left py-3 px-2 sm:px-4">
                            Order ID: {item.detail.id}
                            {item.order_history.length > 0 && (
                              <ul className="list-disc">
                                {item.order_history.map((orderItem, j) => (
                                  <li className="ml-5" key={j}>
                                    {orderItem.name}
                                  </li>
                                ))}
                              </ul>
                            )}
                          </td>
                          <td className="text-xs sm:text-sm text-grey-dark text-left py-3 px-2 sm:px-4">
                            RM{" "}
                            {cart.convertPriceWithThousandSeparator(
                              item.detail.sub_total
                            )}
                          </td>
                          {/* <td className="text-xs sm:text-sm text-grey-dark text-left py-3 px-2 sm:px-4">
                          {renderStatus(item.status)}
                          </td> */}
                          <td className="text-xs sm:text-sm text-grey-dark text-left py-3 px-2 sm:px-4">
                            <Link
                              className="mt-[2px] mx-auto rounded-full text-primary"
                              to={`${path.ORDERDETAILS}/${item.detail.id}`}
                              state={{ data: item }}
                            >
                              <FaEye className="text-2xl" />
                            </Link>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className="align-top">
                        <td className="text-center py-2 px-4" colSpan={4}>
                          No order history available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              ) : (
                <div className="min-h-[300px] flex justify-center items-center">
                  <FaSpinner className="text-2xl text-grey animate-spin" />
                </div>
              )}
            </div>

            <div className="pb-5 sm:pb-8 px-6 sm:px-12 overflow-hidden flex justify-center">
              <Pagination
                className={`sans`}
                count={data.last_page}
                page={currentPage}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default OrderHistory;
