import logoFull from "./logo-full.png";
import logo from "./logo.png";
import profile from "./profile.png";
import IconAboutUs01 from "./about-us/icon01.png";
import IconAboutUs02 from "./about-us/icon02.png";
import IconAboutUs03 from "./about-us/icon03.png";
import ImgAboutUs01 from "./about-us/img01.png";
import ImgAboutUs02 from "./about-us/img02.png";
import ImgAboutUs03 from "./about-us/img03.png";
import ImgAboutUs04 from "./about-us/img04.png";
import ImgAboutUs05 from "./about-us/img05.png";
import ImgAboutUs06 from "./about-us/img06.png";

const Images = {
  logoFull,
  logo,
  profile,
  IconAboutUs01,
  IconAboutUs02,
  IconAboutUs03,
  ImgAboutUs01,
  ImgAboutUs02,
  ImgAboutUs03,
  ImgAboutUs04,
  ImgAboutUs05,
  ImgAboutUs06,
};

export default Images;
