import React, { useEffect, useState } from "react";
import { ModalBody, ModalWrapper } from ".";
import { Form, Formik } from "formik";
import * as yup from "yup";
import TextField from "../form/text-field";
import SelectField from "../form/select-field";
import Checkbox from "../form/checkbox";
import Button from "../button";
import { alertModal } from "./alert";
import { PutUrlEncoding } from "../../../services";

export default function EditAddressModal({ open, setOpen, data }) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const toggle = () => {
    setOpen(!isOpen);
    setIsOpen(!isOpen);
  };

  const onSubmitEditAddress = (values, actions) => {
    actions.setSubmitting(true);
    const {
      address1,
      address2,
      postcode,
      city,
      state,
      country,
      defaultAddress,
    } = values;
    PutUrlEncoding(`/user/address/${data.id}`, {
      address_1: address1,
      address_2: address2,
      zip_code: postcode,
      city: city,
      state: state,
      country: country,
      default: defaultAddress ? 1 : 0,
    })
      .then((response) => {
        toggle();
        alertModal
          .fire(
            "Success",
            "Address updated successfully", // Text
            "success", // Icon
            "Ok" // confirmButtonText
          )
          .then((result) => {
            window.location.reload();
          });
        actions.resetForm();
        actions.setSubmitting(false);
      })
      .catch((error) => {
        console.error("Error: ", error);
        toggle();
        actions.setSubmitting(false);
      });
  };

  return (
    <ModalWrapper open={isOpen} toggle={toggle} closeOnClickOutside={true}>
      <ModalBody className="p-10">
        <h2 className="text-xl sm:text-2xl font-bold text-left mb-4">
          Edit Address
        </h2>
        <Formik
          enableReinitialize
          initialValues={{
            address1: data?.address_1,
            address2: data?.address_2,
            postcode: data?.zip_code,
            city: data?.city,
            state: data?.state,
            country: data?.country,
            defaultAddress: data?.default == 1 ? true : false,
          }}
          validationSchema={validationSchemaAddress}
          onSubmit={onSubmitEditAddress}
        >
          {({ isSubmitting, handleChange }) => (
            <Form>
              <div className="mb-6 text-left">
                <TextField
                  label={
                    <>
                      Address Line 1 <span className="text-red">*</span>
                    </>
                  }
                  name="address1"
                  type="text"
                  placeholder=""
                  autoComplete="off"
                />
              </div>
              <div className="mb-6 text-left">
                <TextField
                  label="Address Line 2"
                  name="address2"
                  type="text"
                  placeholder=""
                  autoComplete="off"
                />
              </div>
              <div className="mb-6 text-left flex flex-col sm:flex-row">
                <div className="w-full sm:w-1/2 sm:mr-2">
                  <TextField
                    label={
                      <>
                        Postcode <span className="text-red">*</span>
                      </>
                    }
                    name="postcode"
                    type="number"
                    placeholder=""
                    autoComplete="off"
                    isNumberField={true}
                  />
                </div>
                <div className="w-full sm:w-1/2 sm:ml-2">
                  <TextField
                    label={
                      <>
                        City <span className="text-red">*</span>
                      </>
                    }
                    name="city"
                    type="text"
                    placeholder=""
                    autoComplete="off"
                  />
                </div>
              </div>

              <div className="mb-6 text-left flex flex-col sm:flex-row">
                <div
                  className="w-full sm:w-1/2 sm:mr-2"
                  onClick={(e) => e.stopPropagation()}
                >
                  <SelectField
                    label={
                      <>
                        State <span className="text-red">*</span>
                      </>
                    }
                    name="state"
                    options={[
                      {
                        value: "Kuala Lumpur",
                        label: "Kuala Lumpur",
                      },
                      {
                        value: "Selangor",
                        label: "Selangor",
                      },
                    ]}
                  />
                </div>
                <div
                  className="w-full sm:w-1/2 sm:ml-2"
                  onClick={(e) => e.stopPropagation()}
                >
                  <SelectField
                    label={
                      <>
                        Country <span className="text-red">*</span>
                      </>
                    }
                    name="country"
                    options={[
                      {
                        value: "Malaysia",
                        label: "Malaysia",
                      },
                    ]}
                  />
                </div>
              </div>
              <div className="mb-6 text-left">
                <Checkbox
                  name="defaultAddress"
                  label="Default address"
                  handleChange={handleChange}
                />
              </div>

              <div className="mt-8">
                <Button
                  type="submit"
                  name="Update Address"
                  color="primary"
                  disabled={isSubmitting}
                />
              </div>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </ModalWrapper>
  );
}

const validationSchemaAddress = yup.object().shape({
  address1: yup.string().required("Address line 1 is required"),
  postcode: yup.string().required("Postcode is required"),
  city: yup.string().required("City is required"),
  state: yup.string().required("State is required"),
  country: yup.string().required("Country is required"),
});
