import React, { useEffect, useState } from "react";
import { useRouter } from "../hook/useRouter";
import Container from "../components/layout/container";
import Pagination from "@mui/material/Pagination";
import { Post } from "../services";
import { format } from "date-fns";
import { FaSpinner } from "react-icons/fa";
import MenuCard from "../components/elements/card/menu-card";
import MealMenu from "../components/elements/dropdown-menu/meal-menu";
import CuisineMenu from "../components/elements/dropdown-menu/cuisine-menu";
// import SortMenu from "../components/elements/dropdown-menu/sort-menu";

export default function MenuList() {
  const { state } = useRouter();
  const [isLoading, setIsLoading] = useState(true);
  const [isReady, setIsReady] = useState(false);
  const [data, setData] = useState({});
  const [list, setList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [sort, setSort] = useState("asc");
  const [cuisine, setCuisine] = useState(state?.cuisine || "Chinese");
  const [dayTime, setDayTime] = useState(state?.daytime || 1); // Default (All) / 1 (Lunch) / 2 (Dinner)

  useEffect(() => {
    window.scrollTo(0, 0);
    const body = getBody();
    Post(`/menu/listing/search`, body)
      .then((response) => {
        setData(response);
        // setCurrentPage(response.current_page);
        setList(response.data);
        setIsReady(true);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  }, [currentPage, sort, cuisine, dayTime]);

  const getBody = () => {
    let gteqDate = "";
    if (
      currentPage === 1 &&
      ((dayTime === 1 && format(new Date(), "HH") >= 6) ||
        (dayTime === 2 && format(new Date(), "HH") >= 12))
    ) {
      const tomorrow = new Date();
      gteqDate = tomorrow.setDate(tomorrow.getDate() + 1);
    } else gteqDate = new Date();

    const body = {
      extra: {
        // menu: {
        //   eq: {
        //     name: "",
        //   },
        // },
        cuisine: {
          eq: {
            name: cuisine,
          },
        },
      },
      eq: {
        // cuisine_id: "",
        daytime: dayTime, // 1 (Day) / 2 (Night)
      },
      gteq: {
        date: format(new Date(gteqDate), "yyyy-MM-dd"), // "2022-08-02",
      },
    };

    return {
      search: body,
      limit: 12,
      page: currentPage,
      order_by: {
        date: sort,
      },
    };
  };

  const handleChange = (e, value) => {
    setIsReady(false);
    setCurrentPage(value);
  };

  return (
    <Container loading={isLoading} seo={{ title: "FoodHeart | Menu" }}>
      <div className="pt-10 sm:pt-16 pb-10 px-6 sm:px-12 overflow-hidden">
        <div className="w-full max-w-container1200 mx-auto relative flex flex-col">
          {/* Filter */}
          <div className="flex flex-col sm:flex-row justify-between items-center sm:items-end">
            <div className="mb-4 w-full sm:w-2/5">
              <h1 className="text-2xl sm:text-3xl font-bold">Menu</h1>
            </div>
            <div className="flex sm:justify-end flex-wrap mb-4 -my-1 -mx-3 w-[calc(100%+24px)] sm:w-[calc(80%+24px)]">
              <CuisineMenu
                cuisine={cuisine}
                setCuisine={setCuisine}
                setIsReady={setIsReady}
              />
              <MealMenu
                dayTime={dayTime}
                setDayTime={setDayTime}
                setIsReady={setIsReady}
              />
              {/* <SortMenu sort={sort} setSort={setSort} setIsReady={setIsReady} /> */}
            </div>
          </div>
          <div>
            <p className="text-xs sm:text-sm mb-8">
              Delivery time for {dayTime === 1 ? "lunch" : "dinner"}:{" "}
              <span className="font-bold">
                {dayTime === 1 ? "10am - 2pm" : "3pm - 7pm"}
              </span>
            </p>
          </div>

          {/* Listing */}
          <div>
            {isReady ? (
              list.length > 0 ? (
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-6">
                  {list.map((item, i) => {
                    return (
                      <div key={i} className="">
                        <MenuCard key={i} data={item} index={i} />
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div>No content available</div>
              )
            ) : (
              <div className="min-h-[300px] flex justify-center items-center">
                <FaSpinner className="text-2xl text-grey animate-spin" />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="pb-5 sm:pb-8 px-6 sm:px-12 overflow-hidden flex justify-center">
        <Pagination
          className={`sans`}
          count={data.last_page}
          page={currentPage}
          onChange={handleChange}
        />
      </div>
    </Container>
  );
}
