import React from "react";
import Container from "../../components/layout/container";
import { useAuth } from "../../hook/useAuth";
import { useCart } from "../../hook/useCart";
import { useRouter } from "../../hook/useRouter";
import { FaCheck } from "react-icons/fa";
import { format } from "date-fns";
import { Get, PutUrlEncoding } from "../../services";
import { alertModal } from "../../components/elements/modal/alert";
import { path } from "../../assets/copy/path";
import Button from "../../components/elements/button";

export default function EditOrder() {
  const auth = useAuth();
  const { location, state, navigate, query } = useRouter();
  const [isLoading, setIsLoading] = React.useState(true);
  const [availableDateList, setAvailableDateList] = React.useState({});
  const [newSelectedDate, setNewSelectedDate] = React.useState("");

  React.useEffect(() => {
    if (state?.date && state?.data && query?.id == state?.data[0].cart_id)
      getAvailableDates(query.id);
    else navigate(path.ORDERDETAILS);
  }, []);

  const getAvailableDates = (id) => {
    Get(`/order/history/update/date/${id}`)
      .then((response) => {
        handleCompareDate(response.data.menu);
      })
      .catch((error) => {
        console.error("Error: ", error);
        if (error.code_status === 401) {
          auth.logout();
          alertModal
            .fire({
              title: "Session Expired",
              text: "Please login to continue",
              icon: "error",
              confirmButtonText: "Ok",
            })
            .then((result) => {
              if (result.isConfirmed) {
                // Go to login page
                navigate(path.LOGIN, {
                  state: {
                    ...state,
                    redirectTo: location,
                  },
                });
              }
            });
        } else {
          alertModal.fire("Error", error.message, "error", "Ok");
        }
        setIsLoading(false);
      });
  };

  const handleCompareDate = (data) => {
    if (data.hasOwnProperty(state.date)) setAvailableDateList(data[state.date]);
    setIsLoading(false);
  };

  const handleClickNewDate = (date) => {
    setNewSelectedDate(date);
  };

  const handleUpdateOrder = () => {
    PutUrlEncoding(`/order/history/update`, {
      cart_id: query.id,
      date: state.date,
      new_date: newSelectedDate,
    })
      .then((response) => {
        alertModal
          .fire("Success", response.message, "success", "Ok")
          .then((result) => {
            if (result.isConfirmed) {
              // Go to login page
              navigate(path.ORDERHISTORY);
            }
          });
      })
      .catch((error) => {
        console.error("Error: ", error);
        if (error.code_status === 401) {
          auth.logout();
          alertModal
            .fire({
              title: "Session Expired",
              text: "Please login to continue",
              icon: "error",
              confirmButtonText: "Ok",
            })
            .then((result) => {
              if (result.isConfirmed) {
                // Go to login page
                navigate(path.LOGIN, {
                  state: {
                    ...state,
                    redirectTo: location,
                  },
                });
              }
            });
        } else {
          alertModal.fire("Error", error.message, "error", "Ok");
        }
      });
  };

  return (
    <Container loading={isLoading} seo={{ title: "FoodHeart | Subscribe" }}>
      <div className="py-10 sm:py-16 px-6 sm:px-12 overflow-hidden">
        <div className="w-full max-w-container1200 mx-auto relative flex flex-col">
          <h1 className="text-2xl sm:text-3xl font-bold text-center pb-6">
            Edit Order
          </h1>
          {!isLoading && (
            <div>
              {/* Existing */}
              <div className="mb-10">
                <div className="mb-6">
                  <p className="text-xl font-semibold mb-1">
                    Current selected date to change:
                  </p>
                </div>
                <div>
                  <p className="text-sm sm:text-base font-bold mb-2">
                    {format(new Date(state.date), "dd MMM yyyy, E")}
                  </p>
                  {state.data.length > 0 && (
                    <div className="flex flex-col gap-4">
                      {state.data.map((item, i) => (
                        <div>
                          <p className="text-sm sm:text-base font-medium">
                            Pax {i + 1}:
                          </p>
                          <p className="text-xs sm:text-sm">{item.name}</p>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              {/* New date available */}
              <div>
                <div className="mb-6">
                  <p className="text-xl font-semibold mb-1">
                    Please choose your new desired date:
                  </p>
                  <span className="text-3xs sm:text-xs">
                    *Note: Please note that you are only allowed to change the
                    date of your subscription plan. We regret to inform you that
                    changes to the cuisine type are not permitted. However, rest
                    assured that the meal for the new date will adhere to the
                    cuisine you had selected previously.
                  </span>
                </div>
                {Object.keys(availableDateList).length > 0 ? (
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                    {Object.entries(availableDateList).map(
                      ([date, menu], i) => (
                        <Card
                          key={i}
                          date={date}
                          menu={menu}
                          selected={newSelectedDate}
                          handleClick={handleClickNewDate}
                        />
                      )
                    )}
                  </div>
                ) : (
                  <div>No New Date Available</div>
                )}
              </div>
              <div className="flex flex-row justify-center mt-10">
                <Button
                  name="Confirm"
                  type="button"
                  onClick={handleUpdateOrder}
                  color="primary"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </Container>
  );
}

const Card = ({ date, menu, selected, handleClick }) => {
  return (
    <div
      className={`relative p-6 bg-white ${
        selected === date
          ? "border-4 border-primary p-[21px]"
          : "border-2 border-gray-200"
      } border-solid shadow-md rounded-xl cursor-pointer`}
      onClick={() => {
        handleClick(date);
      }}
    >
      <div
        className={`absolute top-0 right-0 border-l-[50px] border-r-[50px] border-b-[50px] border-solid border-l-transparent border-b-transparent ${
          selected === date
            ? "border-r-primary rounded-tr-md"
            : "border-r-gray-200 rounded-tr-lg"
        } `}
      >
        <FaCheck className="absolute top-2 left-7 text-white" />
      </div>
      <div className="flex flex-col w-full max-w-full">
        <p className="text-sm sm:text-base font-bold mb-4 text-center">
          {format(new Date(date), "dd MMM yyyy, E")}
        </p>
        {menu.length > 0 && (
          <div className="flex flex-col gap-4">
            {menu.map((item, i) => (
              <div>
                <p className="text-sm sm:text-base font-medium">Pax {i + 1}:</p>
                <p className="text-xs sm:text-sm">{item}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
