import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import * as yup from "yup";
import Container from "../../components/layout/container";
import TextField from "../../components/elements/form/text-field";
import Button from "../../components/elements/button";
import { useAuth } from "../../hook/useAuth";
import { page } from "../../assets/copy/page";
import { path } from "../../assets/copy/path";

const initialValues = {
  email: "",
};

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email")
    .max(255, "Email must be less than 255 characters")
    .required("Email is required"),
});

function ForgotPassword() {
  const auth = useAuth();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onSubmit = (values, actions) => {
    const { email } = values;
    auth.forgotPassword(
      {
        email: email,
      },
      actions
    );
  };

  return (
    <Container>
      <div className="py-10 sm:py-16 px-6 sm:px-12 overflow-hidden">
        <div className="w-full max-w-container600 m-auto p-10 relative flex flex-col items-center bg-white rounded-3xl shadow-login">
          <h2 className="text-xl sm:text-2xl font-bold text-black-primary text-center uppercase">
            {page.FORGOTPW}
          </h2>
          <p className="mt-2 text-center">
            Enter your account email and we will send you a reset password link
          </p>
          <div className="w-full mt-8">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ isSubmitting, dirty, isValid }) => (
                <Form>
                  <div className="form-input mb-6">
                    <TextField
                      label="Email"
                      name="email"
                      type="text"
                      placeholder=""
                      autoComplete="off"
                    />
                  </div>
                  <div className="form-input mt-8">
                    <Button
                      type="submit"
                      name="Submit"
                      color="primary"
                      buttonClassName="w-full"
                      disabled={!(dirty && isValid) || isSubmitting}
                    />
                  </div>
                </Form>
              )}
            </Formik>
            <div className="text-sm sm:text-base text-center mt-6">
              Don't have an account?&nbsp;
              <Link className="font-bold text-primary" to={path.REGISTER}>
                {page.REGISTER}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default ForgotPassword;
