import React, { useEffect, useRef } from "react";
import { CgChevronDown } from "react-icons/cg";

export default function SubscribeMenu({
  menu,
  selectedMenu,
  defaultMenu,
  setSelectedMenu,
  setIsDefaultMenu,
  disabled,
  paxMenu,
  paxIndex,
  cardIndex,
  date,
  selectedAddOnIds,
  selectedDrinksIds,
  calculatePrice = { calculatePrice },
}) {
  const [show, setShow] = React.useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!ref.current?.contains(event.target)) {
        if (!show) return;
        setShow(false);
      }
    };
    window.addEventListener("click", handleOutsideClick);
    return () => window.removeEventListener("click", handleOutsideClick);
  }, [show, ref]);

  const toggle = (e) => {
    e.stopPropagation();
    setShow(!show);
  };

  const handleClick = (val, defaultMenu) => {
    setSelectedMenu(val);
    if (defaultMenu) setIsDefaultMenu(1);
    else setIsDefaultMenu(0);
    const newArr = paxMenu[`menu[${paxIndex}]`].filter((item) => {
      return item.index !== cardIndex;
    });
    paxMenu[`menu[${paxIndex}]`] = [
      ...newArr,
      {
        index: cardIndex,
        id: val.product_id,
        name: val.name,
        date: date,
        default: defaultMenu ? 1 : 0,
        add_on: selectedAddOnIds,
        drinks: selectedDrinksIds,
      },
    ];
    calculatePrice();
  };

  const Option = ({ item, defaultMenu }) => {
    return (
      <div>
        {!defaultMenu && (
          <div className="pt-3 pb-1 px-4">{item.cuisine_id}</div>
        )}
        <div
          className={`${styles.dropdownItem} ${
            selectedMenu.product_id === item.product_id &&
            selectedMenu.name === item.name
              ? styles.selectedDropdownItem
              : ""
          }`}
          onClick={(e) => {
            handleClick(item, defaultMenu);
            toggle(e);
          }}
          tabIndex={0}
          role="menuitem"
        >
          <div className="flex flex-row items-center">
            <img
              className="w-full w-[40px] min-w-[40px] h-[40px] min-h-[40px] aspect-square object-cover object-center rounded-md overflow-hidden bg-grey"
              src={item.images}
              alt={item.name}
            />
            <div className="flex flex-col ml-2">
              <p className="text-sm sm:text-base">{item.name}</p>
              <span className="text-xs sm:text-sm">
                {/* {defaultMenu ? "Default" : item.cuisine_id} */}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      ref={ref}
      className={`relative mb-4 ${
        show ? "open" : ""
      } [&>div.dropdown-menu]:h-auto transition duration-150 ease-in-out`}
    >
      <button
        className="relative flex flex-row bg-white border border-solid border-grey rounded-md p-2 pr-6 w-full"
        onClick={(e) => toggle(e)}
        type="button"
        id="options-menu"
        aria-haspopup="true"
        data-bs-toggle="dropdown"
        aria-expanded={show ? "true" : undefined}
        disabled={disabled}
      >
        <p className="text-ellipsis overflow-hidden max-w-[250px] whitespace-nowrap">
          {selectedMenu.name}
        </p>
        <CgChevronDown className="text-base absolute top-0 right-1 h-full" />
      </button>
      {show && (
        <div
          className="dropdown-menu w-full max-w-screen sm:max-w-container400 h-0 max-h-[350px] overflow-y-scroll invisible opacity-0 absolute bg-white py-2 rounded-md shadow z-40"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          {menu.length > 0 &&
            menu.map((item, i) => <Option key={i} item={item} />)}
          {defaultMenu.length > 0 && (
            <div>
              <div className="pt-3 pb-1 px-4">Default</div>
              {defaultMenu.map((item, i) => (
                <Option key={i} item={item} defaultMenu={1} />
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

const styles = {
  dropdownItem: `py-2 px-6 cursor-pointer hover:bg-primary-extra-light-50`,
  selectedDropdownItem: `!bg-primary-extra-light`,
};
