import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import LoadingOverlay from "react-loading-overlay-ts";
import { path } from "../../assets/copy/path";
import { useRouter } from "../../hook/useRouter";
import Footer from "../elements/footer";
import Header from "../elements/header";
import WhatsappIcon from "../../assets/images/whatsapp.png";
import { companyInfo } from "../../assets/copy/companyInfo";

export default function Container({
  loading,
  seo,
  children,
  showWhatsappButton,
}) {
  const { pathname } = useRouter();

  const [headerOffset, setHeaderOffset] = useState("92");
  const [isAuthPage, setIsAuthPage] = useState(false);

  useEffect(() => {
    getHeaderHeight();
    checkIfIsAuthPage();

    const onResize = () => {
      getHeaderHeight();
    };
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  const getHeaderHeight = () => {
    setHeaderOffset(document.getElementById("header").offsetHeight);
  };

  const checkIfIsAuthPage = () => {
    if (
      pathname === path.LOGIN ||
      pathname === path.REGISTER ||
      pathname === path.FORGOTPW ||
      pathname === path.RESETPW ||
      pathname === path.VERIFYEMAIL
    ) {
      setIsAuthPage(true);
    } else {
      setIsAuthPage(false);
    }
  };

  return (
    <LoadingOverlay
      active={loading}
      spinner
      fadeSpeed={300}
      styles={{
        wrapper: (base) => ({
          ...base,
          // position: "initial",
        }),
        overlay: (base) => ({
          ...base,
          zIndex: 9999,
          height: `calc(100% + ${headerOffset}px)`,
          top: `-${headerOffset}px`,
        }),
        spinner: (base) => ({
          ...base,
          position: "fixed",
          top: "50%",
          left: "calc(50% - 50px)",
          right: "calc(50% - 50px)",
          zIndex: 999,
        }),
      }}
    >
      <Helmet>
        <title>{seo.title}</title>
      </Helmet>
      <Header />
      <main className="min-h-main">{children}</main>
      {!(
        pathname === path.LOGIN ||
        pathname === path.REGISTER ||
        pathname === path.FORGOTPW ||
        pathname === path.RESETPW ||
        pathname === path.VERIFYEMAIL
      ) && <Footer />}
      {showWhatsappButton && (
        <div className="fixed right-4 bottom-4 rounded-full bg-white drop-shadow-xl shadow-xl p-3 max-w-[60px] z-50">
          <a
            className="flex flex-row items-center"
            href={companyInfo.waLink}
            target="_blank"
            rel="noreferrer"
          >
            <img className="" src={WhatsappIcon} alt="" />
          </a>
        </div>
      )}
    </LoadingOverlay>
  );
}

Container.defaultProps = {
  loading: false,
  seo: {},
  children: "",
};

Container.propTypes = {
  loading: PropTypes.bool,
  seo: PropTypes.object,
  children: PropTypes.node,
};
