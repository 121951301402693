import Images from "../images/images";

export const companyInfo = {
  name: "FOODHEART SDN BHD",
  regNo: "202201008974 (1454671-K)",
  logo: Images.logoFull,
  contact: "+60 12 607 8702",

  address1: "22, Jalan TK 5/44",
  address2: "Kinrara Industrial Park",
  poscode: "47190",
  city: "Puchong",
  state: "Selangor",
  country: "Malaysia",
  wazeLink:
    "https://ul.waze.com/ul?place=ChIJFzuwFSVLzDERwuCd_hApECc&ll=3.06166770%2C101.63439120&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location",

  email: "enquiry@foodheart.com.my",
  emailLink: "mailto:enquiry@foodheart.com.my",
  wa: "+60 12 607 8702",
  waLink: "https://wa.me/60126078702",
  fb: "FoodHeart Kitchen",
  fbLink: "https://www.facebook.com/FoodHeartKitchen/",
  ig: "foodheartkitchen",
  igLink: "https://www.instagram.com/foodheartkitchen",
};
