import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const CustomSwal = withReactContent(Swal);

export const alertModal = CustomSwal.mixin({
  showClass: {
    popup: "animate__animated animate__fadeIn animate__faster",
  },
  hideClass: {
    popup: "animate__animated animate__fadeOut animate__faster",
  },
  customClass: {
    title: "text-xl sm:text-2xl font-bold !text-primary capitalize",
    content: "text-sm sm:text-base !text-black-secondary",
    container: "text-sm sm:text-base !text-black-secondary",
    confirmButton:
      "text-sm sm:text-base font-bold text-white capitalize bg-primary hover:bg-primary-dark border-2 border-solid border-primary rounded-button shadow min-w-[120px] mt-2 mx-1 py-3 px-6 disabled:!opacity-60 disabled:pointer-events-none transition duration-300 ease",
    cancelButton:
      "text-sm sm:text-base font-bold text-black-secondary hover:text-primary capitalize bg-white border-2 border-solid border-primary rounded-button shadow min-w-[120px] mt-2 mx-1 py-3 px-6 disabled:!opacity-60 disabled:pointer-events-none transition duration-300 ease",
    denyButton:
      "text-sm sm:text-base font-bold text-black-secondary hover:text-primary capitalize bg-white border-2 border-solid border-primary rounded-button shadow min-w-[120px] mt-2 mx-1 py-3 px-6 disabled:!opacity-60 disabled:pointer-events-none transition duration-300 ease",
  },
  closeButtonHtml:
    '<svg stroke="currentColor" fill="none" stroke-width="0" viewBox="0 0 24 24" class="text-2xl outline-none" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M6.2253 4.81108C5.83477 4.42056 5.20161 4.42056 4.81108 4.81108C4.42056 5.20161 4.42056 5.83477 4.81108 6.2253L10.5858 12L4.81114 17.7747C4.42062 18.1652 4.42062 18.7984 4.81114 19.1889C5.20167 19.5794 5.83483 19.5794 6.22535 19.1889L12 13.4142L17.7747 19.1889C18.1652 19.5794 18.7984 19.5794 19.1889 19.1889C19.5794 18.7984 19.5794 18.1652 19.1889 17.7747L13.4142 12L19.189 6.2253C19.5795 5.83477 19.5795 5.20161 19.189 4.81108C18.7985 4.42056 18.1653 4.42056 17.7748 4.81108L12 10.5858L6.2253 4.81108Z" fill="currentColor"></path></svg>',
  buttonsStyling: false,
  allowOutsideClick: false,
  showCloseButton: true,
});
