import React from "react";
import ProfileNav from "../../components/elements/profile-nav";
import Container from "../../components/layout/container";
import Button from "../../components/elements/button";
import { MdErrorOutline } from "react-icons/md";
import { page } from "../../assets/copy/page";
import { path } from "../../assets/copy/path";
import { useCart } from "../../hook/useCart";
import { useAuth } from "../../hook/useAuth";
import { Get, PostUrlEncoding } from "../../services";
import { alertModal } from "../../components/elements/modal/alert";
import { useRouter } from "../../hook/useRouter";

function TopUp() {
  const auth = useAuth();
  const cart = useCart();
  const { location, navigate, state } = useRouter();
  const [isLoading, setIsLoading] = React.useState(true);
  const [profileData, setProfileData] = React.useState({});
  const [amount, setAmount] = React.useState(null);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    setIsLoading(true);
    Get(`/user/profile`)
      .then((response) => {
        setProfileData(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error: ", error);
        if (error.code_status === 401) {
          auth.logout();
          alertModal
            .fire({
              title: "Session Expired", // Title
              text: "Please login to continue", // Text
              icon: "error", // Icon
              confirmButtonText: "Ok", // confirmButtonText
            })
            .then((result) => {
              if (result.isConfirmed) {
                // Go to login page
                navigate(path.LOGIN, {
                  state: {
                    ...state,
                    redirectTo: location,
                  },
                });
              }
            });
        } else {
          alertModal.fire(
            "Error", // Title
            error.message, // Text
            "error", // Icon
            "Ok" // confirmButtonText
          );
        }
        setIsLoading(false);
      });
    if (state?.scrollTo) {
      const section = document.getElementById(state.scrollTo);
      section && scrollToSection(section);
    } else window.scrollTo(0, 0);
  }, []);

  const scrollToSection = (section) => {
    const headerOffset = document.getElementById("header").offsetHeight;
    const sectionPosition = section.getBoundingClientRect().top;
    const offsetPosition = sectionPosition + window.pageYOffset - headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  const handleSubmit = () => {
    // TODO LIVE: uncomment code when go prod
    if (amount < 10) setError("Minimum top up amount is RM10");
    else if (amount > 3000) setError("Maximum top up amount is RM3000");
    else handleReload();
  };

  const handleReload = () => {
    setIsLoading(true);
    PostUrlEncoding(`/wallet/topup`, {
      amount: amount,
    })
      .then((response) => {
        setIsLoading(false);
        window.location.href = `${process.env.REACT_APP_API_URL}/payment/gateway?trans_id=${response.data.trans_id}&user_id=${profileData.id}`;
      })
      .catch((error) => {
        console.error("Error: ", error);
        if (error.code_status === 401) {
          auth.logout();
          alertModal
            .fire({
              title: "Session Expired", // Title
              text: "Please login to continue", // Text
              icon: "error", // Icon
              confirmButtonText: "Ok", // confirmButtonText
            })
            .then((result) => {
              if (result.isConfirmed) {
                // Go to login page
                navigate(path.LOGIN, {
                  state: {
                    ...state,
                    redirectTo: location,
                  },
                });
              }
            });
        } else {
          alertModal.fire(
            "Error", // Title
            error.message, // Text
            "error", // Icon
            "Ok" // confirmButtonText
          );
        }
        setIsLoading(false);
      });
  };

  return (
    <Container loading={isLoading}>
      <div className="px-6 sm:px-12 overflow-hidden">
        <div className="py-5 sm:py-8 w-full max-w-container1200 mx-auto relative flex flex-col md:flex-row justify-center">
          <ProfileNav hideMobile={true} />
          <div className="py-5 sm:py-8 w-full md:w-3/5 flex flex-col" id="top">
            <h1 className="text-2xl sm:text-3xl font-bold">
              {page.EWALLETTOPUP}
            </h1>
            <div className="w-full md:max-w-container500 mt-10">
              <div className="mb-6">
                <label
                  className="text-sm sm:text-base font-medium mb-1"
                  htmlFor={amount}
                >
                  Amount
                </label>
                <input
                  id="amount"
                  name="amount"
                  className="w-full border border-solid border-grey rounded-lg p-3 outline-0 text-ellipsis"
                  onChange={(e) => {
                    const val = e.target.value.match(
                      /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s
                    )[0];
                    setAmount(val);
                    setError(null);
                  }}
                  value={amount}
                  placeholder="Enter your amount"
                  type={"number"}
                />
                <div className="flex items-center text-xs sm:text-sm mt-1">
                  Minimum top up amount: RM10
                </div>
                {error ? (
                  <div className="flex items-center mt-1 text-xs sm:text-sm text-red">
                    <MdErrorOutline className="mr-1" /> <span>{error}</span>
                  </div>
                ) : null}
              </div>
              <p className="text-sm sm:text-base font-medium mb-1">
                Or select the following amount:
              </p>
              <div className="-m-2">
                <Button
                  type="button"
                  name="RM20"
                  onClick={() => setAmount(20)}
                  color="secondary"
                  buttonClassName={`m-1 sm:m-2 w-[calc(100%/3-8px)] sm:w-[calc(100%/3-16px)] min-w-[100px] ${
                    amount === 20 ? "!bg-primary text-white" : null
                  }`}
                />
                <Button
                  type="button"
                  name="RM50"
                  onClick={() => setAmount(50)}
                  color="secondary"
                  buttonClassName={`m-1 sm:m-2 w-[calc(100%/3-8px)] sm:w-[calc(100%/3-16px)] min-w-[100px] ${
                    amount === 50 ? "!bg-primary text-white" : null
                  }`}
                />
                <Button
                  type="button"
                  name="RM100"
                  onClick={() => setAmount(100)}
                  color="secondary"
                  buttonClassName={`m-1 sm:m-2 w-[calc(100%/3-8px)] sm:w-[calc(100%/3-16px)] min-w-[100px] ${
                    amount === 100 ? "!bg-primary text-white" : null
                  }`}
                />
                <Button
                  type="button"
                  name="RM200"
                  onClick={() => setAmount(200)}
                  color="secondary"
                  buttonClassName={`m-1 sm:m-2 w-[calc(100%/3-8px)] sm:w-[calc(100%/3-16px)] min-w-[100px] ${
                    amount === 200 ? "!bg-primary text-white" : null
                  }`}
                />
                <Button
                  type="button"
                  name="RM500"
                  onClick={() => setAmount(500)}
                  color="secondary"
                  buttonClassName={`m-1 sm:m-2 w-[calc(100%/3-8px)] sm:w-[calc(100%/3-16px)] min-w-[100px] ${
                    amount === 500 ? "!bg-primary text-white" : null
                  }`}
                />
                <Button
                  type="button"
                  name="RM1000"
                  onClick={() => setAmount(1000)}
                  color="secondary"
                  buttonClassName={`m-1 sm:m-2 w-[calc(100%/3-8px)] sm:w-[calc(100%/3-16px)] min-w-[100px] ${
                    amount === 1000 ? "!bg-primary text-white" : null
                  }`}
                />
              </div>
              <div className="mt-10">
                <Button
                  type="submit"
                  name="Top Up"
                  onClick={handleSubmit}
                  color="primary"
                  buttonClassName="w-full md:w-auto"
                  disabled={!amount}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default TopUp;
