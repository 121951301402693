import React, { useRef, useEffect, useState } from "react";
import Slider from "react-slick";
import Hero from "../hero";

export default function HeroSlider({ list }) {
  const sliderRef = useRef(null);

  const settings = {
    autoplay: true,
    autoplaySpeed: 5000,
    dots: true,
    arrow: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    customPaging: (i) => <div className="custom-slick-dots" />,
  };

  return (
    <Slider ref={sliderRef} {...settings}>
      {list?.map((item, i) => {
        return <Hero key={i} data={item} />;
      })}
    </Slider>
  );
}
