import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../hook/useAuth";
import { useRouter } from "../../hook/useRouter";
import { BiUser } from "react-icons/bi";
import { FaCamera } from "react-icons/fa";
import {
  MdContentCopy,
  MdHistory,
  MdLogout,
  MdOutlineAccountBalanceWallet,
  MdOutlineListAlt,
} from "react-icons/md";
import { CgSpinner } from "react-icons/cg";
import { Get, PostFormData, Put } from "../../services";
import { alertModal } from "./modal/alert";
import { path } from "../../assets/copy/path";
import CopyToClipboard from "react-copy-to-clipboard";
import { page } from "../../assets/copy/page";
import { useCart } from "../../hook/useCart";

function ProfileNav({ hideMobile }) {
  const auth = useAuth();
  const cart = useCart();
  const inputFile = useRef(null);
  const { location, navigate, state } = useRouter();
  const [isLoading, setIsLoading] = React.useState(true);
  const [profilePhoto, setProfilePhoto] = React.useState(null);
  const [profileData, setProfileData] = React.useState({});
  const [balance, setBalance] = React.useState(0);

  useEffect(() => {
    setIsLoading(true);
    Get(`/user/profile`)
      .then((response) => {
        if (response.code_status === 200) {
          setProfilePhoto(response.data.image);
          setBalance(Number(response.data.wallets?.sum || 0));
          setProfileData(response.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error: ", error);
        if (error.code_status === 401) {
          auth.logout();
          alertModal
            .fire({
              title: "Session Expired",
              text: "Please login to continue",
              icon: "error",
              confirmButtonText: "Ok",
            })
            .then((result) => {
              if (result.isConfirmed) {
                // Go to login page
                navigate(path.LOGIN, {
                  state: {
                    ...state,
                    redirectTo: location,
                  },
                });
              }
            });
        } else {
          alertModal.fire("Error", error.message, "error", "Ok");
        }
        setIsLoading(false);
      });
  }, []);

  const handleOpenFileSelection = () => {
    inputFile.current.click();
  };

  const handleFileChange = (e) => {
    setIsLoading(true);
    const file = e.target.files[0];
    if (file) handleUploadFile(file);
  };

  const handleUploadFile = (file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("path", "users");
    formData.append("type", "image");

    PostFormData(`/file/upload`, formData)
      .then((response) => {
        setProfilePhoto(response.data.path);
        handleUpdateProfilePhoto(response.data.media_id);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error: ", error);
        if (error.code_status === 401) {
          auth.logout();
          alertModal
            .fire({
              title: "Session Expired",
              text: "Please login to continue",
              icon: "error",
              confirmButtonText: "Ok",
            })
            .then((result) => {
              if (result.isConfirmed) {
                // Go to login page
                navigate(path.LOGIN, {
                  state: {
                    ...state,
                    redirectTo: location,
                  },
                });
              }
            });
        } else {
          alertModal.fire("Error", error.message, "error", "Ok");
        }
      });
  };

  const handleUpdateProfilePhoto = (mediaId) => {
    Put(`/user/profile/picture/${profileData.id}`, {
      media_id: mediaId,
    })
      .then((response) => {
        alertModal.fire(
          "Success",
          "Your profile photo have been updated successfully",
          "success",
          "Ok"
        );
      })
      .catch((error) => {
        console.error("Error: ", error);
        if (error.code_status === 401) {
          auth.logout();
          alertModal
            .fire({
              title: "Session Expired",
              text: "Please login to continue",
              icon: "error",
              confirmButtonText: "Ok",
            })
            .then((result) => {
              if (result.isConfirmed) {
                // Go to login page
                navigate(path.LOGIN, {
                  state: {
                    ...state,
                    redirectTo: location,
                  },
                });
              }
            });
        } else {
          alertModal.fire("Error", error.message, "error", "Ok");
        }
      });
    setIsLoading(false);
  };

  const handleLogout = () => {
    alertModal
      .fire({
        title: "Logout?",
        text: "Are you sure you want to logout?",
        icon: "question",
        confirmButtonText: "Yes",
        showCancelButton: true,
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          auth.logout();
          navigate(path.HOME, {
            state: {
              ...state,
              redirectTo: location,
            },
          });
        }
      });
  };

  return (
    <div
      className={`py-5 sm:py-8 w-full md:w-2/5 md:max-w-[350px] md:pr-16 ${
        hideMobile && "hidden sm:block"
      }`}
    >
      <div className="text-center mb-8">
        <div className="relative w-28 h-28 mx-auto mb-4">
          <div className="w-28 h-28 mx-auto rounded-full shadow-md overflow-hidden cursor-pointer">
            {isLoading ? (
              <div className="w-28 h-28 bg-grey-extra-light flex justify-center items-center">
                <CgSpinner className="text-2xl text-white animate-spin" />
              </div>
            ) : profilePhoto ? (
              <img
                className="w-28 h-28 object-cover object-center"
                src={profilePhoto}
                alt=""
              />
            ) : (
              <div className="w-28 h-28 bg-grey-extra-light flex justify-center items-center">
                <BiUser className="text-4xl text-grey-dark" />
              </div>
            )}
          </div>
          <div className="absolute bottom-0 right-0">
            <input
              className="hidden"
              ref={inputFile}
              id="file"
              name="file"
              type="file"
              accept="image/png, image/jpeg"
              onChange={handleFileChange}
            />
            <button
              className="flex rounded-full bg-white p-2 shadow-md z5"
              onClick={handleOpenFileSelection}
            >
              <FaCamera className="text-xl text-grey-dark" />
            </button>
          </div>
        </div>
        <p className="text-xl sm:text-2xl font-semibold capitalize -mb-1.5">
          {profileData.first_name}
        </p>
        <span className="text-3xs sm:text-xs text-grey-dark">
          @{profileData.username}
        </span>
      </div>
      <div className="shadow-profile rounded-md">
        <div className="text-center pt-6 px-4">
          <p className="text-xs sm:text-sm">Available Balance</p>
          <p className="text-xl sm:text-2xl font-semibold mb-2">
            RM {cart.convertPriceWithThousandSeparator(balance)}
          </p>
          <Link
            to={path.EWALLETTOPUP}
            state={{ scrollTo: "topup" }}
            className="py-1 px-4 bg-primary rounded-3xl text-3xs sm:text-xs font-bold text-center text-white shadow"
          >
            Top Up
          </Link>
          <hr className="bg-grey-light my-6" />
        </div>
        <div className="">
          <ul>
            <li>
              <Link
                to={path.PROFILE}
                state={{ scrollTo: "profile" }}
                className={`${styles.navItem} ${
                  location.pathname === path.PROFILE
                    ? styles.selectedNavItem
                    : ""
                }`}
              >
                <BiUser className={styles.navIcon} />
                {page.PROFILE}
              </Link>
            </li>
            <li>
              <Link
                to={path.EWALLET}
                state={{ scrollTo: "ewallet" }}
                className={`${styles.navItem} ${
                  location.pathname === path.EWALLET
                    ? styles.selectedNavItem
                    : ""
                }`}
              >
                <MdOutlineAccountBalanceWallet className={styles.navIcon} />
                {page.EWALLET}
              </Link>
            </li>
            <li>
              <Link
                to={path.ORDERHISTORY}
                state={{ scrollTo: "order-history" }}
                className={`${styles.navItem} ${
                  location.pathname === path.ORDERHISTORY
                    ? styles.selectedNavItem
                    : ""
                }`}
              >
                <MdHistory className={styles.navIcon} />
                {page.ORDERHISTORY}
              </Link>
            </li>
            <li>
              <Link
                to={path.TRANSACTIONHISTORY}
                state={{ scrollTo: "transaction-history" }}
                className={`${styles.navItem} ${
                  location.pathname === path.TRANSACTIONHISTORY
                    ? styles.selectedNavItem
                    : ""
                }`}
              >
                <MdOutlineListAlt className={styles.navIcon} />
                {/* MdOutlineListAlt */}
                {page.TRANSACTIONHISTORY}
              </Link>
            </li>
            <li className={styles.navItem} onClick={handleLogout}>
              <MdLogout className={styles.navIcon} />
              Logout
            </li>
          </ul>
        </div>
        <div className="pb-6 px-4">
          <hr className="bg-grey-light my-6" />
          <p className="text-sm sm:text-base font-bold mb-1">Referral link</p>
          <div className="w-full border border-grey rounded-lg p-3 outline-0 flex flex-row justify-between">
            <input
              id="referral_url"
              name="referral_url"
              type="text"
              value={profileData?.referral_url}
              readOnly
              className="overflow-hidden text-ellipsis w-full"
            />
            <CopyToClipboard
              text={profileData?.referral_url}
              onCopy={() => {
                alertModal
                  .mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                  })
                  .fire({
                    title: "Link copied successfully",
                    icon: "success",
                  });
              }}
            >
              <MdContentCopy className="text-2xl cursor-pointer" />
            </CopyToClipboard>
          </div>
        </div>
      </div>
    </div>
  );
}

const styles = {
  navItem:
    "flex flex-row items-center py-2 px-4 text-sm sm:text-base cursor-pointer",
  selectedNavItem: "font-bold text-primary",
  navIcon: "text-2xl mr-4",
};
export default ProfileNav;
