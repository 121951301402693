import React from "react";
import { CgChevronDown, CgChevronUp } from "react-icons/cg";

const Context = React.createContext({});

//custom hook to consume all accordion values
const useAccordion = () => React.useContext(Context);

function Accordion({ children, className }) {
  const [selected, setSelected] = React.useState(true);

  const toggleItem = () => {
    setSelected(!selected);
  };

  return (
    <Context.Provider value={{ selected, toggleItem }}>
      <div className={className}>{children}</div>
    </Context.Provider>
  );
}

function AccordionItem({ children, className, iconClassName }) {
  const { selected, toggleItem } = useAccordion();
  return (
    <div
      role="button"
      onClick={() => toggleItem()}
      className={`${className} ${selected ? "text-blue" : "text-body-text"}`}
    >
      {children}
      {selected ? (
        <CgChevronUp className={`text-2xl ${iconClassName}`} />
      ) : (
        <CgChevronDown className={`text-2xl ${iconClassName}`} />
      )}
    </div>
  );
}

function AccordionPanel({ children, id, className }) {
  const { selected } = useAccordion();
  const ref = React.useRef();
  const inlineStyle = selected
    ? { height: ref.current?.scrollHeight }
    : { height: 0 };

  return (
    <div
      ref={ref}
      id={id}
      className={`${style.panel} ${
        selected ? "" : style.panelOverflowHidden
      } ${className}`}
      style={inlineStyle}
    >
      {children}
    </div>
  );
}

const style = {
  // item: `flex flex-row justify-between items-center`,
  panel: `transition-height ease duration-300`,
  panelOverflowHidden: `overflow-hidden md:overflow-x-hidden`,
};

export { Accordion, AccordionItem, AccordionPanel };
