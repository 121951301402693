import React from "react";
import { Link } from "react-router-dom";
import { useRouter } from "../../hook/useRouter";
import { Formik, Form } from "formik";
import * as yup from "yup";
import Container from "../../components/layout/container";
import TextField from "../../components/elements/form/text-field";
import Button from "../../components/elements/button";
import { page } from "../../assets/copy/page";
import { path } from "../../assets/copy/path";
import { useAuth } from "../../hook/useAuth";

const initialValues = {
  username: "",
  password: "",
};

const validationSchema = yup.object().shape({
  username: yup.string().required("Username is required"),
  password: yup
    .string()
    .min(
      8,
      "Password must be at least 8 characters and contain at least one lowercase letter, one uppercase letter, one number, and one special character."
    )
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[0-9]/, "Password must contain at least one number")
    .matches(
      /[!@#$%^&*]/,
      "Password must contain at least one special character (!@#$%^&*)"
    ),
});

function Login() {
  const auth = useAuth();
  const { location } = useRouter();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onSubmit = (values, actions) => {
    const { username, password } = values;
    auth.login(
      {
        username: username,
        password: password,
      },
      actions,
      location.state
    );
  };

  return (
    <Container>
      <div className="py-10 sm:py-16 px-6 sm:px-12 overflow-hidden">
        <div className="w-full max-w-container600 m-auto p-10 relative flex flex-col items-center bg-white rounded-3xl shadow-login">
          <h2 className="text-xl sm:text-2xl font-bold text-black-primary text-center uppercase">
            Login
          </h2>
          <div className="w-full mt-8">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema}
              // validateOnChange={false}
              // validateOnBlur={false}
              onSubmit={onSubmit}
            >
              {({ isSubmitting, handleChange, dirty, isValid }) => (
                <Form>
                  <div className="mb-6">
                    <TextField
                      label="Username"
                      name="username"
                      type="text"
                      placeholder=""
                      autoComplete="off"
                    />
                  </div>
                  <div className="mb-3">
                    <TextField
                      label="Password"
                      name="password"
                      type="password"
                      placeholder=""
                      autoComplete="off"
                      isPasswordField={true}
                    />
                  </div>
                  <div className="flex flex-row justify-end">
                    <Link
                      className="text-sm sm:text-base font-bold text-primary text-right"
                      to={path.FORGOTPW}
                    >
                      {page.FORGOTPW}?
                    </Link>
                  </div>
                  <div className="mt-10">
                    <Button
                      type="submit"
                      name="Login"
                      color="primary"
                      buttonClassName="w-full"
                      disabled={!(dirty && isValid) || isSubmitting}
                    />
                  </div>
                </Form>
              )}
            </Formik>
            <div className="text-sm sm:text-base text-center mt-6">
              Don't have an account?&nbsp;
              <Link className="font-bold text-primary" to={path.REGISTER}>
                {page.REGISTER}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default Login;
