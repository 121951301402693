import React from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "./dropdown-menu/header-menu";
import { useAuth } from "../../hook/useAuth";
import { useRouter } from "../../hook/useRouter";
import { path } from "../../assets/copy/path";
import { page } from "../../assets/copy/page";
import { companyInfo } from "../../assets/copy/companyInfo";
import ProfileMenu from "./dropdown-menu/profile-menu";
import CartMenu from "./dropdown-menu/cart-menu/cart-menu";
import { TbMenu2 } from "react-icons/tb";
import { slide as Menu } from "react-burger-menu";

function Header() {
  const auth = useAuth();
  const { location } = useRouter();
  const currentPage = `${location.pathname}${location.hash}`;

  const styles = {
    menuItem: `mt-1 mx-3 pb-1 `,
    menuItemText: `text-base font-medium !leading-[120%]`,
    selectedMenuItemText: `!font-bold text-primary`,
    menuItemIcon: `mx-1 p-2 flex text-2xl text-primary rounded-full`,
    menuItemButton: `text-white bg-primary hover:bg-primary-dark mx-3 py-3 px-6 rounded-button shadow hover:shadow-md transition duration-300 ease-in-out`,

    menuDropDownItem: `py-3`,
    menuDropDownItemText: `text-base font-medium !leading-[120%]`,
    selectedMenuDropDownItemText: `!font-bold text-primary`,
    // Menu
    bmOverlay: {
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      opacity: 0,
    },
    bmBurgerButton: {
      width: 40,
      height: 40,
    },
    bmItemList: {
      paddingVertical: 0,
      paddingHorizontal: 24,
    },
  };

  return (
    <header
      className="fixed top-0 right-0 left-0 w-full h-[80px] bg-white shadow-menu z-40"
      id="header"
    >
      <div className="relative py-4 px-6 sm:px-12">
        <div className="w-full max-w-container1600 mx-auto flex flex-row justify-between items-center">
          <div className="w-[180px]">
            <Link to={path.HOME}>
              <img src={companyInfo.logo} className="" alt={companyInfo.name} />
            </Link>
          </div>

          {/* mobile */}
          <div className="flex lg:hidden flex-row justify-end flex-auto ml-3 -mr-3">
            {auth.user ? (
              <>
                <ProfileMenu styles={styles} />
                <CartMenu styles={styles} />
              </>
            ) : (
              <div className="flex">
                <Link to={path.LOGIN} className={`${styles.menuItemButton}`}>
                  Login
                </Link>
              </div>
            )}
            <div className="flex justify-center items-center lg:hidden  transition duration-150 ease-in-out">
              <Menu
                right
                // noOverlay
                noTransition
                width={"100%"}
                id={"sidebar"}
                className="absolute top-[80px] inset-x-0 bg-white w-full h-full px-[24px] shadow"
                customBurgerIcon={<TbMenu2 className="text-2xl" />}
                customCrossIcon={false}
                burgerButtonClassName="mx-1 p-2 flex text-2xl text-black-primary rounded-full flex-col justify-center items-center relative"
                styles={styles}
              >
                <div className={styles.menuDropDownItem}>
                  <Link
                    to={path.HOME}
                    className={`${styles.menuDropDownItemText} ${
                      currentPage === path.HOME
                        ? styles.selectedMenuDropDownItemText
                        : ""
                    }`}
                  >
                    {page.HOME}
                  </Link>
                </div>
                <div className={styles.menuDropDownItem}>
                  <Link
                    to={path.HOMECOOKED}
                    className={`${styles.menuDropDownItemText} ${
                      currentPage === path.HOMECOOKED
                        ? styles.selectedMenuDropDownItemText
                        : ""
                    }`}
                  >
                    {page.HOMECOOKED}
                  </Link>
                </div>
                <div className={styles.menuDropDownItem}>
                  <Link
                    to={path.SUBSCRIBEPLAN}
                    className={`${styles.menuDropDownItemText} ${
                      currentPage === path.SUBSCRIBEPLAN ||
                      currentPage === path.SUBSCRIBEMENU
                        ? styles.selectedMenuDropDownItemText
                        : ""
                    }`}
                  >
                    {page.SUBSCRIBEPLAN}
                  </Link>
                </div>
                <div className={styles.menuDropDownItem}>
                  <Link
                    to={path.CATERING}
                    className={`${styles.menuDropDownItemText} ${
                      currentPage === path.CATERING
                        ? styles.selectedMenuDropDownItemText
                        : ""
                    }`}
                  >
                    {page.CATERING}
                  </Link>
                </div>
                <div className={styles.menuDropDownItem}>
                  <Link
                    to={path.LITTLECOOK}
                    className={`${styles.menuDropDownItemText} ${
                      currentPage === path.LITTLECOOK
                        ? styles.selectedMenuDropDownItemText
                        : ""
                    }`}
                  >
                    {page.LITTLECOOK}
                  </Link>
                </div>
              </Menu>
            </div>
          </div>

          {/* desktop */}
          <nav className="hidden lg:flex flex-row justify-end flex-auto ml-3 -mr-3">
            <ul className="flex flex-row items-center">
              <li>
                <Link
                  to={path.HOME}
                  className={`${styles.menuItem} ${styles.menuItemText} ${
                    currentPage === path.HOME ? styles.selectedMenuItemText : ""
                  }`}
                >
                  {page.HOME}
                </Link>
              </li>
              <li>
                <Link
                  to={path.HOMECOOKED}
                  className={`${styles.menuItem} ${styles.menuItemText} ${
                    currentPage === path.HOMECOOKED
                      ? styles.selectedMenuItemText
                      : ""
                  }`}
                >
                  {page.HOMECOOKED}
                </Link>
              </li>
              <li>
                <Link
                  to={path.SUBSCRIBEPLAN}
                  className={`${styles.menuItem} ${styles.menuItemText} ${
                    currentPage === path.SUBSCRIBEPLAN ||
                    currentPage === path.SUBSCRIBEMENU
                      ? styles.selectedMenuItemText
                      : ""
                  }`}
                >
                  {page.SUBSCRIBEPLAN}
                </Link>
              </li>
              <li>
                <Link
                  to={path.CATERING}
                  className={`${styles.menuItem} ${styles.menuItemText} ${
                    currentPage === path.CATERING
                      ? styles.selectedMenuItemText
                      : ""
                  }`}
                >
                  {page.CATERING}
                </Link>
              </li>
              <li>
                <Link
                  to={path.LITTLECOOK}
                  className={`${styles.menuItem} ${styles.menuItemText} ${
                    currentPage === path.LITTLECOOK
                      ? styles.selectedMenuItemText
                      : ""
                  }`}
                >
                  {page.LITTLECOOK}
                </Link>
              </li>
              {auth.user ? (
                <>
                  <li>
                    {/* <Link
                      to={path.PROFILE}
                      className={`${styles.menuItemIcon}`}
                    >
                      <CgProfile className="text-2xl" />
                    </Link> */}
                    <ProfileMenu styles={styles} />
                  </li>
                  <li>
                    <CartMenu styles={styles} />
                  </li>
                </>
              ) : (
                <li>
                  <Link to={path.LOGIN} className={`${styles.menuItemButton}`}>
                    Login
                  </Link>
                </li>
              )}
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
}

export default Header;
