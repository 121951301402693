import React from "react";

function Button(props) {
  const { name, type, onClick, color, buttonClassName, icon, disabled } = props;
  return (
    <button
      className={`button text-sm sm:text-base font-bold !leading-[120%] capitalize shadow hover:shadow-md disabled:!opacity-60 disabled:pointer-events-none transition duration-300 ease-in-out ${
        color === "primary"
          ? "text-white bg-primary hover:bg-primary-dark py-3 px-6 min-w-[120px] rounded-button"
          : ""
      } ${
        color === "secondary"
          ? "text-primary bg-white border-2 border-solid border-primary py-[10px] px-[22px] min-w-[120px] rounded-button"
          : ""
      } ${icon && "flex flex-row items-center"} ${buttonClassName} `}
      type={type}
      onClick={onClick ? onClick : null}
      disabled={disabled}
    >
      {icon}
      {name ? <span>{name}</span> : null}
    </button>
  );
}

export default Button;
