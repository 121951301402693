import React, { useRef, useState } from "react";
import { useField } from "formik";
import { MdErrorOutline, MdCheckCircleOutline } from "react-icons/md";
import { FiEye, FiEyeOff } from "react-icons/fi";
// useField() returns [formik.getFieldProps(), formik.getFieldMeta()] which we can spread on <input>. We can use field meta to show an error message if the field is invalid and it has been touched (i.e. visited)

function TextField(props) {
  const inputRef = useRef(null);
  const hideShowButtonRef = useRef(null);
  const [field, meta, helpers] = useField(props);
  const {
    ref,
    label,
    name,
    successText,
    remarkText,
    required,
    isNumberField,
    isPasswordField,
  } = props;
  const [showPassword, setShowPassword] = useState(false);
  const showError = meta.touched && meta.error;

  const handleHideShowPw = () => {
    if (inputRef.current.type === "password") inputRef.current.type = "text";
    else inputRef.current.type = "password";
    setShowPassword(!showPassword);
  };

  return (
    <>
      {label && (
        <label className="text-sm sm:text-base font-medium mb-1" htmlFor={name}>
          {label}
          {required && <span className="text-red">*</span>}
        </label>
      )}
      <div className="relative" ref={ref}>
        <input
          ref={inputRef}
          autoComplete="off"
          className={`w-full border border-grey rounded-lg p-3 outline-0 text-ellipsis ${
            isPasswordField ? "pr-12" : ""
          } ${showError ? "!border-red" : ""}`}
          {...field}
          {...props}
          onChange={(e) => {
            // Only allow numbers
            if (isNumberField) {
              let number = e.target.value.replace(/[^0-9+]/g, "");
              helpers.setValue(number);
            } else {
              field.onChange(e); // Default formik onChange function
            }
          }}
        />
        {isPasswordField ? (
          <div className="absolute inset-y-0 right-1 flex justify-center items-center z-20">
            <button
              className="p-2 rounded-full ease-in-out"
              ref={hideShowButtonRef}
              type="button"
              onClick={handleHideShowPw}
              tabIndex="-1"
            >
              {showPassword ? (
                <FiEyeOff className="text-2xl" /> // Click to show password
              ) : (
                <FiEye className="text-2xl" /> // Click to hide password
              )}
            </button>
          </div>
        ) : null}
      </div>
      {showError && (
        <div className="flex items-center mt-1 text-xs sm:text-sm text-red">
          <MdErrorOutline className="mr-1" /> <span>{meta.error}</span>
        </div>
      )}
      {successText && (
        <div className="flex items-center mt-1 text-xs sm:text-sm text-green">
          <MdCheckCircleOutline className="mr-1" /> <span>{successText}</span>
        </div>
      )}
      {remarkText && (
        <div className="flex items-center text-xs sm:text-sm mt-1">
          {remarkText}
        </div>
      )}
    </>
  );
}

export default TextField;
