import "./App.css";
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import { path } from "./assets/copy/path";

// Pages - Public
import Home from "./pages/home";
import Login from "./pages/auth/login";
import Register from "./pages/auth/register";
import ForgotPassword from "./pages/auth/forgot-password";
import ResetPassword from "./pages/auth/reset-password";
import VerifyEmail from "./pages/auth/verify-email";
import Page404 from "./pages/404";
import MenuList from "./pages/menu-list";
import Subscription from "./pages/subscription";
import Catering from "./pages/catering";
// import DailyMeal from "./pages/daily-meal";
import LittleCook from "./pages/little-cook";
import Terms from "./pages/terms";
import Privacy from "./pages/privacy";
import DeliveryPolicy from "./pages/delivery-policy";
import RefundPolicy from "./pages/refund-policy";
import AboutUs from "./pages/about-us";

// Pages - Private
import Profile from "./pages/profile";
import SubscriptionMenu from "./pages/subscription/menu";
import Cart from "./pages/cart";
import Ewallet from "./pages/e-wallet";
import TopUp from "./pages/e-wallet/top-up";
import OrderHistory from "./pages/order/order-history";
import OrderDetails from "./pages/order/order-details";
import EditOrder from "./pages/order/edit-order";
import TransactionHistory from "./pages/transaction-history";
import PaymentStatus from "./pages/payment/status";

// Hook
import { useRouter } from "./hook/useRouter";
import { ProvideAuth, useAuth } from "./hook/useAuth";
import { ProvideCart } from "./hook/useCart";

const PrivateRoute = () => {
  const { location } = useRouter();
  const auth = useAuth();

  if (!auth.user)
    return (
      <Navigate replace to={path.LOGIN} state={{ redirectTo: location }} />
    );

  return <Outlet />;
};

const PrivateRoutes = () => {
  return (
    <Route path="/" element={<PrivateRoute />}>
      <Route path={path.PROFILE} element={<Profile />} />
      <Route path={path.SUBSCRIBEMENU} element={<SubscriptionMenu />} />
      <Route path={path.CART} element={<Cart />} />
      <Route path={path.PAYMENTSTATUS} element={<PaymentStatus />} />

      <Route path={path.EWALLETTOPUP} element={<TopUp />} />
      <Route path={path.EWALLET} element={<Ewallet />} />
      <Route path={path.ORDERHISTORY} element={<OrderHistory />} />
      <Route path={`${path.ORDERDETAILS}/:id`} element={<OrderDetails />} />
      <Route path={`${path.EDITORDER}/:id`} element={<EditOrder />} />
      <Route path={path.TRANSACTIONHISTORY} element={<TransactionHistory />} />
    </Route>
  );
};

function App() {
  return (
    <Router>
      <ProvideAuth>
        <ProvideCart>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path={path.MENU} element={<MenuList />} />
            {/* <Route path={path.DAILYMEAL} element={<DailyMeal />} /> */}
            <Route path={path.LITTLECOOK} element={<LittleCook />} />
            <Route path={path.SUBSCRIBEPLAN} element={<Subscription />} />
            <Route path={path.CATERING} element={<Catering />} />

            <Route path={path.LOGIN} element={<Login />} />
            <Route path={path.REGISTER} element={<Register />} />
            <Route path={path.FORGOTPW} element={<ForgotPassword />} />
            <Route path={path.RESETPW} element={<ResetPassword />} />
            <Route path={path.VERIFYEMAIL} element={<VerifyEmail />} />
            <Route path={path.ABOUTUS} element={<AboutUs />} />
            <Route path={path.TERMS} element={<Terms />} />
            <Route path={path.PRIVACY} element={<Privacy />} />
            <Route path={path.DELIVERYPOLICY} element={<DeliveryPolicy />} />
            <Route path={path.REFUNDPOLICY} element={<RefundPolicy />} />
            <Route path="*" element={<Page404 />} />
            {PrivateRoutes()}
          </Routes>
        </ProvideCart>
      </ProvideAuth>
    </Router>
  );
}

export default App;
