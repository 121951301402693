import React, { useRef } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import MenuCard from "../card/menu-card";
import { FaChevronRight } from "react-icons/fa";
import { TbInfoCircle } from "react-icons/tb";
import { path, sectionId } from "../../../assets/copy/path";

export default function MenuSlider({ title, category, list }) {
  const sliderRef = useRef(null);

  const settings = {
    // className: "center",
    // centerMode: true,
    // centerPadding: "100px",
    dots: false,
    arrow: true,
    infinite: list?.length > 5,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
    nextArrow: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.29006 6.71002C8.90006 7.10002 8.90006 7.73002 9.29006 8.12002L13.1701 12L9.29006 15.88C8.90006 16.27 8.90006 16.9 9.29006 17.29C9.68006 17.68 10.3101 17.68 10.7001 17.29L15.2901 12.7C15.6801 12.31 15.6801 11.68 15.2901 11.29L10.7001 6.70002C10.3201 6.32002 9.68006 6.32002 9.29006 6.71002Z"
          fill="#1C1C1C"
        />
      </svg>
    ),
    prevArrow: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.71 6.70998C14.32 6.31998 13.69 6.31998 13.3 6.70998L8.70998 11.3C8.31998 11.69 8.31998 12.32 8.70998 12.71L13.3 17.3C13.69 17.69 14.32 17.69 14.71 17.3C15.1 16.91 15.1 16.28 14.71 15.89L10.83 12L14.71 8.11998C15.1 7.72998 15.09 7.08998 14.71 6.70998Z"
          fill="#1C1C1C"
        />
      </svg>
    ),
  };

  return (
    <>
      {list?.length > 0 && (
        <div className="pb-10 sm:pb-16" id={title}>
          <div className="flex justify-between items-center pb-4">
            <div className="flex flex-col sm:flex-row sm:items-center flex-wrap">
              {category !== sectionId.littlecook &&
                category !== sectionId.dailymeal && (
                  <>
                    <h2 className="text-xl sm:text-2xl font-bold mr-4">
                      {title}
                    </h2>
                    <Link
                      to={path.SUBSCRIBEPLAN}
                      className="text-3xs sm:text-xs text-white mt-1 py-1 px-2.5 flex flex-row items-center bg-primary rounded-3xl cursor-pointer"
                    >
                      <TbInfoCircle className="text-sm" />
                      <p className="ml-2">
                        <span
                          to={path.SUBSCRIBEPLAN}
                          className="font-bold underline "
                        >
                          Subscribe
                        </span>
                        &nbsp; plan to save more
                      </p>
                    </Link>
                  </>
                )}
            </div>
            <div>
              <Link
                className="see-all-link text-sm sm:text-base font-bold text-primary hover:text-primary-dark flex desktop whitespace-nowrap"
                to={{
                  pathname:
                    category === sectionId.littlecook
                      ? path.LITTLECOOK
                      : category === sectionId.dailymeal
                      ? path.DAILYMEAL
                      : path.MENU,
                  // search: category && `?cuisine=${category}`,
                }}
                state={{ cuisine: category }}
                style={{ alignItems: "center", padding: 0, marginLeft: 24 }}
              >
                See all&nbsp;
                <FaChevronRight className="icon" />
              </Link>
            </div>
          </div>

          <div>
            <div className="m-[-12px]">
              <Slider ref={sliderRef} {...settings}>
                {list.map((item, i) => {
                  return (
                    <div className="w-[calc(100%-24px)] m-[12px] flex flex-col">
                      <MenuCard
                        key={i}
                        category={category}
                        data={item}
                        index={i}
                      />
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
