import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as yup from "yup";
import Container from "../../components/layout/container";
import TextField from "../../components/elements/form/text-field";
import Button from "../../components/elements/button";
import { page } from "../../assets/copy/page";
import { path } from "../../assets/copy/path";
import { useAuth } from "../../hook/useAuth";
import { useRouter } from "../../hook/useRouter";

const initialValues = {
  password: "",
  confirmPassword: "",
};

const validationSchema = yup.object().shape({
  password: yup
    .string()
    .min(
      8,
      "Password must be at least 8 characters and contain at least one lowercase letter, one uppercase letter, one number, and one special character."
    )
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[0-9]/, "Password must contain at least one number")
    .matches(
      /[!@#$%^&*]/,
      "Password must contain at least one special character (!@#$%^&*)"
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Password not match")
    .required("Confirm password is required"),
});

function ResetPassword() {
  const auth = useAuth();
  const { query } = useRouter();
  const [isLoading, setIsLoading] = React.useState(true);
  const [email, setEmail] = React.useState(null);
  const [encryptedEmail, setEncryptedEmail] = React.useState(null);
  const [token, setToken] = React.useState(null);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    async function fetchData() {
      if (query?.email) {
        await auth.getEmail(
          query.email,
          setEmail,
          "Invalid link. Please try again.",
          path.FORGOTPW
        );
        setEncryptedEmail(query.email);
        setToken(query.token);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);

  const onSubmit = async (values, actions) => {
    const submit = async () => {
      const { password, confirmPassword } = values;
      auth.resetPassword(
        {
          email: encryptedEmail,
          password: password,
          password_confirmation: confirmPassword,
          token: token,
        },
        actions
      );
    };
    submit();
  };

  return (
    <Container loading={isLoading}>
      <div className="py-10 sm:py-16 px-6 sm:px-12 overflow-hidden">
        <div className="w-full max-w-container600 m-auto p-10 relative flex flex-col items-center bg-white rounded-3xl shadow-login">
          <h2 className="text-xl sm:text-2xl font-bold text-black-primary text-center uppercase">
            {page.RESETPW}
          </h2>
          <p className="mt-2 text-center">
            Enter your new password for {email}
          </p>
          <div className="w-full mt-8">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ isSubmitting, dirty, isValid }) => (
                <Form>
                  <div className="form-input mb-6">
                    <TextField
                      label="Password"
                      name="password"
                      type="password"
                      placeholder=""
                      autoComplete="off"
                      isPasswordField={true}
                    />
                  </div>
                  <div className="form-input mb-6">
                    <TextField
                      label="Confirm password"
                      name="confirmPassword"
                      type="password"
                      placeholder=""
                      autoComplete="off"
                      isPasswordField={true}
                    />
                  </div>
                  <div className="form-input mt-8">
                    <Button
                      type="submit"
                      name="Reset password"
                      color="primary"
                      buttonClassName="w-full"
                      disabled={!(dirty && isValid) || isSubmitting}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default ResetPassword;
