import axiosClient from "../lib/axios";
import axiosFormDataClient from "../lib/axiosFormData";
import axiosUrlEncodingClient from "../lib/axiosUrlEncoding";

export const Post = (path, body) => {
  return axiosClient.post(path, body);
};

export const Get = (path) => {
  return axiosClient.get(path);
};

export const Put = (path, body) => {
  return axiosClient.put(path, body);
};

export const Delete = (path) => {
  return axiosClient.delete(path);
};

export const PostFormData = (path, formData) => {
  return axiosFormDataClient.post(path, formData);
};

export const PostUrlEncoding = (path, params) => {
  return axiosUrlEncodingClient.post(path, params);
};

export const PutUrlEncoding = (path, params) => {
  return axiosUrlEncodingClient.put(path, params);
};
