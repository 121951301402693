import React, { useEffect, useContext, createContext } from "react";
import useLocalStorage from "./useLocalStorage";
import { Get, Post } from "../services";
import { path } from "../assets/copy/path";
import { alertModal } from "../components/elements/modal/alert";
import { useRouter } from "./useRouter";

const authContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const { navigate, state } = useRouter();
  const [user, setUser] = useLocalStorage("user", "");

  const login = (body, actions, state) => {
    actions.setSubmitting(true);
    Post(`/auth/login`, body)
      .then((response) => {
        setUser(response.data);
        actions.resetForm();
        if (state?.redirectTo)
          navigate(
            `${state.redirectTo?.pathname ? state.redirectTo.pathname : ""}${
              state.redirectTo?.hash ? state.redirectTo.hash : ""
            }`,
            {
              state: {
                ...state,
              },
            }
          );
        else navigate(path.HOME);
        actions.resetForm();
        actions.setSubmitting(false);
      })
      .catch((error) => {
        console.error("Error: ", error);
        if (error.code_status === 401) {
          alertModal.fire({
            title: "Your account is not verified.",
            text: "Please verify your account to continue or enter your email to resend verification email.",
            icon: "error",
            input: "email",
            inputAttributes: {
              autocapitalize: "off",
            },
            inputValidator: (value) => {
              if (!value) {
                return "Email is required";
              }
            },
            showCancelButton: true,
            // showDenyButton: true,
            // confirmButtonText: "Ok",
            // denyButtonText: `Resend Verification Email`,
            // reverseButtons: true,
            preConfirm: async (email) => {
              await resendVerify({ email: email });
            },
            allowOutsideClick: () => !alertModal.isLoading(),
            showLoaderOnConfirm: true,
          });

          // if (email) resendVerify(email);
          // .then((result) => {
          //   // if (result.isConfirmed) {
          //   //   navigate(path.LOGIN);
          //   // } else if (result.isDenied) {
          //   //   // resendVerify({ email: body.email });
          //   // }
          // });
        } else {
          alertModal.fire("Error", error.message, "error", "Ok");
        }
        actions.setSubmitting(false);
      });
  };

  const register = (body, actions, setIsLoading) => {
    Post(`/auth/register`, body)
      .then((response) => {
        alertModal
          .fire({
            title: "Register success",
            html: "Please check your email for the verification link.",
            icon: "success",
            showDenyButton: true,
            confirmButtonText: "Login Now",
            denyButtonText: `Resend Verification Email`,
            reverseButtons: true,
          })
          .then((result) => {
            if (result.isConfirmed) {
              // Go to login page
              navigate(path.LOGIN);
            } else if (result.isDenied) {
              // Resend email
              resendVerify({ email: body.email });
            }
          });
        actions.resetForm();
        actions.setSubmitting(false);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error: ", error);
        if (error.code_status === 422) {
          actions.setErrors({
            username: error.data.username,
            email: error.data.email,
            password: error.data.password,
            phone: error.data.phone_no,
            firstName: error.data.first_name,
            lastName: error.data.last_name,
            dob: error.data.dob,
            gender: error.data.gender,
          });
        } else {
          alertModal.fire(
            "Error", // Title
            error.message, // Text
            "error", // Icon
            "Ok" // confirmButtonText
          );
        }
        actions.setSubmitting(false);
        setIsLoading(false);
      });
  };

  const verify = (id, expires, signature) => {
    Get(`/auth/email/verify/${id}?expires=${expires}&signature=${signature}`)
      .then((response) => {
        if (response.code_status === 200) {
          alertModal
            .fire({
              title: "Verification Success", // Title
              html: `Your email has been verified.<br />You can now login with your new account.`,
              icon: "success",
              confirmButtonText: "Login",
            })
            .then((result) => {
              navigate(path.LOGIN);
            });
        }
      })
      .catch((error) => {
        console.error("Error: ", error);

        if (error.code_status === 400) {
          alertModal
            .fire(
              "You have verified", // Title
              "Please login to continue", // response.message, // Text
              "success", // Icon
              "Ok" // confirmButtonText
            )
            .then((result) => {
              navigate(path.LOGIN);
            });
        } else {
          alertModal.fire(
            "Error", // Title
            error.message, // Text
            "error", // Icon
            "Ok" // confirmButtonText
          );
        }
      });
  };

  const resendVerify = (body) => {
    Post(`/auth/email/verify/resend`, body)
      .then((response) => {
        if (response.code_status === 200) {
          alertModal
            .fire({
              title: "Successfully resend verification email",
              text: "Please check your email for the verification link.",
              icon: "success",
              confirmButtonText: "Ok",
            })
            .then((result) => {
              if (result.isConfirmed) {
                // Go to login page
                navigate(path.LOGIN);
              }
            });
        }
      })
      .catch((error) => {
        console.error("Error: ", error);
        if (error.code_status === 422) {
          alertModal.fire(
            "Error", // Title
            error.data.email[0], // Text
            "error", // Icon
            "Ok" // confirmButtonText
          );
        } else {
          alertModal.fire(
            "Error", // Title
            error.message, // Text
            "error", // Icon
            "Ok" // confirmButtonText
          );
        }
      });
  };

  const forgotPassword = (body, actions) => {
    actions.setSubmitting(true);
    Post(`/auth/forgot-password`, body)
      .then((response) => {
        alertModal
          .fire(
            "Success",
            "Password reset email sent successfully",
            "success",
            "Ok"
          )
          .then((result) => {
            if (result.isConfirmed) {
              // Go to login page
              navigate(path.LOGIN);
            }
          });
        actions.setSubmitting(false);
        actions.resetForm();
      })
      .catch((error) => {
        console.error("Error: ", error);
        if (error.code_status === 422) {
          actions.setErrors({
            email: error.data.email,
          });
        } else {
          alertModal.fire("Error", error.message, "error", "Ok");
        }
        actions.setSubmitting(false);
      });
  };

  const resetPassword = (body, actions) => {
    actions.setSubmitting(true);
    Post(`/auth/reset-password`, body)
      .then((response) => {
        alertModal
          .fire("Success", "Password reset successfully", "success", "Ok")
          .then((result) => {
            if (result.isConfirmed) {
              // Go to login page
              navigate(path.LOGIN);
            }
          });
        actions.setSubmitting(false);
        actions.resetForm();
      })
      .catch((error) => {
        console.error("Error: ", error);
        if (error.code_status === 422) {
          actions.setErrors({
            password: error.data.password,
            confirmPassword: error.data.password_confirmation,
          });
        } else {
          alertModal.fire("Error", error.message, "error", "Ok");
        }
        actions.setSubmitting(false);
      });
  };

  const getEmail = (token, setEmail, message, to) => {
    Get(`/auth/reset-password/email/${token}`)
      .then((response) => {
        setEmail(response.data.email);
      })
      .catch((error) => {
        console.error("Error: ", error);
        alertModal
          .fire(
            "Error", // Title
            message, // Text
            "error", // Icon
            "Ok" // confirmButtonText
          )
          .then((result) => {
            navigate(to);
          });
      });
  };
  const logout = () => {
    setUser("");
  };

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    if (user) setUser(user);
    else setUser("");
  }, []); // can't add dependency here will trigger re-render

  // Return the user object and auth methods
  return {
    user,
    login,
    register,
    verify,
    resendVerify,
    forgotPassword,
    resetPassword,
    getEmail,
    logout,
  };
}
