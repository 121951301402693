import React, { useEffect, useRef, useState } from "react";
import { useRouter } from "../hook/useRouter";
import { page } from "../assets/copy/page";
import { path, sectionId } from "../assets/copy/path";
import Maps from "../components/elements/maps";
import HeroSlider from "../components/elements/slider/hero-slider";
import MenuSlider from "../components/elements/slider/menu-slider";
import Container from "../components/layout/container";
import { Get } from "../services";
import { MdMyLocation } from "react-icons/md";

export default function Home() {
  const { location, navigate } = useRouter();
  const homecookedSectionRef = useRef(null);
  const littlecookSectionRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [bannerList, setBannerList] = useState([]);
  const [menuList, setMenuList] = useState({});
  const [dailyMealList, setDailyMealList] = React.useState([]);
  const [littlecookList, setLittlecookList] = useState([]);
  const [daytime, setDaytime] = useState(1);

  useEffect(() => {
    Get(`/dashboard?daytime=${daytime}`)
      .then((response) => {
        if (response.code_status === 200) {
          setBannerList(response.data.banner);
          setMenuList(response.data.menu);
          setLittlecookList(response.data.little_cook);
          setDailyMealList(response.data.daily_meal);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  }, [daytime]);

  useEffect(() => {
    if (menuList) {
      if (location.hash) {
        const id = location.hash.replace(`#`, "");
        const section = document.getElementById(id);
        section && scrollToSection(section);
      } else {
        window.scrollTo(0, 0);
      }
    }
  }, [menuList, location, location.hash]);

  const scrollToSection = (section) => {
    const headerOffset = document.getElementById("header").offsetHeight;
    const sectionPosition = section.getBoundingClientRect().top;
    const offsetPosition = sectionPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  const handleClickTab = (val) => {
    setDaytime(val);
    navigate(path.HOMECOOKED);
  };

  return (
    <Container
      loading={isLoading}
      seo={{ title: "FoodHeart" }}
      showWhatsappButton={true}
    >
      <div className="p-0 overflow-hidden" id="hero">
        <div className="w-full mx-auto relative aspect-video bg-grey">
          {bannerList && <HeroSlider list={bannerList} />}
        </div>
      </div>

      <div className="py-10 sm:py-16 px-6 sm:px-12 overflow-hidden">
        <div className="w-full max-w-container1200 mx-auto relative flex flex-col items-center">
          <h1 className="font-pacifico text-3xl sm:text-4xl text-center pb-4">
            Coverage Checker
          </h1>
          <p
            className="w-full text-sm sm:text-base text-center pb-6 flex flex-row justify-center items-center"
            style={{ flexFlow: "wrap" }}
          >
            Click this <MdMyLocation className="text-xl mx-2" /> to check if
            your area is covered.
            {/* Yay! your area is covered [gif symbol]  */}
            {/* Oh oh/oops, your area is not covered, we will be there soon */}
          </p>
          <Maps />
        </div>
      </div>

      {/* Selling point */}

      <div id="product">
        {/* Daily Meal */}
        {/* <div id={sectionId.dailymeal}>
          <div className="pt-10 sm:pt-16 px-6 sm:px-12 overflow-hidden">
            <div className="w-full max-w-container1200 mx-auto relative">
              <h1 className="font-pacifico text-3xl sm:text-4xl text-center pb-6">
                {page.DAILYMEAL}
              </h1>
              <MenuSlider
                title={page.DAILYMEAL}
                category={sectionId.dailymeal}
                list={dailyMealList}
              />
            </div>
          </div>
        </div> */}

        {/* Homecooked */}
        <div id={sectionId.homecooked} ref={homecookedSectionRef}>
          <div className="pt-10 sm:pt-16 px-6 sm:px-12 overflow-hidden">
            <div className="w-full max-w-container1200 mx-auto relative">
              <h1 className="font-pacifico text-3xl sm:text-4xl text-center pb-6">
                {page.HOMECOOKED}
              </h1>
              <div className="tab flex flex-row justify-center items-center mb-6">
                <div
                  className={`py-3 px-4 sm:px-8 mx-3 min-w-[120px] rounded-button text-center cursor-pointer transition duration-300 ease-in-out ${
                    daytime === 1 ? "bg-primary-extra-light-50" : ""
                  }`}
                  onClick={() => handleClickTab(1)}
                >
                  <h2 className="text-xl sm:text-2xl font-bold">Lunch</h2>
                  <p className="text-xs sm:text-sm !leading-[120%]">
                    Delivery time:
                    <br />
                    10am - 2pm
                  </p>
                </div>
                <div
                  className={`py-3 px-8 mx-3 min-w-[120px] rounded-button text-center cursor-pointer transition duration-300 ease-in-out ${
                    daytime === 2 ? "bg-primary-extra-light-50" : ""
                  }`}
                  onClick={() => handleClickTab(2)}
                >
                  <h2 className="text-xl sm:text-2xl font-bold">Dinner</h2>
                  <p className="text-xs sm:text-sm !leading-[120%]">
                    Delivery time:
                    <br />
                    3pm - 7pm
                  </p>
                </div>
              </div>
              {Object.keys(menuList).length > 0 &&
                Object.keys(menuList).map((key, i) => (
                  <MenuSlider
                    key={i}
                    title={`${key} Cuisine`}
                    category={key}
                    list={menuList[key]}
                  />
                ))}
            </div>
          </div>
        </div>

        {/* Little Cook */}
        {littlecookList.length > 0 && (
          <div id={sectionId.littlecook} ref={littlecookSectionRef}>
            <div className="pt-10 sm:pt-16 px-6 sm:px-12 overflow-hidden">
              <div className="w-full max-w-container1200 mx-auto relative">
                <h1 className="font-pacifico text-3xl sm:text-4xl text-center pb-6">
                  {page.LITTLECOOK}
                </h1>
                <MenuSlider
                  title={page.LITTLECOOK}
                  category={sectionId.littlecook}
                  list={littlecookList}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </Container>
  );
}
