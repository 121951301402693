import React, { useEffect, useState } from "react";
import { ModalBody, ModalWrapper } from ".";
import Button from "../button";
import { MdAdd, MdRemove } from "react-icons/md";
import { useCart } from "../../../hook/useCart";
import Select from "react-select";
import { Get } from "../../../services";

export default function CartAddOnModal({ open, setOpen, data, addressId }) {
  const cart = useCart();
  const [isOpen, setIsOpen] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [addOnList, setAddOnList] = React.useState([]);
  const [drinksList, setDrinksList] = React.useState([]);

  const [selectedAddOn, setSelectedAddOn] = useState([]);
  const [selectedAddOnIds, setSelectedAddOnIds] = useState([]);

  const [selectedDrinks, setSelectedDrinks] = useState([]);
  const [selectedDrinksIds, setSelectedDrinksIds] = useState([]);

  React.useEffect(() => {
    isOpen && cart.getAddOn(setAddOnList, setDrinksList);
  }, [isOpen, data]);

  React.useEffect(() => {
    let tempAddOnArr = [];
    let tempDrinksArr = [];

    let tempAddOnIdsArr = [];
    let tempDrinksIdsArr = [];

    if (data?.add_on?.length > 0) {
      data.add_on.map((itemSelectedAddOn) => {
        addOnList?.map((itemAddOn) => {
          if (itemSelectedAddOn.id === itemAddOn.value) {
            tempAddOnArr.push(itemAddOn);
            tempAddOnIdsArr.push(itemAddOn.value);
          }
        });
      });
    }
    if (data?.drinks?.length > 0) {
      data.drinks.map((itemSelectedDrinks) => {
        drinksList?.map((itemDrinks) => {
          if (itemSelectedDrinks.id === itemDrinks.value) {
            tempDrinksArr.push(itemDrinks);
            tempDrinksIdsArr.push(itemDrinks.value);
          }
        });
      });
    }

    setSelectedAddOn(tempAddOnArr);
    setSelectedAddOnIds(tempAddOnIdsArr);
    setSelectedDrinks(tempDrinksArr);
    setSelectedDrinksIds(tempDrinksIdsArr);
  }, [data, addOnList, drinksList]);

  React.useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const toggle = () => {
    setOpen(!isOpen);
    setIsOpen(!isOpen);
  };

  const handleClickSubmit = async () => {
    setIsSubmitting(true);
    await cart.updateCart(
      data,
      data.quantity,
      addressId,
      selectedAddOnIds,
      selectedDrinksIds
    );
    setIsSubmitting(false);
    toggle();
  };

  return (
    <ModalWrapper
      open={isOpen}
      toggle={toggle}
      closeOnClickOutside={true}
      className="min-w-[50%]"
    >
      <ModalBody className="p-6 !text-left">
        <h2 className="text-xl sm:text-2xl font-bold text-left mb-4">
          Add On & Beverage
        </h2>
        <div className="flex flex-col w-full mb-4">
          <p className="mb-1">Add On:</p>
          <MultiSelect
            options={addOnList}
            value={selectedAddOn}
            setValue={setSelectedAddOn}
            setIds={setSelectedAddOnIds}
          />
        </div>
        <div className="flex flex-col w-full">
          <p className="mb-1">Beverage:</p>
          <MultiSelect
            options={drinksList}
            value={selectedDrinks}
            setValue={setSelectedDrinks}
            setIds={setSelectedDrinksIds}
          />
        </div>

        <div className="flex flex-col sm:flex-row justify-center items-center pt-6">
          <Button
            type="button"
            name="Submit"
            color="primary"
            onClick={() => handleClickSubmit()}
            disabled={isSubmitting}
          />
        </div>
      </ModalBody>
    </ModalWrapper>
  );
}

const MultiSelect = ({ options, value, setValue, setIds, disabled }) => {
  const customStyles = {
    dropdownIndicator: (styles) => ({
      ...styles,
      color: "#656C72!important",
    }),
    control: (styles, state) => ({
      ...styles,
      backgroundColor: "#fff",
      borderRadius: 8,
      boxShadow: "none",
      ":hover": {
        ...styles[":hover"],
        border: "1px solid #CBD0D6",
      },
    }),
    valueContainer: (styles) => ({
      ...styles,
      padding: "0.5rem 0.75rem",
      maxHeight: 48,
      overflow: "scroll",
    }),
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: "#fbe5d8",
      };
    },
    multiValueLabel: (styles) => ({
      ...styles,
      // color: data.color,
    }),
    option: (styles, { isDisabled, isSelected }) => ({
      ...styles,
      backgroundColor: isSelected ? "#fbe5d8" : "#fff",
      color: "#1C1C1C",
      cursor: isDisabled ? "not-allowed" : "pointer",
      ":hover": {
        ...styles[":hover"],
        backgroundColor: "#fdf2eb",
      },
    }),
    menu: (styles) => ({
      ...styles,
      margin: "4px 0",
    }),
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Select
        className="multiselect"
        isMulti
        options={options}
        hideSelectedOptions={false}
        onClick={(e) => e.stopPropagation()}
        value={value}
        onChange={(option) => {
          setValue(option);
          setIds(option?.map((item) => item.value));
        }}
        formatOptionLabel={(option) => (
          <div className="flex flex-row items-center">
            <img
              className="w-[40px] h-[40px] aspect-square object-cover object-center rounded-md overflow-hidden bg-grey"
              src={option.image}
              alt={option.label}
            />
            <div className="flex flex-col ml-2">
              <p className="text-sm sm:text-base">{option.label}</p>
              <span className="text-xs sm:text-sm">RM {option.price}</span>
            </div>
          </div>
        )}
        styles={customStyles}
        isDisabled={disabled}
      />
    </div>
  );
};
