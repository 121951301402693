import React from "react";
import { useField } from "formik";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MdErrorOutline, MdOutlineCalendarToday } from "react-icons/md";

function DateField(props) {
  const [field, meta, helpers] = useField(props);
  const { label, name, required } = props;
  const showError = meta.touched && meta.error;

  return (
    <>
      <label className="text-sm sm:text-base font-medium mb-1" htmlFor={name}>
        {label}
        {required && <span className="text-red">*</span>}
      </label>

      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          className={showError ? "has-error" : ""}
          name={field.name}
          value={field?.value ? new Date(field.value) : ""}
          onChange={(date) => {
            helpers.setValue(date);
          }}
          disableFuture={true}
          format="dd-MM-yyyy"
          slotProps={{
            textField: {
              InputProps: {
                endAdornment: <MdOutlineCalendarToday className="text-2xl" />,
              },
            },
          }}
        />
      </LocalizationProvider>
      {showError && (
        <div className="flex items-center mt-1 text-xs sm:text-sm text-red">
          <MdErrorOutline className="mr-1 min-w-[14px] min-h-[14px]" />{" "}
          <span>{meta.error}</span>
        </div>
      )}
    </>
  );
}

export default DateField;
