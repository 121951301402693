import React, { useEffect, useState } from "react";
import Container from "../../components/layout/container";
import Pagination from "@mui/material/Pagination";
import { Post } from "../../services";
import MenuCard from "../../components/elements/card/menu-card";
import { FaSpinner } from "react-icons/fa";
import HeroSlider from "../../components/elements/slider/hero-slider";
import { page } from "../../assets/copy/page";
import Images from "../../assets/images/images";

export default function LittleCook() {
  const [isLoading, setIsLoading] = useState(true);
  const [isReady, setIsReady] = useState(false);
  const [data, setData] = useState({});
  const [list, setList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [sort, setSort] = useState("desc");
  const [bannerList, setBannerList] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const body = getBody();
    Post(`/little_cook/listing/search`, body)
      .then((response) => {
        setBannerList(response.banner);
        setData(response);
        // setCurrentPage(response.current_page);
        setList(response.data);
        setIsReady(true);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  }, [currentPage, sort]);

  const getBody = () => {
    return {
      search: {
        eq: {},
      },
      limit: 15,
      page: currentPage,
      order_by: {
        created_at: sort,
      },
    };
  };

  const handleChange = (e, value) => {
    setIsReady(false);
    setCurrentPage(value);
  };

  return (
    <Container loading={isLoading} seo={{ title: "Little Cook" }}>
      <div className="p-0 overflow-hidden" id="hero">
        <div className="w-full mx-auto relative aspect-video bg-grey">
          {bannerList && <HeroSlider list={bannerList} />}
        </div>
      </div>
      <div className="pt-10 sm:pt-16 pb-8 px-6 sm:px-12 overflow-hidden">
        <div className="w-full max-w-container1200 mx-auto relative flex flex-col">
          <div className="flex flex-col sm:flex-row justify-evenly items-center sm:items-start mt-5">
            <div className="w-1/3 mb-8 mx-3 flex flex-col items-center">
              <div className="rounded-full w-32 h-32 mb-3 overflow-hidden">
                <img src={Images.IconAboutUs01} className="" alt="" />
              </div>
              <p className="text-sm sm:text-base font-bold text-center">
                Fresh Ingredients
                <br />
                新鲜食材
              </p>
            </div>
            <div className="w-1/3 mb-8 mx-3 flex flex-col items-center">
              <div className="rounded-full w-32 h-32 mb-3 overflow-hidden">
                <img src={Images.IconAboutUs02} className="" alt="" />
              </div>
              <p className="text-sm sm:text-base font-bold text-center">
                Authentic, Traditional Taste
                <br />
                正宗传统味道
              </p>
            </div>
            <div className="w-1/3 mb-8 mx-3 flex flex-col items-center">
              <div className="rounded-full w-32 h-32 mb-3 overflow-hidden">
                <img src={Images.IconAboutUs03} className="" alt="" />
              </div>
              <p className="text-sm sm:text-base font-bold text-center">
                No preservatives
                <br />
                无防腐剂,
                <br />
                No added colourings
                <br />
                无添加色素
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="py-10 sm:py-16 px-6 sm:px-12 overflow-hidden">
        <div className="w-full max-w-container1200 mx-auto relative flex flex-col">
          {/* Filter */}
          <div className="flex flex-col sm:flex-row justify-between items-center">
            <div>
              <h1 className="text-2xl sm:text-3xl font-bold mb-8">
                {page.LITTLECOOK}
              </h1>
            </div>
          </div>

          {/* Listing */}
          <div>
            {isReady ? (
              list.length > 0 ? (
                <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 lg:grid-cols-5 gap-6">
                  {list.map((item, i) => {
                    return (
                      <div className="">
                        <MenuCard key={i} data={item} index={i} />
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div>No content available</div>
              )
            ) : (
              <div className="min-h-[300px] flex justify-center items-center">
                <FaSpinner className="text-2xl text-grey animate-spin" />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="py-5 sm:py-8 px-6 sm:px-12 overflow-hidden flex justify-end">
        <Pagination
          className={`sans`}
          count={data.last_page}
          page={currentPage}
          onChange={handleChange}
        />
      </div>
    </Container>
  );
}
