import React from "react";
import ProfileNav from "../components/elements/profile-nav";
import Container from "../components/layout/container";
import { Post } from "../services";
import { alertModal } from "../components/elements/modal/alert";
import { useAuth } from "../hook/useAuth";
import { useCart } from "../hook/useCart";
import { useRouter } from "../hook/useRouter";
import { format } from "date-fns";
import Pagination from "@mui/material/Pagination";
import { page } from "../assets/copy/page";
import { path } from "../assets/copy/path";
import { FaSpinner } from "react-icons/fa";

function TransactionHistory() {
  const auth = useAuth();
  const cart = useCart();
  const { location, navigate, state } = useRouter();
  const [isLoading, setIsLoading] = React.useState(true);
  const [isReady, setIsReady] = React.useState(false);
  const [data, setData] = React.useState({});
  const [list, setList] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(1);

  React.useEffect(() => {
    const body = getBody();
    Post(`/wallet/search`, body)
      .then((response) => {
        setData(response);
        setList(response.data);
        setIsReady(true);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error: ", error);
        if (error.code_status === 401) {
          auth.logout();
          alertModal
            .fire({
              title: "Session Expired", // Title
              text: "Please login to continue", // Text
              icon: "error", // Icon
              confirmButtonText: "Ok", // confirmButtonText
            })
            .then((result) => {
              if (result.isConfirmed) {
                // Go to login page
                navigate(path.LOGIN, {
                  state: {
                    ...state,
                    redirectTo: location,
                  },
                });
              }
            });
        } else {
          alertModal.fire(
            "Error", // Title
            error.message, // Text
            "error", // Icon
            "Ok" // confirmButtonText
          );
        }
      });
    if (state?.scrollTo) {
      const section = document.getElementById(state.scrollTo);
      section && scrollToSection(section);
    } else window.scrollTo(0, 0);
  }, [currentPage]);

  const scrollToSection = (section) => {
    const headerOffset = document.getElementById("header").offsetHeight;
    const sectionPosition = section.getBoundingClientRect().top;
    const offsetPosition = sectionPosition + window.pageYOffset - headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
    // navigate(path.TRANSACTIONHISTORY, { replace: true });
  };

  const getBody = () => {
    return {
      limit: 15,
      page: currentPage,
      order_by: {
        created_at: "desc",
      },
    };
  };

  const handleChange = (e, value) => {
    setIsReady(false);
    setCurrentPage(value);
  };

  const renderStatus = (status) => {
    switch (status) {
      case 0:
        return <p className="">Pending</p>;
      case 1:
        return <p className="text-green">Success</p>;
      case 2:
        return <p className="text-red">Failed</p>;
      default:
        break;
    }
  };

  return (
    <Container
      loading={isLoading}
      seo={{ title: `FoodHeart | ${page.TRANSACTIONHISTORY}` }}
    >
      <div className="px-6 sm:px-12 overflow-hidden">
        <div className="py-5 sm:py-8 w-full max-w-container1200 mx-auto relative flex flex-col md:flex-row justify-center">
          <ProfileNav hideMobile={true} />
          <div
            className="py-5 sm:py-8 w-full md:w-3/5 flex flex-col"
            id="transaction-history"
          >
            <h1 className="text-2xl sm:text-3xl font-bold">
              {page.TRANSACTIONHISTORY}
            </h1>

            {/* Listing */}
            <div className="py-10">
              {isReady ? (
                <table className="w-full">
                  <thead>
                    <tr className="bg-primary text-sm sm:text-base text-white">
                      <th className="w-1/2 text-left px-2 py-4 sm:px-4 rounded-l-3xl overflow-hidden">
                        Transaction Type
                      </th>
                      <th className="w-1/4 text-center px-2 py-4 sm:px-4">
                        Status
                      </th>
                      <th className="w-1/4 text-center px-2 py-4 sm:px-4  rounded-r-3xl overflow-hidden">
                        Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {list.length > 0 ? (
                      list.map((item, i) => (
                        <tr
                          key={i}
                          className="align-top [&>td]:first:pt-4 [&>td]:last:pb-4 text-sm sm:text-base border-b border-b-grey-light"
                        >
                          <td className="text-left py-3 px-2 sm:px-4">
                            <p className="font-bold">{item.title}</p>
                            <p className="text-xs sm:text-sm text-grey-dark">
                              {item.body}
                            </p>
                            <span className="text-xs sm:text-sm text-grey-dark">
                              {format(
                                new Date(item.created_at),
                                "dd-MM-yyyy HH:mm:ss"
                              )}
                            </span>
                          </td>
                          <td className="text-center font-bold py-3 px-2 sm:px-4">
                            {renderStatus(item.status)}
                          </td>
                          <td className="text-center font-bold py-3 px-2 sm:px-4">
                            RM{" "}
                            {cart.convertPriceWithThousandSeparator(
                              item.amount
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className="align-top">
                        <td className="text-center py-2 px-4" colSpan={3}>
                          No transaction history available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              ) : (
                <div className="min-h-[300px] flex justify-center items-center">
                  <FaSpinner className="text-2xl text-grey animate-spin" />
                </div>
              )}
            </div>

            <div className="pb-5 sm:pb-8 px-6 sm:px-12 overflow-hidden flex justify-center">
              <Pagination
                className={`sans`}
                count={data.last_page}
                page={currentPage}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default TransactionHistory;
