import React, { useEffect, useRef } from "react";
import { CgChevronDown } from "react-icons/cg";

export default function CuisineMenu({ cuisine, setCuisine, setIsReady }) {
  const [show, setShow] = React.useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!ref.current?.contains(event.target)) {
        if (!show) return;
        setShow(false);
      }
    };
    window.addEventListener("click", handleOutsideClick);
    return () => window.removeEventListener("click", handleOutsideClick);
  }, [show, ref]);

  const toggle = () => {
    setShow(!show);
  };

  const handleClick = (val) => {
    setIsReady(false);
    setCuisine(val);
  };

  return (
    <div
      ref={ref}
      className={`relative w-[calc(50%-24px)] sm:w-auto my-1 mx-3 ${
        show ? "open" : ""
      } [&>div.dropdown-menu]:h-auto transition duration-150 ease-in-out`}
    >
      <button
        className="relative flex flex-row border border-solid border-grey rounded-md p-2 pr-6 w-full sm:w-36"
        onClick={toggle}
        type="button"
        id="options-menu"
        aria-haspopup="true"
        data-bs-toggle="dropdown"
        aria-expanded={show ? "true" : undefined}
      >
        <p className="ml-2">{cuisine}</p>
        <CgChevronDown className="text-base absolute top-0 right-1 h-full" />
      </button>
      {show && (
        <div
          className="dropdown-menu min-w-max w-full h-0 invisible opacity-0 absolute bg-white py-2 rounded-md shadow z-40"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          <div
            className={`${styles.dropdownItem} ${
              cuisine === "Chinese" ? styles.selectedDropdownItem : ""
            }`}
            onClick={() => {
              handleClick("Chinese");
              toggle();
            }}
            tabIndex={0}
            role="menuitem"
          >
            Chinese
          </div>
          <div
            className={`${styles.dropdownItem} ${
              cuisine === "Japanese" ? styles.selectedDropdownItem : ""
            }`}
            onClick={() => {
              handleClick("Japanese");
              toggle();
            }}
            tabIndex={0}
            role="menuitem"
          >
            Japanese
          </div>

          <div
            className={`${styles.dropdownItem} ${
              cuisine === "Western" ? styles.selectedDropdownItem : ""
            }`}
            onClick={() => {
              handleClick("Western");
              toggle();
            }}
            tabIndex={0}
            role="menuitem"
          >
            Western
          </div>
        </div>
      )}
    </div>
  );
}

const styles = {
  dropdownItem: `py-2 px-6 cursor-pointer hover:bg-primary-extra-light-50`,
  selectedDropdownItem: `!bg-primary-extra-light`,
};
