import React from "react";

export const SingleInput = React.forwardRef(
  ({ type, value, onChange, onKeyDown, autoFocus, style, disabled }, ref) => {
    const inputRef = React.useRef(null);

    React.useImperativeHandle(ref, () => ({
      focus: () => {
        inputRef.current.focus();
      },
      select: () => {
        inputRef.current.select();
      },
    }));

    const handleFocus = () => {
      inputRef.current.select();
    };

    return (
      <input
        className="w-1/5 max-w-[50px] text-2xl text-center border border-grey-extra-light p-2 rounded-md shadow-md"
        type={type}
        inputMode="numeric"
        pattern="[0-9]*"
        maxLength="1"
        ref={inputRef}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onFocus={handleFocus}
        autoFocus={autoFocus}
        style={style}
        disabled={disabled}
      />
    );
  }
);
