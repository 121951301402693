import React from "react";
import { page } from "../assets/copy/page";
import Container from "../components/layout/container";
import Images from "../assets/images/images";

export default function AboutUs() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container seo={{ title: `FoodHeart | ${page.ABOUTUS}` }}>
      <div className="pt-10 sm:pt-16 pb-8 px-6 sm:px-12 overflow-hidden">
        <div className="w-full max-w-container1200 mx-auto relative flex flex-col">
          <div className="mb-8">
            <h1 className="text-2xl sm:text-3xl font-bold text-center">
              {page.ABOUTUS}
            </h1>
          </div>
          <div>
            <div className="max-w-[900px] mx-auto mb-16">
              <p className="text-sm sm:text-base mb-4 text-center">
                As the name implies, FoodHeart is an Online Kitchen that is
                dedicated to warm your heart with delicious food straight out
                from our kitchen to your doorstep! Helping working parents or
                busy families to solve the cooking and meals preparation
                problems is what we do.
              </p>
              <p className="text-sm sm:text-base mb-4 text-center">
                If you have got NO TIME to cook, do not know HOW to cook, or
                WHAT to cook? Plus having old folks or young children at home,
                looking to solve your food catering problem once and for all,
                you are in the right place now!
              </p>
              <p className="text-sm sm:text-base mb-4 text-center">
                Whether it is your three meals a day, party celebration or
                ready-to-eat meal package that can be stored and heated at any
                time when needed, we have it all!
              </p>
              <p className="text-sm sm:text-base mb-5 text-center">
                We specialized in providing catering and delivery service to
                household, families, offices, factories, nurseries etc.:
              </p>
              <div className="flex flex-col sm:flex-row justify-evenly items-center sm:items-start ">
                <div className="w-1/3 mb-8 mx-3 flex flex-col items-center">
                  <div className="rounded-full w-32 h-32 mb-3 overflow-hidden">
                    <img src={Images.IconAboutUs01} className="" alt="" />
                  </div>
                  <p className="text-sm sm:text-base font-bold text-center">
                    Daily Meal Delivery
                    <br />
                    (subscription basis)
                  </p>
                </div>
                <div className="w-1/3 mb-8 mx-3 flex flex-col items-center">
                  <div className="rounded-full w-32 h-32 mb-3 overflow-hidden">
                    <img src={Images.IconAboutUs02} className="" alt="" />
                  </div>
                  <p className="text-sm sm:text-base font-bold text-center">
                    Buffet Catering
                  </p>
                </div>
                <div className="w-1/3 mb-8 mx-3 flex flex-col items-center">
                  <div className="rounded-full w-32 h-32 mb-3 overflow-hidden">
                    <img src={Images.IconAboutUs03} className="" alt="" />
                  </div>
                  <p className="text-sm sm:text-base font-bold text-center">
                    Ready-to-eat Food
                    <br />
                    (Little Cook)
                  </p>
                </div>
              </div>
            </div>
            <div className="mb-8">
              <h1 className="text-2xl sm:text-3xl font-bold text-center mb-8">
                What We Offer
              </h1>
              <div>
                <div className="flex flex-col sm:flex-row items-center mb-8">
                  <div className="w-full sm:w-[52%]">
                    <img src={Images.ImgAboutUs01} className="" alt="" />
                  </div>
                  <div className="w-full sm:w-[48%] h-full flex flex-col justify-center items-center text-center p-5">
                    <h4 className="text-xl sm:text-2xl font-bold mb-4">
                      FOOD QUALITY
                    </h4>
                    <p className="text-sm sm:text-base">
                      Our passionate chefs with 15 years of cooking experience
                      understand the need of a nutrition balanced, healthy and
                      hearty meal. We insist on not using MSG on our food to
                      ensure that old folks and toddlers can eat our food in
                      peace of mind.
                    </p>
                  </div>
                </div>

                <div className="flex flex-col-reverse sm:flex-row items-center mb-8">
                  <div className="w-full sm:w-[48%] h-full flex flex-col justify-center items-center text-center p-5">
                    <h4 className="text-xl sm:text-2xl font-bold mb-4">
                      FRESH INGREDIENTS & GOOD CONDIMENTS
                    </h4>
                    <p className="text-sm sm:text-base">
                      We procure our ingredients daily to ensure the freshness
                      and quality of our food. The condiments we used are more
                      family-oriented, which is healthier and better in quality.
                    </p>
                  </div>
                  <div className="w-full sm:w-[52%]">
                    <img src={Images.ImgAboutUs02} className="" alt="" />
                  </div>
                </div>

                <div className="flex flex-col sm:flex-row items-center mb-8">
                  <div className="w-full sm:w-[52%]">
                    <img src={Images.ImgAboutUs03} className="" alt="" />
                  </div>
                  <div className="w-full sm:w-[48%] h-full flex flex-col justify-center items-center text-center p-5">
                    <h4 className="text-xl sm:text-2xl font-bold mb-4">
                      DELICIOUS MEALS
                    </h4>
                    <p className="text-sm sm:text-base">
                      We are 1st in Malaysia providing 3 different cuisines for
                      daily meal delivery at a fixed price. We serve more than
                      300 menus vary from Chinese, Western to Japanese cuisines.
                      We are committed to serving tasty, authentic and
                      affordable high quality home-taste food to everyone.
                    </p>
                  </div>
                </div>

                <div className="flex flex-col-reverse sm:flex-row items-center mb-8">
                  <div className="w-full sm:w-[48%] h-full flex flex-col justify-center items-center text-center p-5">
                    <h4 className="text-xl sm:text-2xl font-bold mb-4">
                      QUALITY PACKAGING
                    </h4>
                    <p className="text-sm sm:text-base">
                      We are using high quality food-grade packaging materials,
                      ensuring that the food is kept in best condition and
                      keeping its freshness along the delivery journey and also
                      for storage purpose.
                    </p>
                  </div>
                  <div className="w-full sm:w-[52%]">
                    <img src={Images.ImgAboutUs04} className="" alt="" />
                  </div>
                </div>

                <div className="flex flex-col sm:flex-row items-center mb-8">
                  <div className="w-full sm:w-[52%]">
                    <img src={Images.ImgAboutUs05} className="" alt="" />
                  </div>
                  <div className="w-full sm:w-[48%] h-full flex flex-col justify-center items-center text-center p-5">
                    <h4 className="text-xl sm:text-2xl font-bold mb-4">
                      RESPONSIBLE DELIVERY SERVICE
                    </h4>
                    <p className="text-sm sm:text-base">
                      We always ensure the daily meal is delivered to our
                      customers safely and within the time frame. As for our
                      ready-to-eat food (Little Cook's product), we offer
                      express delivery services to various states across
                      Peninsular Malaysia.
                    </p>
                  </div>
                </div>

                <div className="flex flex-col-reverse sm:flex-row items-center mb-8">
                  <div className="w-full sm:w-[48%] h-full flex flex-col justify-center items-center text-center p-5">
                    <h4 className="text-xl sm:text-2xl font-bold mb-4">
                      PROMPT SUPPORT
                    </h4>
                    <p className="text-sm sm:text-base">
                      Our friendly and helpful customer service support team is
                      always ready to answer to your doubts!
                    </p>
                  </div>
                  <div className="w-full sm:w-[52%]">
                    <img src={Images.ImgAboutUs06} className="" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
