import React, { useEffect, useRef } from "react";
import { useOnHoverOutside } from "../../../hook/useOnHoverOutside";

export default function SelectAllMenu({ handleSelectAll }) {
  const [show, setShow] = React.useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!ref.current?.contains(event.target)) {
        if (!show) return;
        setShow(false);
      }
    };
    window.addEventListener("click", handleOutsideClick);
    return () => window.removeEventListener("click", handleOutsideClick);
  }, [show, ref]);

  const toggle = () => {
    setShow(!show);
  };

  const closeHoverMenu = () => {
    setShow(false);
  };

  useOnHoverOutside(ref, closeHoverMenu);

  return (
    <div
      ref={ref}
      className={`relative my-1 mx-2 ${
        show ? "open" : ""
      } [&>div.dropdown-menu]:h-auto transition duration-150 ease-in-out`}
    >
      <button
        className="relative button text-sm sm:text-base font-bold !leading-[120%] capitalize shadow hover:shadow-md disabled:!opacity-60 disabled:pointer-events-none transition duration-300 ease-in-out  text-primary bg-white border-2 border-solid border-primary py-[10px] px-[22px] min-w-[120px] rounded-button mb-10"
        onClick={toggle}
        onMouseOver={() => setShow(true)}
        type="button"
        id="options-menu"
        aria-haspopup="true"
        data-bs-toggle="dropdown"
        aria-expanded={show ? "true" : undefined}
      >
        <>
          Select all
          <br />
          <span className="text-3xs font-medium">(Applies to all pax)</span>
        </>
      </button>
      {show && (
        <div
          className="dropdown-menu min-w-max w-full h-0 invisible opacity-0 absolute top-[63px] bg-white py-2 rounded-md shadow z-40"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          <div
            className={styles.dropdownItem}
            onClick={() => {
              handleSelectAll("Chinese");
              toggle();
            }}
            tabIndex={0}
            role="menuitem"
          >
            Chinese
          </div>
          <div
            className={styles.dropdownItem}
            onClick={() => {
              handleSelectAll("Western");
              toggle();
            }}
            tabIndex={0}
            role="menuitem"
          >
            Western
          </div>
          <div
            className={styles.dropdownItem}
            onClick={() => {
              handleSelectAll("Japanese");
              toggle();
            }}
            tabIndex={0}
            role="menuitem"
          >
            Japanese
          </div>
        </div>
      )}
    </div>
  );
}

const styles = {
  dropdownItem: `py-2 px-6 cursor-pointer hover:bg-primary-extra-light-50`,
  selectedDropdownItem: `!bg-primary-extra-light`,
};
