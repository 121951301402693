import React from "react";
import { page } from "../assets/copy/page";
import Container from "../components/layout/container";

export default function RefundPolicy() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container seo={{ title: `FoodHeart | ${page.REFUNDPOLICY}` }}>
      <div className="pt-10 sm:pt-16 pb-8 px-6 sm:px-12 overflow-hidden">
        <div className="w-full max-w-container1200 mx-auto relative flex flex-col">
          <div className="mb-8">
            <h1 className="text-2xl sm:text-3xl font-bold">
              {page.REFUNDPOLICY}
            </h1>
          </div>
          <div>
            <div className="mb-8">
              <h4 className="text-xl font-bold underline mb-5">
                Daily Meal Delivery Service
              </h4>
              <p className="mb-5">
                We will not accept any cancellation, return or exchange of
                meal(s) that are delivered or shipped to you as every meal(s)
                will be assured in fresh and good conditions. Once payment for
                the meal(s) has been made to FoodHeart Sdn Bhd, it shall not be
                refunded or refundable under any circumstances or any reasons.
                We will send you a confirmation email immediately once the
                payment has been made successfully. However, you may contact us
                directly if you insist on a refund for certain specific reasons,
                we will get back to you within 7 working days upon receiving the
                request. If approved, you will be automatically refunded to your
                original payment method.
              </p>
            </div>

            <div className="mb-8">
              <h4 className="text-xl font-bold underline mb-5">
                Buffet Catering
              </h4>
              <p className="mb-5">
                To start a cancellation and refund, contact us before your
                delivery date. We only allow cancellation and refund of orders
                made 3 days before the delivery date and based on the total
                amount paid, a 20% administration fee will be charged. Please
                note that the refund will take up to 7 working days and will be
                refunded to your original payment method.
              </p>
            </div>

            <div className="mb-8">
              <h4 className="text-xl font-bold underline mb-5">
                Ready-to-Eat Food (by Little Cook)
              </h4>
              <p className="mb-5">
                Any requests for refund must be made within 24 hours upon
                receiving the products. Please check your order carefully upon
                receipt and contact us immediately if you receive the wrong item
                so that we can evaluate the issue and solve the problems. Items
                sent back to us without notifying us will not be accepted.
                <br />
                <br />
                Please note that we will not refund if the case that the items
                purchased do not meet your expectations. To be eligible for a
                return, your item must be in the same condition as your received
                it, unconsumed, with tads and in its original packaging. You
                will need to provide the receipt or proof of purchase and return
                the items purchased at your own cost. Please note that refund
                will take up to 7 working days and will be refunded to your
                original payment method.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
