import React, { useEffect, useState } from "react";
import { ModalBody, ModalWrapper } from ".";
import Button from "../button";
import { alertModal } from "./alert";
import { PostUrlEncoding, PutUrlEncoding } from "../../../services";
import { SingleInput } from "../form/single-input";
import { BiChevronRight } from "react-icons/bi";
import { MdErrorOutline } from "react-icons/md";
import { useAuth } from "../../../hook/useAuth";
import { useRouter } from "../../../hook/useRouter";
import { path } from "../../../assets/copy/path";
import { Link } from "react-router-dom";

export default function EnterPinModal({
  open,
  setOpen,
  handleCheckoutWallet,
  handleCancelCheckout,
}) {
  const auth = useAuth();
  const { location, navigate, state } = useRouter();
  const inputCurrentPinRef = React.useRef([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [currentPin, setCurrentPin] = React.useState("");
  const [currentPinError, setCurrentPinError] = React.useState("");

  useEffect(() => {
    setIsOpen(open);
    if (open) {
      setCurrentPin("");
      setCurrentPinError("");
      setIsLoading(false);
    }
  }, [open]);

  React.useEffect(() => {
    if (!isLoading) inputCurrentPinRef.current[0].focus();
  }, [isLoading]);

  const toggle = () => {
    setOpen(!isOpen);
    setIsOpen(!isOpen);
    handleCancelCheckout();
  };

  const handleInputChange = (e, index, key, setKey, inputRef) => {
    setCurrentPinError("");
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      // only allow digits
      let newKey = key;
      const keyArr = newKey.split("");
      keyArr[index] = value;
      newKey = keyArr.join("");
      setKey(newKey);

      if (value) {
        inputRef.current[index + 1]?.focus(); // focus on next input if exists
      }
    }
  };

  const handleInputKeyDown = (e, index, key, inputRef) => {
    if (e.key === "Backspace" && ![key][index]) {
      inputRef.current[index - 1].focus(); // focus on previous input
    }
  };

  const handleSubmitCurrentPin = () => {
    if (currentPin.length === 6) {
      handleCheckPin();
    } else {
      setCurrentPinError("Invalid current PIN");
    }
  };

  const handleCheckPin = () => {
    setIsSubmitting(true);
    PostUrlEncoding("/user/security/check-pin", {
      pin: currentPin,
    })
      .then((response) => {
        setIsSubmitting(false);
        handleCheckoutWallet();
      })
      .catch((error) => {
        console.error("Error: ", error);
        if (error.code_status === 401) {
          auth.logout();
          alertModal
            .fire({
              title: "Session Expired", // Title
              text: "Please login to continue", // Text
              icon: "error", // Icon
              confirmButtonText: "Ok", // confirmButtonText
            })
            .then((result) => {
              if (result.isConfirmed) {
                // Go to login page
                navigate(path.LOGIN, {
                  state: {
                    ...state,
                    redirectTo: location,
                  },
                });
              }
            });
        } else if (error.code_status === 403) {
          setCurrentPin("");
          setCurrentPinError(error.message);
        } else {
          alertModal.fire(
            "Error", // Title
            error.message, // Text
            "error", // Icon
            "Ok" // confirmButtonText
          );
        }
        setIsSubmitting(false);
      });
  };

  return (
    <ModalWrapper open={isOpen} toggle={toggle} className="w-full">
      <ModalBody className="p-10">
        <h2 className="text-xl sm:text-2xl font-bold text-center mb-4">
          Enter e-Wallet PIN
        </h2>
        <p className="mb-4">
          Please enter your current PIN to verify your identity.
        </p>
        <div className="flex flex-col items-center">
          <div className="flex flex-row gap-x-4">
            {Array.from({ length: 6 }, (_, i) => (
              <SingleInput
                className=""
                type="password"
                key={i}
                value={currentPin[i] ?? ""}
                onChange={(e) =>
                  handleInputChange(
                    e,
                    i,
                    currentPin,
                    setCurrentPin,
                    inputCurrentPinRef
                  )
                }
                onKeyDown={(e) =>
                  handleInputKeyDown(e, i, currentPin, inputCurrentPinRef)
                }
                autoFocus={i === 0}
                ref={(el) => (inputCurrentPinRef.current[i] = el)}
              />
            ))}
          </div>
          {currentPinError && (
            <div className="flex items-center mt-2 text-xs sm:text-sm text-red">
              <MdErrorOutline className="mr-1" /> <span>{currentPinError}</span>
            </div>
          )}
        </div>
        <div className="mt-8">
          <Link
            className="text-sm sm:text-base font-bold text-primary cursor-pointer"
            to={path.EWALLET}
            state={{
              scrollTo: "ewallet",
              action: "Forgot pin",
            }}
          >
            Forgot PIN
          </Link>
        </div>
        <Button
          type="button"
          name="Submit"
          color="primary"
          buttonClassName="mt-4"
          disabled={currentPin.length < 6 || isSubmitting}
          onClick={handleSubmitCurrentPin}
        />
      </ModalBody>
    </ModalWrapper>
  );
}

const StepProgress = ({ step }) => {
  const items = [
    {
      step: 1,
      label: "Enter Current PIN",
    },
    {
      step: 2,
      label: "Enter New PIN",
    },
    {
      step: 3,
      label: "Confirm New PIN",
    },
  ];
  const style = {
    stepWrap: `flex flex-col items-center flex-1 mx-1`,
    step: `rounded-full border border-primary p-1 w-8 h-8 font-bold mb-1`,
    primary: `bg-primary text-white`,
    secondary: `bg-white text-primary`,
  };
  return (
    <div className="flex flex-row justify-center max-w-container400 mx-auto mb-6">
      {items.map((item, key) => {
        return (
          <>
            <div key={key} className={style.stepWrap}>
              <div
                className={`${style.step} ${
                  item.step <= step ? style.primary : style.secondary
                }`}
              >
                {item.step}
              </div>
              <p className="text-2xs">{item.label}</p>
            </div>
            {key < items.length - 1 ? (
              <BiChevronRight className="text-2xl text-grey self-center" />
            ) : null}
          </>
        );
      })}
    </div>
  );
};
