export default function Hero({ data }) {
  return (
    <div className="w-full relative overflow-hidden">
      <div className="bg-[#eaeff2] h-full">
        <img
          className="w-full h-full object-cover object-center aspect-video"
          src={data.image}
          alt={data.title}
        />
      </div>
      <div
        className="w-full h-full absolute inset-0 flex justify-center items-center px-[24px]"
        style={{
          background:
            "linear-gradient(180deg, rgba(0, 0, 0, 0) 46.62%, rgba(0, 0, 0, 0.6) 100%)",
        }}
      ></div>
      {/* {data.title && (
        <div
          className="w-full h-full absolute inset-0 flex justify-center items-center px-[24px]"
          style={{
            background:
              "linear-gradient(180deg, rgba(0, 0, 0, 0) 46.62%, rgba(0, 0, 0, 0.6) 100%)",
          }}
        >
          <div className="w-full max-w-[1600px] mx-auto py-[40px] text-white text-center">
            <h2 className="font-bold uppercase">{data.title}</h2>
            <p>{data.description}</p>
          </div>
        </div>
      )} */}
    </div>
  );
}
