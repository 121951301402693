import React, { useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import { CgClose } from "react-icons/cg";

export function ModalWrapper({
  children,
  className,
  open,
  toggle,
  closeOnClickOutside,
  disableCloseButton,
}) {
  const ref = useRef();

  // close modal on click outside
  useEffect(() => {
    // check if its on/off
    if (!closeOnClickOutside) return;

    const handleOutsideClick = (event) => {
      if (!ref.current?.contains(event.target)) {
        if (!open) return;
        toggle(false);
      }
    };
    window.addEventListener("click", handleOutsideClick);
    return () => window.removeEventListener("click", handleOutsideClick);
  }, [open, closeOnClickOutside, ref, toggle]);

  // close modal when you click on "ESC" key
  useEffect(() => {
    const handleEscape = (event) => {
      if (!open) return;
      if (event.key === "Escape") {
        toggle(false);
      }
    };
    document.addEventListener("keyup", handleEscape);
    return () => document.removeEventListener("keyup", handleEscape);
  }, [open, toggle]);

  // hide scrollbar and prevent body from moving when modal is open
  //put focus on modal dialogue
  useEffect(() => {
    if (!open) return;

    ref.current?.focus();

    const html = document.documentElement;
    const scrollbarWidth = window.innerWidth - html.clientWidth;

    html.style.overflow = "hidden";
    html.style.paddingRight = `${scrollbarWidth}px`;

    return () => {
      html.style.overflow = "";
      html.style.paddingRight = "";
    };
  }, [open]);

  return (
    <>
      {open &&
        ReactDOM.createPortal(
          <div className="fixed inset-0  z-40">
            <div className={style.overlay} />
            <div className={style.container}>
              <div
                className={`${style.content} ${className}`}
                ref={ref}
                role="dialogue"
                aria-modal={true}
                tabIndex={-1}
              >
                {!disableCloseButton && (
                  <button
                    className="absolute top-0 right-0 flex justify-center items-center ml-auto w-12 h-12 text-grey-light hover:text-primary"
                    type="button"
                    onClick={toggle}
                    style={{ transition: "color .1s" }}
                  >
                    <CgClose className="text-2xl" />
                  </button>
                )}
                {children}
              </div>
            </div>
          </div>,
          document.body
        )}
    </>
  );
}
export function ModalHeader({ children }) {
  return (
    <div className={style.header}>
      <h4 className={style.headerTitle}>{children}</h4>
    </div>
  );
}
export function ModalBody({ children, className }) {
  return <div className={`${style.body} ${className}`}>{children}</div>;
}
export function ModalFooter({ children }) {
  return <div className={style.footer}>{children}</div>;
}

const style = {
  overlay: `fixed inset-0 w-screen h-screen bg-black opacity-40 z-0`,
  container: `flex justify-center items-center z-40 h-full`, // m-0 p-2.5 overflow-hidden w-full
  content: `relative bg-white border-0 rounded m-2.5  overflow-y-auto outline-0
   flex flex-col  max-h-[calc(100%-20px)] max-w-container600`, // justify-center items-center pointer-events-auto w-full
  body: `flex-shrink flex-grow text-center w-full`,
  bodyTitle: `text-4xl font-bold text-bodyText`,
  bodyDescription: `text-sm text-bodyText`,
  headerTitle: `text-2xl md:text-3xl font-light`,
  header: `items-start justify-between p-4 border-b border-gray-300`,
  footer: `flex flex-wrap items-center justify-end p-3 border-t border-gray-300`,
};
