import React, { useEffect, useState } from "react";
import { ModalBody, ModalWrapper } from ".";
import Button from "../button";
import { MdAdd, MdRemove } from "react-icons/md";
import { useAuth } from "../../../hook/useAuth";
import { useCart } from "../../../hook/useCart";
import { useRouter } from "../../../hook/useRouter";
import { alertModal } from "./alert";
import { path } from "../../../assets/copy/path";

export default function AddToCartModal({ open, setOpen, data }) {
  const auth = useAuth();
  const cart = useCart();
  const { location, navigate } = useRouter();
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const toggle = () => {
    setOpen(!isOpen);
    setIsOpen(!isOpen);
  };

  const handleClickAdd = () => {
    setQuantity(quantity + 1);
  };

  const handleClickRemove = () => {
    if (quantity > 1) setQuantity(quantity - 1);
  };

  const handleClickAddToCart = () => {
    if (!auth.user) {
      alertModal
        .fire({
          text: "Please login to continue",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Login",
          cancelButtonText: "Cancel",
        })
        .then((result) => {
          if (result.isConfirmed) {
            navigate(path.LOGIN, {
              state: {
                redirectTo: location,
              },
            });
          }
        });
    } else {
      setIsSubmitting(true);
      cart.addCart(
        data.title || data.name,
        {
          type: data.type,
          product_id: data.id,
          quantity: quantity,
        },
        setIsSubmitting,
        toggle
      );
    }
  };

  return (
    <ModalWrapper open={isOpen} toggle={toggle} closeOnClickOutside={true}>
      <ModalBody className="p-6">
        <div className="sm:px-6">
          {data.image && (
            <div className="menu-card relative flex justify-center pt-6 pb-4 overflow-hidden">
              <img
                className="w-full aspect-4/3 sm:aspect-video object-cover object-center rounded-md shadow-md bg-grey-light"
                src={data.image}
                alt={data.title}
              />
            </div>
          )}
          <div className="flex flex-row justify-between pb-3">
            <div className="text-left mr-5">
              {(data.title || data.name) && (
                <h2 className="text-sm sm:text-base font-bold">
                  {data.title || data.name}
                </h2>
              )}
              <p className="text-xs sm:text-sm">{data.description}</p>
            </div>
            <span className="text-sm sm:text-base font-medium whitespace-nowrap">
              RM {data.price}
            </span>
          </div>
          <div className="flex flex-row justify-center">
            <button
              className="p-1.5 rounded-full shadow text-primary hover:[&>svg]:text-primary-dark hover:[&>svg]:scale-105 disabled:!opacity-60 disabled:pointer-events-none ease-in-out"
              type="button"
              onClick={() => handleClickRemove()}
              disabled={quantity <= 1}
            >
              <MdRemove className="text-2xl" />
            </button>
            <span className="text-sm sm:text-base font-medium p-1.5 w-14 text-center flex justify-center items-center">
              {quantity}
            </span>
            <button
              className="p-1.5 rounded-full shadow text-primary hover:[&>svg]:text-primary-dark hover:[&>svg]:scale-105 disabled:!opacity-60 disabled:pointer-events-none ease-in-out"
              type="button"
              onClick={() => handleClickAdd()}
            >
              <MdAdd className="text-2xl" />
            </button>
          </div>

          <div className="flex flex-col sm:flex-row justify-center items-center pt-6">
            <Button
              type="button"
              name="Add to Cart"
              color="primary"
              onClick={() => handleClickAddToCart()}
              disabled={isSubmitting}
            />
          </div>
        </div>
      </ModalBody>
    </ModalWrapper>
  );
}
