import React, { useEffect, useRef } from "react";
import Container from "../components/layout/container";
import { useAuth } from "../hook/useAuth";
import { useRouter } from "../hook/useRouter";
import { BiEditAlt } from "react-icons/bi";
import { MdDeleteOutline } from "react-icons/md";
import { Formik, Form } from "formik";
import * as yup from "yup";
import TextField from "../components/elements/form/text-field";
import Button from "../components/elements/button";
import { Delete, Get, Post, PutUrlEncoding } from "../services";
import { alertModal } from "../components/elements/modal/alert";
import { path } from "../assets/copy/path";
import DateField from "../components/elements/form/date-field";
import SelectField from "../components/elements/form/select-field";
import AddAddressModal from "../components/elements/modal/add-address";
import EditAddressModal from "../components/elements/modal/edit-address";
import ProfileNav from "../components/elements/profile-nav";
import CreatePinModal from "../components/elements/modal/create-pin";

function Profile() {
  const auth = useAuth();
  const formikRef = useRef(null);
  const { location, navigate, state } = useRouter();
  const [isLoading, setIsLoading] = React.useState(true);
  const [profileData, setProfileData] = React.useState({});
  const [addressData, setAddressData] = React.useState([]);
  const [openAddAddressModal, setOpenAddAddressModal] = React.useState(false);
  const [openEditAddressModal, setOpenEditAddressModal] = React.useState(false);
  const [selectedAddress, setSelectedAddress] = React.useState({});
  const [openCreatePinModal, setOpenCreatePinModal] = React.useState(false);
  const [requestOtp, setRequestOtp] = React.useState(false);

  useEffect(() => {
    Get(`/user/profile`)
      .then((response) => {
        if (response.code_status === 200) {
          setProfileData(response.data);
          setAddressData(response.data.addresses);
          setRequestOtp(!response.data.has_pin);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error: ", error);
        if (error.code_status === 401) {
          auth.logout();
          alertModal
            .fire({
              title: "Session Expired",
              text: "Please login to continue",
              icon: "error",
              confirmButtonText: "Ok",
            })
            .then((result) => {
              if (result.isConfirmed) {
                // Go to login page
                navigate(path.LOGIN, {
                  state: {
                    ...state,
                    redirectTo: location,
                  },
                });
              }
            });
        } else {
          alertModal.fire("Error", error.message, "error", "Ok");
        }
        setIsLoading(false);
      });
    if (state?.scrollTo) {
      const section = document.getElementById(state.scrollTo);
      section && scrollToSection(section);
    } else window.scrollTo(0, 0);

    if (state?.action === "Add address") {
      setTimeout(() => {
        setOpenAddAddressModal(true);
        navigate(path.PROFILE, { replace: true });
      }, 1000);
    }
  }, []);

  const scrollToSection = (section) => {
    const headerOffset = document.getElementById("header").offsetHeight;
    const sectionPosition = section.getBoundingClientRect().top;
    const offsetPosition = sectionPosition + window.pageYOffset - headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
    state.scrollTo === "address" &&
      navigate(path.PROFILE, { state: "profile", replace: true });
  };

  React.useEffect(() => {
    if (requestOtp) setOpenCreatePinModal(true);
  }, [requestOtp]);

  const onSubmitUpdateProfile = (values, actions) => {
    actions.setSubmitting(true);
    const params = new URLSearchParams();
    params.append("first_name", values.firstName);
    params.append("last_name", values.lastName);
    params.append("dob", values.dob);
    params.append("gender", values.gender);
    params.append("phone_no", values.phone);

    PutUrlEncoding(`/user/profile/${profileData.id}`, params)
      .then((response) => {
        alertModal.fire(
          "Success",
          "Your details have been updated successfully",
          "success",
          "Ok"
        );
        setProfileData(response.data);
        actions.setSubmitting(false);
      })
      .catch((error) => {
        console.error("Error: ", error);
        if (error.code_status === 401) {
          auth.logout();
          alertModal
            .fire({
              title: "Session Expired",
              text: "Please login to continue",
              icon: "error",
              confirmButtonText: "Ok",
            })
            .then((result) => {
              if (result.isConfirmed) {
                // Go to login page
                navigate(path.LOGIN, {
                  state: {
                    ...state,
                    redirectTo: location,
                  },
                });
              }
            });
        } else if (error.code_status === 422) {
          actions.setErrors({
            firstName: error.data.first_name,
            lastName: error.data.last_name,
            dob: error.data.dob,
            gender: error.data.gender,
            phone: error.data.phone_no,
          });
        } else {
          alertModal.fire("Error", error.message, "error", "Ok");
        }
        actions.setSubmitting(false);
      });
  };

  const handleAddAddress = () => {
    setOpenAddAddressModal(true);
  };

  const handleEditAddress = (data) => {
    setOpenEditAddressModal(true);
    setSelectedAddress(data);
  };

  const handleRemoveAddress = (id) => {
    alertModal
      .fire({
        title: "Delete Address?",
        html: "Are you sure you want to delete this address",
        icon: "question",
        showDenyButton: true,
        confirmButtonText: "Delete",
        denyButtonText: `Cancel`,
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          onSubmitDeleteAddress(id);
        }
      });
  };

  const onSubmitDeleteAddress = (id) => {
    Delete(`/user/address/${id}`)
      .then((response) => {
        alertModal
          .fire(
            "Success",
            "The address have been deleted successfully",
            "success",
            "Ok"
          )
          .then((result) => {
            window.location.reload();
          });
      })
      .catch((error) => {
        console.error("Error: ", error);
        if (error.code_status === 401) {
          auth.logout();
          alertModal
            .fire({
              title: "Session Expired",
              text: "Please login to continue",
              icon: "error",
              confirmButtonText: "Ok",
            })
            .then((result) => {
              if (result.isConfirmed) {
                // Go to login page
                navigate(path.LOGIN, {
                  state: {
                    ...state,
                    redirectTo: location,
                  },
                });
              }
            });
        } else {
          alertModal.fire("Error", error.message, "error", "Ok");
        }
      });
  };

  const onSubmitUpdatePassword = (values, actions) => {
    actions.setSubmitting(true);
    const { currentPassword, newPassword, confirmNewPassword } = values;
    Post(`/user/change-password`, {
      old_password: currentPassword,
      new_password: newPassword,
      new_password_confirmation: confirmNewPassword,
    })
      .then((response) => {
        alertModal.fire("Success", response.message, "success", "Ok");
        actions.resetForm();
        actions.setSubmitting(false);
      })
      .catch((error) => {
        console.error("Error: ", error);
        if (error.code_status === 401) {
          auth.logout();
          alertModal
            .fire({
              title: "Session Expired",
              text: "Please login to continue",
              icon: "error",
              confirmButtonText: "Ok",
            })
            .then((result) => {
              if (result.isConfirmed) {
                // Go to login page
                navigate(path.LOGIN, {
                  state: {
                    ...state,
                    redirectTo: location,
                  },
                });
              }
            });
        } else if (error.code_status === 422) {
          actions.setErrors({
            currentPassword: error.data.old_password,
            newPassword: error.data.new_password,
            confirmNewPassword: error.data.new_password_confirmation,
          });
        } else {
          alertModal.fire("Error", error.message, "error", "Ok");
        }
        actions.setSubmitting(false);
      });
  };

  return (
    <Container loading={isLoading}>
      <div className="px-6 sm:px-12 overflow-hidden">
        <div className="py-5 sm:py-8 w-full max-w-container1200 mx-auto relative flex flex-col md:flex-row justify-center">
          <ProfileNav />
          <div
            className="py-5 sm:py-8 w-full md:w-3/5 flex flex-col"
            id="profile"
          >
            <h1 className="text-2xl sm:text-3xl font-bold">Profile</h1>
            <div className="py-10">
              <div className="mb-4">
                <h2 className="text-xl sm:text-2xl font-medium">
                  Personal Details
                </h2>
              </div>
              <div className="w-full">
                <Formik
                  innerRef={formikRef}
                  enableReinitialize
                  initialValues={{
                    email: profileData?.email || "",
                    username: profileData?.username || "",
                    firstName: profileData?.first_name || "",
                    lastName: profileData?.last_name || "",
                    phone: profileData?.phone_no || "",
                    dob: profileData?.dob || "",
                    gender: profileData?.gender || "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={onSubmitUpdateProfile}
                >
                  {({ isSubmitting, handleChange, dirty, isValid }) => (
                    <Form>
                      <div className="flex flex-col sm:flex-row">
                        <div className="w-full sm:w-1/2 mb-6 sm:mr-2">
                          <TextField
                            label={
                              <>
                                Email <span className="text-red">*</span>
                              </>
                            }
                            name="email"
                            type="email"
                            placeholder=""
                            autoComplete="off"
                            disabled
                          />
                        </div>
                        <div className="w-full sm:w-1/2 mb-6 sm:ml-2">
                          <TextField
                            label={
                              <>
                                Username <span className="text-red">*</span>
                              </>
                            }
                            name="username"
                            type="text"
                            placeholder=""
                            autoComplete="off"
                            disabled
                          />
                        </div>
                      </div>
                      <div className="flex flex-col sm:flex-row">
                        <div className="w-full sm:w-1/2 mb-6 sm:mr-2">
                          <TextField
                            label={
                              <>
                                First Name <span className="text-red">*</span>
                              </>
                            }
                            name="firstName"
                            type="text"
                            placeholder=""
                            autoComplete="off"
                          />
                        </div>
                        <div className="w-full sm:w-1/2 mb-6 sm:ml-2">
                          <TextField
                            label={
                              <>
                                Last Name <span className="text-red">*</span>
                              </>
                            }
                            name="lastName"
                            type="text"
                            placeholder=""
                            autoComplete="off"
                          />
                        </div>
                      </div>
                      <div className="flex flex-col sm:flex-row">
                        <div className="w-full sm:w-1/2 mb-6 sm:mr-2">
                          <DateField
                            label="Date of Birth"
                            name="dob"
                            placeholder=""
                          />
                        </div>
                        <div className="w-full sm:w-1/2 mb-6 sm:ml-2">
                          <SelectField
                            label="Gender"
                            name="gender"
                            options={[
                              {
                                value: "male",
                                label: "Male",
                              },
                              {
                                value: "female",
                                label: "Female",
                              },
                            ]}
                          />
                        </div>
                      </div>
                      <div className="flex flex-col sm:flex-row">
                        <div className="w-full sm:w-1/2 mb-6 sm:mr-2">
                          <TextField
                            label="Phone Number"
                            name="phone"
                            type="tel"
                            placeholder="0123456789"
                            autoComplete="off"
                            isNumberField={true}
                          />
                        </div>
                        <div className="w-full sm:w-1/2 mb-6 sm:ml-2"></div>
                      </div>
                      <div className="mt-4">
                        <Button
                          type="submit"
                          name="Update Details"
                          color="primary"
                          disabled={!(dirty && isValid) || isSubmitting}
                        />
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>

            <hr className="bg-grey-light my-6" />

            <div className="py-10" id="address">
              <div className="flex flex-row justify-between items-center mb-4">
                <h2 className="text-xl sm:text-2xl font-medium">
                  Addresses{" "}
                  <span className="text-3xs sm:text-xs">
                    (max. 3 addresses)
                  </span>
                </h2>
              </div>
              {addressData.length > 0 ? (
                <div className="w-full">
                  <ul>
                    {addressData.map((item, i) => (
                      <li
                        key={i}
                        className="text-sm sm:text-base flex flex-row justify-between border border-solid border-grey-light mb-4 py-2 px-4 rounded-md"
                      >
                        <div>
                          <p>{item.address_1}</p>
                          {item.address_2 && <p>{item.address_2}</p>}
                          <p>
                            {item.zip_code} {item.city}
                          </p>
                          <p>
                            {item.state}, {item.country}
                          </p>
                          {item.default ? (
                            <div className="mt-2">
                              <span className="text-3xs sm:text-xs text-white mt-1 py-1 px-2.5 bg-primary rounded-3xl">
                                Default Address
                              </span>
                            </div>
                          ) : null}
                        </div>
                        <div className="flex flex-row items-center">
                          <button onClick={() => handleEditAddress(item)}>
                            <BiEditAlt className="text-2xl mr-4 cursor-pointer" />
                          </button>
                          <button
                            className="disabled:opacity-50"
                            onClick={() => handleRemoveAddress(item.id)}
                            disabled={item.default}
                          >
                            <MdDeleteOutline className="text-2xl" />
                          </button>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                <div className="w-full mb-12">No addresses added</div>
              )}
              <div className="mt-10">
                <Button
                  type="button"
                  name="Add New Address"
                  color="primary"
                  onClick={handleAddAddress}
                  disabled={addressData.length >= 3}
                />
              </div>
              <AddAddressModal
                open={openAddAddressModal}
                setOpen={setOpenAddAddressModal}
                addAsDefaultAddress={addressData.length === 0}
              />
              <EditAddressModal
                open={openEditAddressModal}
                setOpen={setOpenEditAddressModal}
                data={selectedAddress}
              />
            </div>

            <hr className="bg-grey-light my-6" />

            <div className="pt-10">
              <div className="mb-4">
                <h2 className="text-xl sm:text-2xl font-medium">
                  Update password
                </h2>
              </div>
              <div className="w-full mb-12">
                <Formik
                  enableReinitialize
                  initialValues={{
                    currentPassword: "",
                    newPassword: "",
                    confirmNewPassword: "",
                  }}
                  validationSchema={validationSchemaUpdatePassword}
                  onSubmit={onSubmitUpdatePassword}
                >
                  {({ isSubmitting, handleChange, dirty, isValid }) => (
                    <Form>
                      <div className="mb-6">
                        <TextField
                          label={
                            <>
                              Current Password{" "}
                              <span className="text-red">*</span>
                            </>
                          }
                          name="currentPassword"
                          type="password"
                          placeholder=""
                          autoComplete="off"
                          isPasswordField={true}
                        />
                      </div>
                      <div className="mb-6">
                        <TextField
                          label={
                            <>
                              New Password <span className="text-red">*</span>
                            </>
                          }
                          name="newPassword"
                          type="password"
                          placeholder=""
                          autoComplete="off"
                          isPasswordField={true}
                        />
                      </div>
                      <div className="mb-6">
                        <TextField
                          label={
                            <>
                              Confirm New Password{" "}
                              <span className="text-red">*</span>
                            </>
                          }
                          name="confirmNewPassword"
                          type="password"
                          placeholder=""
                          autoComplete="off"
                          isPasswordField={true}
                        />
                      </div>

                      <div className="mt-10">
                        <Button
                          type="submit"
                          name="Update password"
                          color="primary"
                          disabled={!(dirty && isValid) || isSubmitting}
                        />
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
          <CreatePinModal
            open={openCreatePinModal}
            setOpen={setOpenCreatePinModal}
          />
        </div>
      </div>
    </Container>
  );
}

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .email("Email is invalid")
    .max(255, "Email must be less than 255 characters")
    .required("Email is required"),
  username: yup.string().required("Username is required"),
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  phone: yup
    .number("Phone number is invalid")
    .typeError("Phone number is invalid")
    .integer("Phone number is invalid")
    .required("Phone Number is required"),
  dob: yup
    .date("Date of Birth is invalid")
    .required("Date of Birth is required"),
  gender: yup.string().required("Gender is required"),
});

const validationSchemaUpdatePassword = yup.object().shape({
  currentPassword: yup
    .string()
    .min(8, "Password must be at least 8 characters")
    .required("Current password is required"),
  newPassword: yup
    .string()
    .min(8, "New password must be at least 8 characters")
    .required("New password is required"),
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Password not match")
    .required("Confirm new password is required"),
});

export default Profile;
