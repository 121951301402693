import axios from "axios";

const DEBUG = process.env.NODE_ENV === "development";

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

axiosClient.defaults.withCredentials = false;

axiosClient.interceptors.request.use(
  (config) => {
    const token = JSON.parse(localStorage.getItem("user"))?.access_token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    // if (DEBUG) { console.info("config", config); }
    return config;
  },
  (error) => {
    if (DEBUG) {
      console.error("axios error", error);
    }
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  (response) => {
    if (response.status === 401) {
      alert("You are not authorized");
    }
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response.data;
  },
  (error) => {
    if (error.response && error.response.data) {
      if (DEBUG) {
        console.error("axios error", error.response.data);
      }
      return Promise.reject(error.response.data);
    }

    if (DEBUG) {
      console.error("axios error", error);
    }
    return Promise.reject(error);

    // const status = error.response?.status || 500;
    // // we can handle global errors here
    // switch (status) {
    //   // authentication (token related issues)
    //   case 401: {
    //     return Promise.reject(new APIError(err.message, 409));
    //   }

    //   // forbidden (permission related issues)
    //   case 403: {
    //     return Promise.reject(new APIError(err.message, 409));
    //   }

    //   // bad request
    //   case 400: {
    //     return Promise.reject(new APIError(err.message, 400));
    //   }

    //   // not found
    //   case 404: {
    //     return Promise.reject(new APIError(err.message, 404));
    //   }

    //   // conflict
    //   case 409: {
    //     return Promise.reject(new APIError(err.message, 409));
    //   }

    //   // unprocessable
    //   case 422: {
    //     return Promise.reject(new APIError(err.message, 422));
    //   }

    //   // generic api error (server related) unexpected
    //   default: {
    //     return Promise.reject(new APIError(err.message, 500));
    //   }
    // }
  }
);

export default axiosClient;
