import React, { useEffect, useState } from "react";
import { ModalBody, ModalWrapper } from ".";
import { path } from "../../../assets/copy/path";
import { useAuth } from "../../../hook/useAuth";
import { useRouter } from "../../../hook/useRouter";
import { Post } from "../../../services";
import Button from "../button";
import { alertModal } from "./alert";

export default function SubscribePlanModal({ open, setOpen, plan, planDay }) {
  const auth = useAuth();
  const { location, navigate, state } = useRouter();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const toggle = () => {
    setOpen(!isOpen);
    setIsOpen(!isOpen);
  };

  const handleClick = (daytime) => {
    Post(`/subscription/menu`, {
      subscription_plan: plan,
      daytime: daytime,
    })
      .then((response) => {
        navigate(path.SUBSCRIBEMENU, {
          state: {
            data: response.data,
            subscriptionPlan: plan,
            planDay: planDay,
            daytime: daytime,
          },
        });
      })
      .catch((error) => {
        console.error("Error: ", error);
        if (error.code_status === 401) {
          auth.logout();
          alertModal
            .fire({
              title: "Session Expired", // Title
              text: "Please login to continue", // Text
              icon: "error", // Icon
              confirmButtonText: "Ok", // confirmButtonText
            })
            .then((result) => {
              if (result.isConfirmed) {
                // Go to login page
                navigate(path.LOGIN, {
                  state: {
                    ...state,
                    redirectTo: location,
                  },
                });
              }
            });
        } else {
          alertModal.fire(
            "Error", // Title
            error.message, // Text
            "error", // Icon
            "Ok" // confirmButtonText
          );
        }
      });
    toggle();
  };

  return (
    <ModalWrapper open={isOpen} toggle={toggle} closeOnClickOutside={true}>
      <ModalBody className="p-6">
        <div className="sm:px-6">
          Would you like to subscribe to lunch or dinner?
          <div className="flex flex-row flex-wrap justify-center items-center">
            <Button
              type="button"
              name="Lunch"
              color="primary"
              buttonClassName="mt-6"
              onClick={() => handleClick(1)}
            />
            <Button
              type="button"
              name="Dinner"
              color="primary"
              buttonClassName="mt-6 ml-4"
              onClick={() => handleClick(2)}
            />
          </div>
        </div>
      </ModalBody>
    </ModalWrapper>
  );
}
