import React from "react";
import { Link } from "react-router-dom";
import { path } from "../assets/copy/path";
import Container from "../components/layout/container";

function Page404() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <div className="flex flex-col justify-center items-center h-content">
        <h1>Page not found</h1>
        <p>Sorry, the page you are looking for could not be found</p>
        <Link to={path.HOME} className="link primary-link">
          Back to home
        </Link>
      </div>
    </Container>
  );
}

export default Page404;
