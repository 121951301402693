import React from "react";
import { useAuth } from "../../hook/useAuth";
import { useCart } from "../../hook/useCart";
import { useRouter } from "../../hook/useRouter";
import { path } from "../../assets/copy/path";
import Container from "../../components/layout/container";
import Button from "../../components/elements/button";
import SelectAllMenu from "../../components/elements/dropdown-menu/select-all-menu";
import SubscribeMenuPax from "../../components/elements/accordion/subscibe-menu-pax";
import { alertModal } from "../../components/elements/modal/alert";
import { Post } from "../../services";

export default function SubscriptionMenu() {
  const auth = useAuth();
  const cart = useCart();
  const { state, navigate } = useRouter();
  const [isLoading, setIsLoading] = React.useState(true);
  const [data, setData] = React.useState({});
  const [pax, setPax] = React.useState(1);
  const [paxMenu, setPaxMenu] = React.useState({
    "menu[0]": [], // Pax 1
    "menu[1]": [], // Pax 2
    "menu[2]": [], // Pax 3
    "menu[3]": [], // Pax 4
    "menu[4]": [], // Pax 5
    "menu[5]": [], // Pax 6
  });
  const [priceSave, setPriceSave] = React.useState("0.00");
  const [subtotal, setSubtotal] = React.useState("0.00");
  const [dateSelected, setDateSelected] = React.useState([]);
  const [selectedAll, setSelectedAll] = React.useState(""); // Select all cuisine type

  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (
      state?.data &&
      state?.planDay &&
      state?.daytime &&
      state?.subscriptionPlan
    ) {
      const sortedData = Object.entries(state.data)
        .sort(([date1], [date2]) => new Date(date1) - new Date(date2))
        .reduce((acc, [date, value]) => {
          acc[date] = value;
          return acc;
        }, {});
      setData(sortedData);
      setIsLoading(false);
    } else navigate(path.SUBSCRIBEPLAN);
  }, []);

  const handleSelectAll = (cuisine) => {
    setSelectedAll(cuisine);
    let newDateList = [];
    Object.entries(paxMenu).map(([key, value], i) => {
      if (i < pax) {
        const tempArr = [];
        let idx = 0;

        Object.entries(data).map(([date, menuList], j) => {
          let index = menuList.menu.findIndex(
            (obj) => obj.cuisine_id === cuisine
          );
          if (idx < state.planDay && index >= 0) {
            tempArr.push({
              index: j,
              id: menuList.menu[index].product_id,
              default: 0,
              name: menuList.menu[index].name,
              add_on: [],
              drinks: [],
              date: date,
            });
            newDateList = newDateList.concat(date);
            idx++;
          }
        });
        paxMenu[key] = tempArr;
      }
    });
    setDateSelected(newDateList);
    calculatePrice();
  };

  const handleAddPax = () => {
    // max 6 pax only
    if (pax < 6) setPax(pax + 1);
    setTimeout(() => calculatePrice(), 1000);
  };

  const handleRemovePax = (index) => {
    if (index === 1) {
      // delete pax 2
      const tempPax3 = paxMenu[`menu[2]`];
      const tempPax4 = paxMenu[`menu[3]`];
      const tempPax5 = paxMenu[`menu[4]`];
      const tempPax6 = paxMenu[`menu[5]`];

      paxMenu[`menu[1]`] = tempPax3;
      paxMenu[`menu[2]`] = tempPax4;
      paxMenu[`menu[3]`] = tempPax5;
      paxMenu[`menu[4]`] = tempPax6;
      paxMenu[`menu[5]`] = [];
    } else if (index === 2) {
      // delete pax 3
      const tempPax4 = paxMenu[`menu[3]`];
      const tempPax5 = paxMenu[`menu[4]`];
      const tempPax6 = paxMenu[`menu[5]`];

      paxMenu[`menu[2]`] = tempPax4;
      paxMenu[`menu[3]`] = tempPax5;
      paxMenu[`menu[4]`] = tempPax6;
      paxMenu[`menu[5]`] = [];
    } else if (index === 3) {
      // delete pax 4
      const tempPax5 = paxMenu[`menu[4]`];
      const tempPax6 = paxMenu[`menu[5]`];

      paxMenu[`menu[3]`] = tempPax5;
      paxMenu[`menu[4]`] = tempPax6;
      paxMenu[`menu[5]`] = [];
    } else if (index === 4) {
      // delete pax 5
      const tempPax6 = paxMenu[`menu[5]`];

      paxMenu[`menu[4]`] = tempPax6;
      paxMenu[`menu[5]`] = [];
    }
    if (pax > 1) setPax(pax - 1);
    calculatePrice();
  };

  const calculatePrice = () => {
    const body = {
      subscription_plan: state?.subscriptionPlan,
      pax_menu: paxMenu,
    };
    Post(`/price/calculate`, body)
      .then((response) => {
        setPriceSave(response.data.price_save);
        setSubtotal(response.data.sub_total);
      })
      .catch((error) => {
        console.error("Error: ", error);
        if (error.code_status === 401) {
          auth.logout();
          alertModal
            .fire({
              title: "Session Expired", // Title
              text: "Please login to continue", // Text
              icon: "error", // Icon
              confirmButtonText: "Ok", // confirmButtonText
            })
            .then((result) => {
              if (result.isConfirmed) {
                // Go to login page
                navigate(path.LOGIN, {
                  state: {
                    ...state,
                    redirectTo: {
                      pathname: path.SUBSCRIBEPLAN,
                    },
                  },
                });
              }
            });
        } else {
          alertModal.fire(
            "Error", // Title
            error.message, // Text
            "error", // Icon
            "Ok" // confirmButtonText
          );
        }
      });
  };

  const handleAddCart = () => {
    const { isValid, errMsg } = validateMenu();
    if (isValid) {
      const body = {
        subscription_plan: state?.subscriptionPlan,
        pax_menu: paxMenu,
      };

      cart.addCart(
        `${state?.planDay} Days Subscription Plan`,
        {
          type: "subscription",
          product_id: state?.subscriptionPlan,
          quantity: 1,
          data: body,
          daytime: state?.daytime === 1 ? "lunch" : "dinner",
        },
        setIsLoading,
        null,
        navigate
      );
    } else {
      alertModal.fire(
        "Error", // Title
        errMsg, // Text
        "error", // Icon
        "Ok" // confirmButtonText)
      );
    }
  };

  const validateMenu = () => {
    let isValid = true;
    let errMsg = "";
    Object.entries(paxMenu).map(([key, value], i) => {
      if (i < pax) {
        if (value.length < state.planDay) {
          isValid = false;
          errMsg = "Please complete the plan";
        }
      }
    });

    return { isValid, errMsg };
  };

  return (
    <Container loading={isLoading} seo={{ title: "FoodHeart | Subscribe" }}>
      <div className="py-10 sm:py-16 px-6 sm:px-12 overflow-hidden">
        <div className="w-full max-w-container1200 mx-auto relative flex flex-col">
          <h1 className="text-2xl sm:text-3xl font-bold text-center pb-6">
            {state?.planDay} Days Subscription Plan <br />(
            {state?.daytime === 1 ? "Lunch" : "Dinner"})
          </h1>
          {!isLoading &&
            (Object.entries(data).length > 0 ? (
              <div className="">
                <div className="flex flex-col-reverse sm:flex-row justify-between items-center">
                  <SelectAllMenu handleSelectAll={handleSelectAll} />

                  <div className="flex flex-row justify-end items-center mb-10 ml-10">
                    <div className="mr-4 text-right">
                      <span>&nbsp;</span>
                      <p className="text-xl font-semibold">
                        Sub Total: RM {subtotal}
                      </p>
                      <span className="text-sm font-medium text-grey-dark">
                        You have save RM {priceSave}
                      </span>
                    </div>
                    <Button
                      name="Add Pax"
                      type="button"
                      onClick={handleAddPax}
                      color="primary"
                      disabled={pax >= 6}
                    />
                  </div>
                </div>
                <p className="text-xl font-semibold mb-5">
                  Please choose your desired date
                </p>
                {/* Menu card */}
                {Object.entries(paxMenu).length > 0 &&
                  Object.entries(paxMenu).map(([key, value], i) => {
                    if (i < pax)
                      return (
                        <SubscribeMenuPax
                          key={key}
                          index={i}
                          value={value}
                          pax={pax}
                          paxMenu={paxMenu}
                          setPaxMenu={setPaxMenu}
                          data={data}
                          maxDay={state.planDay}
                          handleRemovePax={handleRemovePax}
                          dateSelected={dateSelected}
                          setDateSelected={setDateSelected}
                          calculatePrice={calculatePrice}
                          selectedAll={selectedAll}
                          setSelectedAll={setSelectedAll}
                        />
                      );
                  })}
                <div className="flex flex-row justify-center">
                  <Button
                    name="Back"
                    type="button"
                    onClick={() => navigate(path.SUBSCRIBEPLAN)}
                    color="secondary"
                    buttonClassName="mr-4"
                  />
                  <Button
                    name="Add To Cart"
                    type="button"
                    onClick={handleAddCart}
                    color="primary"
                  />
                </div>
              </div>
            ) : (
              <div>No content available</div>
            ))}
        </div>
      </div>
    </Container>
  );
}
