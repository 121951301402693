import { useEffect, useState } from "react";
import { useCart } from "../../../../hook/useCart";
import { MdDeleteOutline, MdAdd, MdRemove } from "react-icons/md";
import { format } from "date-fns";
import Images from "../../../../assets/images/images";

export default function CartItem({ data }) {
  const cart = useCart();
  const [quantity, setQuantity] = useState(data.quantity || 1);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    setQuantity(data.quantity);
  }, [data.quantity]);

  const handleClickUpdate = (newQuantity) => {
    setQuantity(newQuantity);
    cart.updateCart(data, newQuantity);
  };

  const handleClickDelete = async () => {
    setIsDeleting(true);
    await cart.deleteCart(data.id, setIsDeleting);
    // setIsDeleting(false);
  };

  return (
    <div
      className={`flex flex-row justify-between border-b border-solid border-grey py-3 ${
        isDeleting ? "hidden" : ""
      }`}
    >
      <div className="flex flex-row w-full relative">
        {data.message && (
          <div className="absolute inset-0 flex justify-center items-center font-bold opacity-100 z-10">
            {data.message}
          </div>
        )}
        <div
          className={`w-[95px] min-w-[95px] max-w-[95px] mr-3 aspect-square ${
            data.message ? "opacity-50" : ""
          }`}
        >
          <img
            className="w-full aspect-square object-cover object-center overflow-hidden rounded-md bg-grey-extra-light shadow-md"
            src={data.image || Images.logo}
            alt={data.name}
          />
        </div>
        <div
          className={`flex flex-col justify-between ${
            data.message ? "opacity-50" : ""
          }`}
        >
          <div>
            <span className="cart__item-title text-sm sm:text-base font-medium overflow-hidden text-ellipsis">
              {data.name}
            </span>
            {data.type === "menu" && (
              <>
                <span className="text-xs sm:text-sm">
                  {format(new Date(data.date), "dd MMM yyyy, E")}{" "}
                  {data.daytime === 1 ? "(Lunch)" : "(Dinner)"}
                </span>
                <br />
              </>
            )}
            {data.type === "subscription" && (
              <p className="text-3xs sm:text-xs mt-2">
                {data.daytime === 1 ? "Lunch" : "Dinner"}
              </p>
            )}
            <span className="text-xs sm:text-sm">RM {cart.convertPriceWithThousandSeparator(data.price)}</span>
          </div>
          {data.type !== "subscription" && (
            <div className="flex flex-row">
              <div className="flex items-center">
                <button
                  className="p-1 rounded-full shadow text-primary hover:[&>svg]:text-primary-dark hover:[&>svg]:scale-105 disabled:!opacity-60 disabled:pointer-events-none ease-in-out"
                  type="button"
                  onClick={() =>
                    quantity > 1 && handleClickUpdate(quantity - 1)
                  }
                  disabled={quantity <= 1}
                >
                  <MdRemove className="text-sm" />
                </button>
              </div>
              <span className="text-xs sm:text-sm font-medium p-1 w-10 text-center flex justify-center items-center">
                {quantity}
              </span>
              <div className="flex items-center">
                <button
                  className="p-1 rounded-full shadow text-primary hover:[&>svg]:text-primary-dark hover:[&>svg]:scale-105 disabled:!opacity-60 disabled:pointer-events-none ease-in-out"
                  type="button"
                  onClick={() => handleClickUpdate(quantity + 1)}
                >
                  <MdAdd className="text-sm" />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="flex items-center -mr-2">
        <button
          className={`p-2 rounded-full text-primary ease-in-out ${
            isDeleting ? "pointer-events-none opacity-50" : ""
          }`}
          onClick={() => handleClickDelete()}
          disabled={isDeleting}
        >
          <MdDeleteOutline className="text-2xl" />
        </button>
      </div>
    </div>
  );
}
