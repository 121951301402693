import React from "react";
import { FaChevronLeft, FaTimesCircle } from "react-icons/fa";
import { MdDeleteOutline, MdAdd, MdRemove } from "react-icons/md";
import { Link } from "react-router-dom";
import { path } from "../assets/copy/path";
import Button from "../components/elements/button";
import Container from "../components/layout/container";
import { useCart } from "../hook/useCart";
import { useRouter } from "../hook/useRouter";
import { format } from "date-fns";
import Images from "../assets/images/images";
import CartAddOnModal from "../components/elements/modal/cart-add-on";
import { alertModal } from "../components/elements/modal/alert";
import { useAuth } from "../hook/useAuth";
import { Get, PostUrlEncoding } from "../services";
import CartChangeAddressModal from "../components/elements/modal/cart-change-address";
import { CgChevronDown, CgChevronUp } from "react-icons/cg";
import EnterPinModal from "../components/elements/modal/enter-pin";

function AddOn({ data, label }) {
  return (
    <p className="text-3xs sm:text-xs">
      {label}:{" "}
      {data?.length > 0
        ? data.map((item, i) => (i === 0 ? `${item.name}` : `, ${item.name}`))
        : `-`}
    </p>
  );
}

function CartItem({ data, addressId }) {
  const cart = useCart();
  const [quantity, setQuantity] = React.useState(data.quantity || 1);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [openEditAddOnModal, setOpenEditAddOnModal] = React.useState(false);
  const [viewDetails, setViewDetails] = React.useState(false);

  React.useEffect(() => {
    setQuantity(data.quantity);
  }, [data.quantity]);

  const handleClickUpdate = (newQuantity) => {
    setQuantity(newQuantity);
    cart.updateCart(data, newQuantity, addressId);
  };

  const handleClickDelete = async () => {
    setIsDeleting(true);
    await cart.deleteCart(data.id, setIsDeleting, addressId);
  };

  const handleEditAddOn = () => {
    setOpenEditAddOnModal(true);
  };

  return (
    <>
      <tr className="align-top [&>td]:first:pt-4 [&>td]:last:pb-4 text-sm sm:text-base">
        <td className="text-left py-3 px-2 sm:px-4 flex flex-col sm:flex-row">
          <div
            className={`w-[80px] min-w-[80px] max-w-[80px] sm:w-[95px] sm:min-w-[95px] sm:max-w-[95px] mb-2 sm:mb-0 sm:mr-4 aspect-square ${
              data.message ? "opacity-50" : ""
            }`}
          >
            <img
              className="w-full aspect-square object-cover object-center overflow-hidden rounded-md bg-grey-extra-light shadow-md"
              src={data.image || Images.logo}
              alt={data.name}
            />
          </div>
          <div
            className={`flex flex-col justify-start ${
              data.message ? "opacity-50" : ""
            }`}
          >
            <p className="font-medium">{data.name}</p>
            {data.type === "menu" && (
              <>
                <p className="text-3xs sm:text-xs mt-2">
                  {format(new Date(data.date), "dd MMM yyyy, E")}{" "}
                  {data.daytime === 1 ? "(Lunch)" : "(Dinner)"}
                </p>
              </>
            )}
            {data.type === "subscription" && (
              <>
                <p className="text-3xs sm:text-xs mt-2">
                  {data.daytime === 1 ? "Lunch" : "Dinner"}
                </p>
                <button
                  className="text-3xs sm:text-xs font-bold text-primary text-left mt-2 flex flex-row items-center"
                  onClick={() => setViewDetails(!viewDetails)}
                >
                  View Details{" "}
                  {viewDetails ? (
                    <CgChevronUp className="text-base" />
                  ) : (
                    <CgChevronDown className="text-base" />
                  )}
                </button>
                {/* {viewDetails
                  ? Object.values(data.item).map((val, i) => {
                      return (
                        <div key={i}>
                          <p className="text-3xs sm:text-xs mt-4">
                            Pax {i + 1}:
                          </p>
                          <ul>
                            {val.map((item, j) => (
                              <li className="list-disc ml-5">
                                <p className="text-3xs sm:text-xs mt-1">
                                  {format(
                                    new Date(item.menu.date),
                                    "dd MMM yyyy, E"
                                  )}
                                </p>
                                <p className="text-3xs sm:text-xs my-1">
                                  {item.menu.name}
                                </p>
                                <AddOn data={item.add_on} label="Add On" />
                                <AddOn data={item.drinks} label="Beverage" />
                              </li>
                            ))}
                          </ul>
                        </div>
                      );
                    })
                  : null} */}
              </>
            )}
            {data.type === "menu" && (
              <div className="mt-2">
                <AddOn data={data.add_on} label="Add On" />
                <AddOn data={data.drinks} label="Beverage" />
                <button
                  className="text-3xs sm:text-xs font-bold text-primary text-left mt-2"
                  onClick={() => handleEditAddOn()}
                  disabled={data.message}
                >
                  Add On & Beverage
                </button>
              </div>
            )}
            <p className="sm:hidden mt-2">
              RM {cart.convertPriceWithThousandSeparator(data.price)}
            </p>
          </div>
        </td>
        <td
          className={`text-center py-3 px-2 sm:px-4 hidden sm:table-cell ${
            data.message ? "opacity-50" : ""
          }`}
        >
          RM {cart.convertPriceWithThousandSeparator(data.price)}
        </td>
        <td
          className={`text-center py-3 px-2 sm:px-4 ${
            data.message ? "opacity-50" : ""
          }`}
        >
          {data.type === "subscription" ? (
            <span className="text-xs sm:text-sm font-medium">
              {data.quantity}
            </span>
          ) : (
            <div className="flex flex-row">
              <div className="flex items-center">
                <button
                  className="p-1 rounded-full shadow text-primary hover:[&>svg]:text-primary-dark hover:[&>svg]:scale-105 disabled:!opacity-60 disabled:pointer-events-none ease-in-out"
                  type="button"
                  onClick={() =>
                    quantity > 1 && handleClickUpdate(quantity - 1)
                  }
                  disabled={data.message || quantity <= 1}
                >
                  <MdRemove className="text-sm" />
                </button>
              </div>
              <span className="text-xs sm:text-sm font-medium p-1 w-10 text-center flex justify-center items-center">
                {quantity}
              </span>
              <div className="flex items-center">
                <button
                  className="p-1 rounded-full shadow text-primary hover:[&>svg]:text-primary-dark hover:[&>svg]:scale-105 disabled:!opacity-60 disabled:pointer-events-none ease-in-out"
                  type="button"
                  onClick={() => handleClickUpdate(quantity + 1)}
                  disabled={data.message}
                >
                  <MdAdd className="text-sm" />
                </button>
              </div>
            </div>
          )}
        </td>
        <td
          className={`text-center py-3 px-2 sm:px-4 ${
            data.message ? "opacity-50" : ""
          }`}
        >
          RM {cart.convertPriceWithThousandSeparator(data.sub_total)}
        </td>
        <td className="text-center py-3 px-2 sm:px-4">
          <button
            className={`mt-[2px] mx-auto rounded-full text-primary ${
              isDeleting ? "pointer-events-none opacity-50" : ""
            }`}
            onClick={() => handleClickDelete()}
          >
            <MdDeleteOutline className="text-2xl" />
          </button>
        </td>
      </tr>
      {data.type === "subscription" && viewDetails ? (
        <tr>
          <td colSpan={4} className="text-left pb-3 px-2 sm:px-4">
            {Object.values(data.item).map((val, i) => {
              return (
                <div key={i} className="sm:ml-[111px]">
                  <p className="text-3xs sm:text-xs mt-4">Pax {i + 1}:</p>
                  <ul>
                    {val.map((item, j) => (
                      <li className="list-disc ml-5">
                        <p className="text-3xs sm:text-xs mt-1">
                          {format(new Date(item.menu.date), "dd MMM yyyy, E")}
                        </p>
                        <p className="text-3xs sm:text-xs my-1">
                          {item.menu.name}
                        </p>
                        <AddOn data={item.add_on} label="Add On" />
                        <AddOn data={item.drinks} label="Beverage" />
                      </li>
                    ))}
                  </ul>
                </div>
              );
            })}
          </td>
        </tr>
      ) : null}
      <CartAddOnModal
        open={openEditAddOnModal}
        setOpen={setOpenEditAddOnModal}
        data={data}
        addressId={addressId}
      />
    </>
  );
}

export default function Cart() {
  const auth = useAuth();
  const cart = useCart();
  const { location, navigate, state } = useRouter();
  const [isLoading, setIsLoading] = React.useState(true);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [canProceed, setCanProceed] = React.useState(false);
  const [addressList, setAddressList] = React.useState([]);
  const [selectedAddress, setSelectedAddress] = React.useState(null);
  const [voucherCode, setVoucherCode] = React.useState(null);
  const [chefRemark, setChefRemark] = React.useState(null);
  const [riderRemark, setRiderRemark] = React.useState(null);
  const [profileData, setProfileData] = React.useState({});
  const [balance, setBalance] = React.useState(0);
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    React.useState(null);
  const [openAddressModal, setOpenAddressModal] = React.useState(false);
  const [openEnterPinModal, setOpenEnterPinModal] = React.useState(false);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    cart.resetVoucher();
    getAddress();
  }, []);

  const getAddress = () => {
    let hasAddress = false;
    let address = {};
    Get(`/user/profile`)
      .then((response) => {
        setBalance(Number(response.data.wallets?.sum || 0));
        setProfileData(response.data);
        if (response.data.addresses.length > 0) {
          setAddressList(response.data.addresses);
          response.data.addresses.map((item) => {
            if (item.default === 1) {
              hasAddress = true;
              address = item;
            }
          });
          if (!hasAddress) {
            address = response.data.addresses[0];
          }
        }
        setSelectedAddress(address);
        getCartData(address.id);
      })
      .catch((error) => {
        console.error("Error: ", error);
        if (error.code_status === 401) {
          auth.logout();
          alertModal
            .fire({
              title: "Session Expired", // Title
              text: "Please login to continue", // Text
              icon: "error", // Icon
              confirmButtonText: "Ok", // confirmButtonText
            })
            .then((result) => {
              if (result.isConfirmed) {
                // Go to login page
                navigate(path.LOGIN, {
                  state: {
                    ...state,
                    redirectTo: location,
                  },
                });
              }
            });
        } else {
          alertModal.fire(
            "Error", // Title
            error.message, // Text
            "error", // Icon
            "Ok" // confirmButtonText
          );
        }
      });
  };

  React.useEffect(() => {
    if (cart.cartList.length > 0) {
      let isNotExpired = true;
      cart.cartList.map((item) => {
        if (item.message) isNotExpired = false;
      });
      setCanProceed(
        isNotExpired &&
          cart.grandTotalPrice > 0 &&
          selectedAddress &&
          Object.values(selectedAddress).length > 0 &&
          selectedPaymentMethod
      );
    }
  }, [cart.cartList, selectedAddress, selectedPaymentMethod]);

  const getCartData = async (addressId) => {
    await cart.getCart(addressId);
    setIsLoading(false);
  };

  const handleApplyVoucher = () => {
    cart.applyVoucher(
      {
        code: voucherCode,
      },
      handleRemoveVoucher
    );
  };

  const handleRemoveVoucher = () => {
    setVoucherCode("");
    cart.resetVoucher();
  };

  const handleEditAddress = () => {
    setOpenAddressModal(true);
  };

  const handlePaymentMethod = (type) => {
    setSelectedPaymentMethod(type);
  };

  const handleCheckout = () => {
    setIsSubmitting(true);
    if (selectedPaymentMethod === "payment_gateway") {
      handlePayment();
    } else if (selectedPaymentMethod === "ewallet") {
      setOpenEnterPinModal(true);
    }
  };

  const handleCancelCheckout = () => {
    setIsSubmitting(false);
  };

  const handleCheckoutWallet = () => {
    setOpenEnterPinModal(false);
    handlePayment();
  };

  const handlePayment = () => {
    setIsLoading(true);
    PostUrlEncoding("/payment/checkout", {
      address_id: selectedAddress.id,
      code: voucherCode,
      payment_type: selectedPaymentMethod,
      remarks: chefRemark,
      note_delivery: riderRemark,
    })
      .then((response) => {
        setIsSubmitting(false);
        setIsLoading(false);
        cart.removeCart();
        if (selectedPaymentMethod === "payment_gateway") {
          window.location.href = `${process.env.REACT_APP_API_URL}/payment/gateway/checkout?trans_id=${response.data.trans_id}&user_id=${profileData.id}`;
        } else if (selectedPaymentMethod === "ewallet") {
          alertModal
            .fire({
              title: "Success", // Title
              text: response.message, // Text
              icon: "success", // Icon
              confirmButtonText: "Ok", // confirmButtonText
            })
            .then((result) => {
              if (result.isConfirmed) {
                // Go to login page
                navigate(path.HOME);
              }
            });
        }
      })
      .catch((error) => {
        console.error("Error: ", error);
        if (error.code_status === 400) {
          alertModal
            .fire({
              title: "Error", // Title
              text: error.message, // Text
              icon: "error", // Icon
              confirmButtonText: "Ok", // confirmButtonText
            })
            .then((result) => {
              if (result.isConfirmed) {
                // Go to login page
                navigate(path.EWALLETTOPUP);
              }
            });
        } else if (error.code_status === 401) {
          auth.logout();
          alertModal
            .fire({
              title: "Session Expired", // Title
              text: "Please login to continue", // Text
              icon: "error", // Icon
              confirmButtonText: "Ok", // confirmButtonText
            })
            .then((result) => {
              if (result.isConfirmed) {
                // Go to login page
                navigate(path.LOGIN, {
                  state: {
                    ...state,
                    redirectTo: location,
                  },
                });
              }
            });
        } else {
          alertModal.fire(
            "Error", // Title
            error.message, // Text
            "error", // Icon
            "Ok" // confirmButtonText
          );
        }
        setIsSubmitting(false);
        setIsLoading(false);
      });
  };

  return (
    <Container loading={isLoading} seo={{ title: "FoodHeart | Order Summary" }}>
      <div className="py-10 sm:py-16 px-6 sm:px-12 overflow-hidden">
        <div className="w-full max-w-container1200 mx-auto relative flex flex-col">
          <h1 className="text-2xl sm:text-3xl font-bold text-center">
            Order Summary
          </h1>
          <div className="flex flex-col lg:flex-row lg:-mx-5">
            <div className="w-full lg:w-4/6 lg:mx-5 mt-6">
              <table className="w-full">
                <thead>
                  <tr className="align-top bg-primary text-sm sm:text-base text-white">
                    <th className="w-2/5 sm:w-5/12 text-left px-2 py-4 sm:px-4 rounded-l-3xl overflow-hidden">
                      Item{" "}
                    </th>
                    <th className="w-0 sm:w-2/12 text-center p-0 py-4 sm:px-4 hidden sm:table-cell">
                      Price
                    </th>
                    <th className="w-1/5 sm:w-2/12 text-center px-2 py-4 sm:px-4">
                      Quantity
                    </th>
                    <th className="w-1/5 sm:w-2/12 text-center px-2 py-4 sm:px-4">
                      Subtotal
                    </th>
                    <th className="w-1/5 sm:w-1/12 text-center px-2 py-4 sm:px-4 rounded-r-3xl overflow-hidden"></th>
                  </tr>
                </thead>
                <tbody>
                  {cart.cartList.length > 0 ? (
                    cart.cartList.map((item, i) => {
                      return (
                        <CartItem
                          key={i}
                          data={item}
                          addressId={selectedAddress?.id}
                        />
                      );
                    })
                  ) : (
                    <tr className="align-top">
                      <td className="text-center py-2 px-4" colSpan={4}>
                        Your cart is currently empty
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="hidden lg:flex">
                <Link
                  to={path.HOME}
                  className="flex flex-row items-center button text-sm sm:text-base font-bold !leading-[120%] text-primary bg-white border-2 border-solid border-primary mt-6 py-[10px] px-[22px] min-w-[120px] rounded-button capitalize shadow hover:shadow-md disabled:!opacity-60 disabled:pointer-events-none transition duration-300 ease-in-out"
                >
                  <FaChevronLeft className="text-xl mr-2" />
                  Continue Shopping
                </Link>
              </div>
            </div>
            <div className="w-full lg:w-2/6 shadow-md rounded-md border py-6 px-1 lg:mx-5 mt-6">
              <table className="w-full">
                <tbody>
                  <tr className="align-top">
                    <td className="w-1/5 py-2 px-4">Total:</td>
                    <td className="w-4/5 text-right py-2 px-4">
                      RM{" "}
                      {cart.convertPriceWithThousandSeparator(cart.totalPrice)}
                    </td>
                  </tr>
                  <tr className="align-top">
                    <td className="w-1/5 py-2 px-4">Voucher:</td>
                    <td className="w-4/5 text-right py-2 px-4">
                      {cart.voucherAmount ? "- " : ""}
                      {cart.voucherType === 1 ? "RM" : ""}{" "}
                      {cart.voucherAmount
                        ? cart.voucherType === 1
                          ? cart.convertPriceWithThousandSeparator(
                              cart.voucherAmount
                            )
                          : cart.voucherAmount
                        : "-"}
                      {cart.voucherType === 2 ? "%" : ""}
                    </td>
                  </tr>
                  <tr className="align-top">
                    <td
                      className="w-1/5 sm:w-3/5 text-right py-2 px-4"
                      colSpan={2}
                    >
                      <div className="relative flex flex-row justify-end">
                        <div className="w-full relative mr-2">
                          <input
                            id="voucher"
                            name="voucher"
                            className={`w-4/6 sm:w-auto lg:w-full border border-solid border-grey rounded-lg py-3 pl-3 ${
                              voucherCode ? "pr-8" : "pr-3"
                            } outline-0 text-ellipsis`}
                            onChange={(e) => setVoucherCode(e.target.value)}
                            value={voucherCode}
                            placeholder="Enter voucher code"
                            autoComplete="off"
                            disabled={isSubmitting}
                          />
                          {voucherCode ? (
                            <FaTimesCircle
                              className="text-base text-grey absolute top-0 right-2 h-full flex items-center hover:text-black-primary"
                              onClick={handleRemoveVoucher}
                            />
                          ) : null}
                        </div>
                        <Button
                          type="button"
                          name="Apply"
                          color="primary"
                          buttonClassName="w-2/6 sm:w-auto !min-w-0 flex justify-center items-center"
                          disabled={!voucherCode}
                          onClick={() => handleApplyVoucher()}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr className="align-top">
                    <td className="w-1/5 py-2 px-4">Ship To:</td>
                    <td className="w-4/5 text-right py-2 px-4">
                      {selectedAddress &&
                      Object.values(selectedAddress).length > 0 ? (
                        <div>
                          <p>{selectedAddress.address_1}</p>
                          {selectedAddress.address_2 ? (
                            <p>{selectedAddress.address_2}</p>
                          ) : null}
                          <p>
                            {selectedAddress.zip_code} {selectedAddress.city}
                          </p>
                          <p>
                            {selectedAddress.state}, {selectedAddress.country}
                          </p>
                        </div>
                      ) : (
                        <div>
                          <p>-</p>
                        </div>
                      )}
                      <button
                        className="text-sm sm:text-base text-primary text-right underline"
                        onClick={handleEditAddress}
                      >
                        {selectedAddress &&
                        Object.values(selectedAddress).length > 0
                          ? "Change Address"
                          : "Add Address"}
                      </button>
                    </td>
                  </tr>
                  <tr className="align-top">
                    <td className="w-1/5 py-2 px-4">Shipping:</td>
                    <td className="w-4/5 text-right py-2 px-4">
                      RM {cart.convertPriceWithThousandSeparator(cart.fees)}
                    </td>
                  </tr>
                  <tr className="align-top">
                    <td className="py-2 px-4" colSpan={2}>
                      <div className="flex flex-col">
                        Note to Chef:
                        <textarea
                          className="mt-2 border border-solid border-grey rounded-lg p-3 outline-0"
                          onChange={(e) => setChefRemark(e.target.value)}
                          value={chefRemark}
                          placeholder="eg: No spicy"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr className="align-top">
                    <td className="py-2 px-4" colSpan={2}>
                      <div className="flex flex-col">
                        Note to Rider:
                        <textarea
                          className="mt-2 border border-solid border-grey rounded-lg p-3 outline-0"
                          onChange={(e) => setRiderRemark(e.target.value)}
                          value={riderRemark}
                          placeholder="eg: Please ring door bell"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr className="align-top">
                    <td className="py-2 px-4" colSpan={2}>
                      <div className="flex flex-col">
                        Payment Method:
                        <div className="w-full flex flex-col gap-2 mt-2">
                          <Radio
                            name="paymentGateway"
                            id="ewallet"
                            label={
                              <>
                                e-Wallet (RM&nbsp;
                                {cart.convertPriceWithThousandSeparator(
                                  balance
                                )}
                                )
                              </>
                            }
                            value="ewallet"
                            onChange={() => handlePaymentMethod("ewallet")}
                            disabled={
                              balance === 0 || balance < cart.grandTotalPrice
                            }

                            // Sorry, you do not have enough balance in your e-Wallet for this payment. Please choose another payment method or top up your e-Wallet to continue.
                          />
                          <Radio
                            name="paymentGateway"
                            id="payment_gateway"
                            label="Credit Card"
                            value="payment_gateway"
                            onChange={() =>
                              handlePaymentMethod("payment_gateway")
                            }
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr className="text-lg font-bold">
                    <td className="w-1/5 py-4 px-4 whitespace-nowrap">
                      Grand Total:
                    </td>
                    <td className="w-4/5 text-right py-4 px-4">
                      RM{" "}
                      {cart.convertPriceWithThousandSeparator(
                        cart.grandTotalPrice
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="mt-6  py-2 px-4">
                <Button
                  type="button"
                  name="Proceed to Checkout"
                  color="primary"
                  buttonClassName="w-full"
                  disabled={!canProceed || isSubmitting}
                  onClick={handleCheckout}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <CartChangeAddressModal
        open={openAddressModal}
        setOpen={setOpenAddressModal}
        addressList={addressList}
        selectedAddress={selectedAddress}
        setSelectedAddress={setSelectedAddress}
      />
      <EnterPinModal
        open={openEnterPinModal}
        setOpen={setOpenEnterPinModal}
        handleCheckoutWallet={handleCheckoutWallet}
        handleCancelCheckout={handleCancelCheckout}
      />
    </Container>
  );
}

const Radio = ({ name, id, label, value, onChange, disabled }) => {
  return (
    <div className="inline-flex items-center">
      <label
        className={`relative flex items-center rounded-full px-3 ${
          disabled ? "cursor-default" : "cursor-pointer"
        }`}
        htmlFor={id}
        data-ripple-dark="true"
      >
        <input
          id={id}
          value={value}
          onChange={onChange}
          name={name}
          type="radio"
          className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-grey transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-primary before:opacity-0 before:transition-opacity checked:border-primary checked:before:bg-primary disabled:cursor-default disabled:opacity-50"
          disabled={disabled}
        />
        <div className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-primary opacity-0 transition-opacity peer-checked:opacity-100">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-3.5 w-3.5"
            viewBox="0 0 16 16"
            fill="currentColor"
          >
            <circle data-name="ellipse" cx="8" cy="8" r="8"></circle>
          </svg>
        </div>
      </label>
      <label
        className={`mt-px select-none ${
          disabled ? "cursor-default" : "cursor-pointer"
        }`}
        htmlFor={id}
      >
        <span className={disabled ? "opacity-50" : "opacity-100"}>{label}</span>{" "}
      </label>
      {disabled && (
        <Link
          to={path.EWALLETTOPUP}
          className="py-1 px-4 bg-primary rounded-3xl text-3xs sm:text-xs font-bold text-center text-white shadow ml-2"
        >
          Top Up Now
        </Link>
      )}
    </div>
  );
};
