export const page = {
  HOME: "Home",
  HOMECOOKED: "Homecooked",
  CATERING: "Catering",
  DAILYMEAL: "Daily Meal",
  LITTLECOOK: "Little Cook",
  MENU: "Menu",
  SUBSCRIBEPLAN: "Subscription Plan",
  SUBSCRIBEMENU: "",

  PROFILE: "Profile",
  EWALLET: "e-Wallet",
  EWALLETTOPUP: "Top Up",
  EWALLETVERIFY: "Verification",
  EWALLETFORGOTPIN: "",
  ORDERHISTORY: "Order History",
  TRANSACTIONHISTORY: "Transaction History",
  PAYMENTSTATUS: "",

  CART: "Order Summary",
  CHECKOUT: "Check out",

  ABOUTUS: "About Us",
  CAREER: "Career",
  CONTACT: "Contact Us",
  FAQ: "FAQ",
  TERMS: "Terms and Conditions",
  PRIVACY: "Privacy Policy",
  DELIVERYPOLICY: "Delivery Policy",
  REFUNDPOLICY: "Refund Policy",

  // Auth
  LOGIN: "Login",
  REGISTER: "Register",
  FORGOTPW: "Forgot password",
  RESETPW: "Reset password",
  VERIFYEMAIL: "Verify email",
  // NOTVERIFY: "Check your email",
  LOGOUT: "Logout",
};
