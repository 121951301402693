import React, { useEffect, useState } from "react";
import Container from "../../components/layout/container";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { PostFormData } from "../../services";
import { alertModal } from "../../components/elements/modal/alert";
import TextField from "../../components/elements/form/text-field";
import Button from "../../components/elements/button";

const initialValues = {
  email: "",
  phone: "",
};

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .email("Email is invalid")
    .max(255, "Email must be less than 255 characters")
    .required("Email is required"),
  phone: yup
    .number("Phone number is invalid")
    .typeError("Phone number is invalid")
    .integer("Phone number is invalid")
    .required("Phone Number is required"),
});

export default function Catering() {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onSubmit = (values, actions) => {
    actions.setSubmitting(true);
    const formData = new FormData();
    formData.append("email", values.email);
    formData.append("phone_no", values.phone);

    PostFormData(`/catering/news`, formData)
      .then((response) => {
        alertModal.fire("Success", response.message, "success", "Ok");
        actions.resetForm();
        actions.setSubmitting(false);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error: ", error);
        if (error.code_status === 422) {
          actions.setErrors({
            email: error.data.email,
            phone: error.data.phone_no,
          });
        } else {
          alertModal.fire(
            "Error", // Title
            error.message, // Text
            "error", // Icon
            "Ok" // confirmButtonText
          );
        }
        actions.setSubmitting(false);
        setIsLoading(false);
      });
  };

  return (
    <Container loading={isLoading} seo={{ title: "Little Cook" }}>
      <div className="py-10 sm:py-16 px-6 sm:px-12 overflow-hidden">
        <div className="w-full max-w-container600 mx-auto relative flex flex-col">
          <h2 className="text-xl sm:text-2xl font-bold text-black-primary text-center uppercase mb-5">
            Catering Enquiries
          </h2>
          <p className="text-center">
            Register your interest with us, we will contact you as soon as
            possible.
          </p>
          <div className="w-full mt-8">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ isSubmitting, handleChange, dirty, isValid }) => (
                <Form>
                  <div className="mb-6">
                    <TextField
                      label="Email"
                      name="email"
                      type="email"
                      placeholder=""
                      autoComplete="off"
                    />
                  </div>
                  <div className="mb-6">
                    <TextField
                      label="Phone Number"
                      name="phone"
                      type="tel"
                      placeholder="0123456789"
                      autoComplete="off"
                      isNumberField={true}
                    />
                  </div>
                  <div className="mt-10 flex justify-center">
                    <Button
                      type="submit"
                      name="Submit"
                      color="primary"
                      disabled={!(dirty && isValid) || isSubmitting}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Container>
  );
}
