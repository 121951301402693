export const sectionId = {
  homecooked: "homecooked",
  dailymeal: "dailymeal",
  littlecook: "littlecook",
};

export const path = {
  HOME: "/",
  HOMECOOKED: `/#${sectionId.homecooked}`,
  CATERING: "/catering",
  DAILYMEAL: "/daily-meal",
  LITTLECOOK: `/littlecook`, // `/#${sectionId.littlecook}`,
  MENU: `/menu`,
  SUBSCRIBEPLAN: `/subscribe/plan`,
  SUBSCRIBEMENU: `/subscribe/menu`,

  PROFILE: "/profile",
  EWALLET: "/ewallet",
  EWALLETTOPUP: "/ewallet/top-up",
  EWALLETVERIFY: "/ewallet/verification",
  EWALLETFORGOTPIN: "/ewallet/forgot-pin",
  ORDERHISTORY: "/order-history",
  ORDERDETAILS: "/order-details",
  EDITORDER: "/edit-order",
  TRANSACTIONHISTORY: "/transaction-history",
  PAYMENTSTATUS: "/payment",

  CART: `/cart`,
  CHECKOUT: "/checkout",

  ABOUTUS: "/about-us",
  CAREER: "/career",
  // CONTACT: "",
  FAQ: "/faq",
  TERMS: "/terms",
  PRIVACY: "/privacy",
  DELIVERYPOLICY: "/delivery-policy",
  REFUNDPOLICY: "/refund-policy",

  // Auth
  LOGIN: "/login",
  REGISTER: "/register",
  FORGOTPW: "/forgot-password",
  RESETPW: "/reset-password",
  VERIFYEMAIL: "/verify",
  // NOTVERIFY: "/not-verify",
  // LOGOUT: ""
};
