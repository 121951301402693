import { FaFacebook, FaInstagram, FaWhatsapp } from "react-icons/fa";
import { MdLocationPin, MdOutlineMail } from "react-icons/md";
import { companyInfo } from "../../assets/copy/companyInfo";
import { page } from "../../assets/copy/page";
import { path } from "../../assets/copy/path";

export default function Footer() {
  return (
    <footer className="bg-primary-extra-light w-full max-w-full overflow-hidden">
      <div className="p-6 sm:px-12">
        <div className="w-full max-w-container1600 mx-auto text-sm sm:text-base text-black-primary flex flex-col md:flex-row justify-between">
          <div className="mb-6 -mx-3 w-full md:max-w-[30%] lg:max-w-[50%] xl:max-w-[55%]">
            <div className="max-w-container400">
              <img src={companyInfo.logo} className="" alt={companyInfo.name} />
            </div>
          </div>

          <div className="-mx-3 w-full md:max-w-[70%] lg:max-w-[50%] xl:max-w-[45%] flex flex-col sm:flex-row justify-end">
            <div className="px-3 w-full sm:max-w-[50%] flex flex-col">
              <div>
                <p className="font-bold py-4 uppercase">Navigation</p>
                <div className="pb-4">
                  <a href={path.HOME}>{page.HOME}</a>
                </div>

                {/* <div className="pb-4">
                  <a href={path.HOMECOOKED}>{page.HOMECOOKED}</a>
                </div>
                <div className="pb-4">
                  <a href={path.CATERING}>{page.CATERING}</a>
                </div> */}
                <div className="pb-4">
                  <a href={path.ABOUTUS}>{page.ABOUTUS}</a>
                </div>
                {/* <div className="pb-4">
                  <a href={path.CAREER}>{page.CAREER}</a>
                </div> */}

                {/* <div className="pb-4">
                  <a href={path.FAQ}>{page.FAQ}</a>
                </div> */}

                <div className="pb-4">
                  <a href={path.TERMS} className="">
                    {page.TERMS}
                  </a>
                </div>
                <div className="pb-4">
                  <a href={path.DELIVERYPOLICY}>{page.DELIVERYPOLICY}</a>
                </div>
                <div className="pb-4">
                  <a href={path.REFUNDPOLICY}>{page.REFUNDPOLICY}</a>
                </div>
                <div className="pb-4">
                  <a href={path.PRIVACY}>{page.PRIVACY}</a>
                </div>
              </div>
            </div>
            <div className="px-3 w-full sm:max-w-[50%] flex flex-col">
              <div>
                <p className="font-bold py-4 uppercase">{page.CONTACT}</p>
                <div className="flex pb-4">
                  <a
                    className="flex flex-row items-center"
                    href={companyInfo.waLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaWhatsapp className="text-2xl w-[24px]" />{" "}
                    <span className="pl-2">{companyInfo.wa}</span>
                  </a>
                </div>
                <div className="flex pb-4">
                  <a
                    className="flex flex-row items-center"
                    href={companyInfo.emailLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <MdOutlineMail className="text-2xl w-[24px]" />
                    <span className="pl-2">{companyInfo.email}</span>
                  </a>
                </div>
                <div className="flex pb-4">
                  <a
                    className="flex flex-row items-baseline"
                    href={companyInfo.wazeLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <MdLocationPin className="absolute text-2xl w-[24px]" />{" "}
                    <span className="ml-6 pl-2">
                      {companyInfo.address1}
                      <br />
                      {companyInfo.address2}
                      <br />
                      {companyInfo.poscode} {companyInfo.city}
                      <br />
                      {companyInfo.state}, {companyInfo.country}
                    </span>
                  </a>
                </div>

                <p className="font-bold py-4 uppercase">FOLLOW US</p>
                <div className="flex pb-4">
                  <a
                    className="flex flex-row items-center"
                    href={companyInfo.fbLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaFacebook className="text-2xl w-[24px]" />{" "}
                    <span className="pl-2">{companyInfo.fb}</span>
                  </a>
                </div>
                <div className="flex pb-4">
                  <a
                    className="flex flex-row items-center"
                    href={companyInfo.igLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaInstagram className="text-2xl w-[24px]" />{" "}
                    <span className="pl-2">{companyInfo.ig}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-3 px-6 sm:px-12">
        <div className="w-full max-w-container1600 mx-auto text-sm sm:text-base text-black-primary text-center">
          <div>
            &copy; {new Date().getFullYear()} {companyInfo.name}{" "}
            <br className="sm:hidden" />
            <span className="">
              (Company Registration No. {companyInfo.regNo})
            </span>
            .
            <br /> All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  );
}
