import React from "react";
import ProfileNav from "../../components/elements/profile-nav";
import Container from "../../components/layout/container";
import Button from "../../components/elements/button";
import { page } from "../../assets/copy/page";
import { path } from "../../assets/copy/path";
import { useCart } from "../../hook/useCart";
import { useAuth } from "../../hook/useAuth";
import { Get } from "../../services";
import { alertModal } from "../../components/elements/modal/alert";
import { useRouter } from "../../hook/useRouter";
import ForgotPinModal from "../../components/elements/modal/forgot-pin";
import ChangePinModal from "../../components/elements/modal/change-pin";

function Ewallet() {
  const auth = useAuth();
  const cart = useCart();
  const { location, navigate, state } = useRouter();
  const [isLoading, setIsLoading] = React.useState(true);
  const [profileData, setProfileData] = React.useState({});
  const [balance, setBalance] = React.useState(0);
  const [amount, setAmount] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [openChangePinModal, setOpenChangePinModal] = React.useState(false);
  const [openForgotPinModal, setOpenForgotPinModal] = React.useState(false);

  React.useEffect(() => {
    setIsLoading(true);
    Get(`/user/profile`)
      .then((response) => {
        setBalance(Number(response.data.wallets?.sum || 0));
        setProfileData(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error: ", error);
        if (error.code_status === 401) {
          auth.logout();
          alertModal
            .fire({
              title: "Session Expired", // Title
              text: "Please login to continue", // Text
              icon: "error", // Icon
              confirmButtonText: "Ok", // confirmButtonText
            })
            .then((result) => {
              if (result.isConfirmed) {
                // Go to login page
                navigate(path.LOGIN, {
                  state: {
                    ...state,
                    redirectTo: location,
                  },
                });
              }
            });
        } else {
          alertModal.fire(
            "Error", // Title
            error.message, // Text
            "error", // Icon
            "Ok" // confirmButtonText
          );
        }
        setIsLoading(false);
      });
    if (state?.scrollTo) {
      const section = document.getElementById(state.scrollTo);
      section && scrollToSection(section);
    } else window.scrollTo(0, 0);

    if (state?.action === "Forgot pin") {
      setTimeout(() => {
        setOpenForgotPinModal(true);
        navigate(path.EWALLET, {
          state: "ewallet",
          replace: true,
        });
      }, 1000);
    }
  }, []);

  const scrollToSection = (section) => {
    const headerOffset = document.getElementById("header").offsetHeight;
    const sectionPosition = section.getBoundingClientRect().top;
    const offsetPosition = sectionPosition + window.pageYOffset - headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  const handleChangePin = () => {
    setOpenChangePinModal(true);
  };

  const handleForgotPin = () => {
    setOpenChangePinModal(false);
    setOpenForgotPinModal(true);
  };

  return (
    <Container loading={isLoading}>
      <div className="px-6 sm:px-12 overflow-hidden">
        <div className="py-5 sm:py-8 w-full max-w-container1200 mx-auto relative flex flex-col md:flex-row justify-center">
          <ProfileNav hideMobile={true} />
          <div
            className="py-5 sm:py-8 w-full md:w-3/5 flex flex-col"
            id="ewallet"
          >
            <h1 className="text-2xl sm:text-3xl font-bold">{page.EWALLET}</h1>
            <div className="w-full md:max-w-container500 mt-10">
              <div className="shadow-profile rounded-md">
                <div className="text-center py-6 px-4">
                  <p className="text-xs sm:text-sm">Available Balance</p>
                  <p className="text-xl sm:text-2xl font-semibold">
                    RM {cart.convertPriceWithThousandSeparator(balance)}
                  </p>
                </div>
              </div>
              <div className="mt-10">
                <Button
                  type="button"
                  name="Change PIN"
                  color="primary"
                  onClick={handleChangePin}
                />
              </div>
              <ChangePinModal
                open={openChangePinModal}
                setOpen={setOpenChangePinModal}
                handleForgotPin={handleForgotPin}
              />
              <ForgotPinModal
                open={openForgotPinModal}
                setOpen={setOpenForgotPinModal}
              />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default Ewallet;
