import React from "react";
import Container from "../../components/layout/container";
import { useRouter } from "../../hook/useRouter";
import Button from "../../components/elements/button";
import { path } from "../../assets/copy/path";

export default function PaymentStatus() {
  const { query } = useRouter();
  const { location, navigate } = useRouter();
  const [showSuccess, setShowSuccess] = React.useState(false);
  const [showFailed, setShowFailed] = React.useState(false);

  React.useEffect(() => {
    if (query.status) {
      setTimeout(() => navigate(path.EWALLETTOPUP), 5000);
      if (query.status == 1) {
        setShowSuccess(true);
      } else if (query.status == 0) {
        setShowFailed(true);
      }
    }
  }, [query]);
  return (
    <Container>
      <div className="py-10 sm:py-16 px-6 sm:px-12 overflow-hidden">
        <div className="w-full max-w-container600 m-auto p-10 relative flex flex-col justify-center items-center">
          {/* Success */}
          {showSuccess && (
            <>
              <div className="sa">
                <div className="sa-success">
                  <div className="sa-success-tip"></div>
                  <div className="sa-success-long"></div>
                  <div className="sa-success-placeholder"></div>
                  <div className="sa-success-fix"></div>
                </div>
              </div>
              <h2 className="text-xl sm:text-2xl font-bold text-black-primary text-center uppercase">
                Payment Success!
              </h2>
              <p className="mt-2 text-center">
                Your payment has been successfully processed and your e-wallet
                has been topped up. Your account balance has been updated and
                you can now use your e-wallet to make order.
              </p>
              <div className="mt-10">
                <Button
                  type="button"
                  name="OK"
                  color="primary"
                  buttonClassName=""
                  onClick={() => navigate(path.HOME)}
                />
              </div>
            </>
          )}

          {/* Failed */}
          {showFailed && (
            <>
              <div className="sa">
                <div className="sa-error">
                  <div className="sa-error-x">
                    <div className="sa-error-left"></div>
                    <div className="sa-error-right"></div>
                  </div>
                  <div className="sa-error-placeholder"></div>
                  <div className="sa-error-fix"></div>
                </div>
              </div>
              <h2 className="text-xl sm:text-2xl font-bold text-black-primary text-center uppercase">
                Payment Failed
              </h2>
              <p className="mt-2 text-center">
                Your e-wallet was not topped up due to an error in the payment
                process. Please try again later or contact our support team for
                assistance.
              </p>
              <div className="mt-10">
                <Button
                  type="button"
                  name="OK"
                  color="primary"
                  buttonClassName=""
                  onClick={() => navigate(path.CART)}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </Container>
  );
}
