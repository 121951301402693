import React from "react";
import { page } from "../assets/copy/page";
import Container from "../components/layout/container";

export default function DeliveryPolicy() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container seo={{ title: `FoodHeart | ${page.DELIVERYPOLICY}` }}>
      <div className="pt-10 sm:pt-16 pb-8 px-6 sm:px-12 overflow-hidden">
        <div className="w-full max-w-container1200 mx-auto relative flex flex-col">
          <div className="mb-8">
            <h1 className="text-2xl sm:text-3xl font-bold">
              {page.DELIVERYPOLICY}
            </h1>
          </div>
          <div>
            <div className="mb-8">
              <h4 className="text-xl font-bold underline mb-5">
                Daily Meal Delivery Service
              </h4>
              <div>
                <p className="font-bold mb-1">
                  Q: What is the delivery time-frame?
                </p>
                <p className="mb-5">
                  Lunch: 10am - 1:30pm
                  <br />
                  Dinner: 4pm - 7pm
                  <br />
                  Note: You may request us to deliver at specific time with an
                  additional charge of RM5/delivery.
                </p>

                <p className="font-bold mb-1">Q: Which area do you deliver?</p>
                <p className="mb-5">
                  We are covering most of the areas in Klang Valley. You may
                  check your delivery area before placing your order.
                </p>

                <p className="font-bold mb-1">
                  Q: How much is the deliver fee?
                </p>
                <p className="mb-5">
                  The delivery fee is based on your location (km) and the number
                  of meals ordered (pax).
                  <br />
                  This will be shown when you are placing your order, checking
                  out and entering your desired location for delivery.
                </p>

                <p className="font-bold mb-1">
                  Q: Can the delivery rider deliver to my doorstep?
                </p>
                <p className="mb-5">
                  Our delivery riders do not deliver to your doorstep if it is a
                  Residential Building or a Commercial Building, as there may be
                  many restrictions such as parking and building security
                  issues. You will receive a message when the rider is about 5
                  minutes away, so you need to only come down/out when the meals
                  are near. If you fail to collect the meal from the rider
                  within 2 minutes upon arrival of the rider, the rider will
                  then call you to finalize the meal drop-off area. The meals
                  dropped off at your requested area is at your own risk.
                  <br />
                  <br />
                  Do let us know if there is a need for us to deliver the meal
                  to your doorstep and your building is delivery friendly, you
                  may list it down on the “Note to Rider” when you check out or
                  contact us directly at{" "}
                  <a
                    href="tel:60126078702"
                    target="_blank"
                    className="underline"
                    rel="noreferrer"
                  >
                    +6012-6078702
                  </a>
                  .
                  <br />
                  We will also contact you for more details in order to arrange
                  it accordingly.
                </p>

                <p className="font-bold mb-1">
                  Q: Can leave the food at reception?
                </p>
                <p className="mb-5">
                  We will leave the food at reception and our delivery rider
                  will send a photo of the meal taken at your reception as proof
                  of delivery. The meals dropped off at your requested area is
                  at your own risk.
                </p>

                <p className="font-bold mb-1">Q: Can the rider wait for me?</p>
                <p className="mb-5">
                  Unfortunately we do not encourage our riders to wait for more
                  than 2 minutes as it could delay other deliveries. If you fail
                  to collect the meal from the rider within 2 minutes upon
                  arrival of the rider, the rider will then call you to finalize
                  the meal drop-off area. The meals dropped off at your
                  requested area is at your own risk.
                </p>

                <p className="font-bold mb-1">
                  Q: Can the delivery rider give me a call / text me upon
                  reaching?
                </p>
                <p className="mb-5">
                  Yes, for sure. Our delivery rider will text you upon reaching,
                  when he is about 5 minutes away.
                </p>

                <p className="font-bold mb-1">Q: How do I track my orders?</p>
                <p className="mb-5">
                  You may track your order by contacting our friendly customer
                  service team at{" "}
                  <a
                    href="tel:60126078702"
                    target="_blank"
                    className="underline"
                    rel="noreferrer"
                  >
                    +6012-6078702
                  </a>
                  .
                </p>
              </div>
            </div>

            <div className="mb-8">
              <h4 className="text-xl font-bold underline mb-5">
                Buffet Catering
              </h4>
              <div>
                <p className="font-bold mb-1">
                  Q: What is the delivery time-frame?
                </p>
                <p className="mb-5">
                  We will deliver your order according to the delivery time that
                  you have selected when you are placing your order.
                </p>

                <p className="font-bold mb-1">Q: Which area do you deliver?</p>
                <p className="mb-5">
                  We cover the whole Klang Valley. To be more specific, you may
                  contact our friendly customer service at{" "}
                  <a
                    href="tel:60126078702"
                    target="_blank"
                    className="underline"
                    rel="noreferrer"
                  >
                    +6012-6078702
                  </a>{" "}
                  to check your delivery location.
                </p>

                <p className="font-bold mb-1">
                  Q: How much is the deliver fee?
                </p>
                <p className="mb-5">
                  The delivery fee is based on your location (km). This will be
                  shown when you are placing your order, checking out and
                  entering your desired location for delivery.
                </p>

                <p className="font-bold mb-1">Q: How do I track my orders?</p>
                <p className="mb-5">
                  You may track your order by contacting our friendly customer
                  service team at{" "}
                  <a
                    href="tel:60126078702"
                    target="_blank"
                    className="underline"
                    rel="noreferrer"
                  >
                    +6012-6078702
                  </a>
                  .
                </p>

                <p className="font-bold mb-1">
                  Q: I need invoice / Receipt for my order.
                </p>
                <p className="mb-5">
                  An invoice/receipt of your order will be sent to your
                  registered email after your payment for your order has been
                  made successfully.
                  <br />
                  However, if you do not receive it in your registered email,
                  please contact us at{" "}
                  <a
                    href="mailto:support@foodheart.com.my"
                    target="_blank"
                    className="underline"
                    rel="noreferrer"
                  >
                    support@foodheart.com.my
                  </a>{" "}
                  or you may drop us a WhatsApp at{" "}
                  <a
                    href="https://api.whatsapp.com/send?phone=60126078702&text=Request+for+Invoice%2FReceipt"
                    target="_blank"
                    className="underline"
                    rel="noreferrer"
                  >
                    +6012-6078702
                  </a>
                  , with a title of “Request for Invoice/Receipt” and our team
                  will arrange for you accordingly.
                </p>
              </div>
            </div>

            <div className="mb-8">
              <h4 className="text-xl font-bold underline mb-5">
                Little Cook's Ready-to-Eat Food
              </h4>
              <div>
                <p className="font-bold mb-1">Q: What is the shipping rate?</p>
                <p className="mb-5">
                  The delivery fee is as follows:
                  <br />
                  (i) minimum order: 3 packets
                  <br />
                  (ii) <span className="font-bold">Klang Valley</span>
                  <br />
                  Order less than RM100 - RM19
                  <br />
                  Order less than RM149 - RM16
                  <br />
                  FREE DELIVERY for order RM150 and above
                  <br />
                  (iii) <span className="font-bold">Out of Klang Valley</span>
                  <br />
                  Order less than RM160 - RM19
                  <br />
                  Order less than RM249 - RM16
                  <br />
                  FREE DELIVERY for order RM250 and above
                  <br />
                </p>

                <p className="font-bold mb-1">Q: Which area do you deliver?</p>
                <p className="mb-5">
                  We currently cover the whole Peninsular Malaysia.
                </p>

                <p className="font-bold mb-1">
                  Q: Who is your courier partner?
                </p>
                <p className="mb-5">
                  We engaged with a few delivery & courier service providers,
                  such as Lalamove, Grab, LineClear and GDex.
                </p>

                <p className="font-bold mb-1">
                  Q: How many days does it take for my order to be delivered?
                </p>
                <p className="mb-5">
                  Your order will be delivered out within 1 - 3 working days
                  once order is made, excluding weekend and public holidays.
                </p>

                <p className="font-bold mb-1">
                  Q: Can I specify a time for collection?
                </p>
                <p className="mb-5">
                  Sorry, customers are not able to select the exact collection
                  time yet.
                </p>

                <p className="font-bold mb-1">Q: How do I track my orders?</p>
                <p className="mb-5">
                  You may track your order by contacting our friendly customer
                  service team at{" "}
                  <a
                    href="tel:60126078702"
                    target="_blank"
                    className="underline"
                    rel="noreferrer"
                  >
                    +6012-6078702
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
