import React, { useCallback, useEffect, useState } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
  getZipCode,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import { maps } from "../../assets/copy/maps";
import { MdMyLocation } from "react-icons/md";
import { Get, Post } from "../../services";
import { alertModal } from "./modal/alert";
import { CgSpinner } from "react-icons/cg";

const libraries = ["places"];

const options = {
  // styles: maps.style,
  disableDefaultUI: true,
  zoomControl: true,
  draggableCursor: "default",
};

export default function Maps() {
  const { isLoaded: isMapLoaded, loadError: mapLoadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries,
  });

  useEffect(() => {
    if (mapLoadError) {
      alertModal.fire(
        "Error", // Title
        "Unable to load map", // Text
        "error", // Icon
        "Ok" // confirmButtonText
      );
    }
  }, [mapLoadError]);

  return <>{isMapLoaded ? <Map /> : null}</>;
}

function Map() {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    // Define search scope here
    requestOptions: {
      componentRestrictions: {
        country: "my",
      },
      debounce: 100,
      cache: 24 * 60 * 60, // Provide the cache time in seconds, default is 24 hours
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const [map, setMap] = useState(null);
  const [zoom, setZoom] = useState(12);
  const [center, setCenter] = useState(maps.defaultPos);
  const [showMarker, setShowMarker] = useState(false);
  const [address, setAddress] = useState("");
  const [postcode, setPostcode] = useState("");
  // console.log(address, postcode);

  const onLoad = useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
    setZoom(12);
    setCenter(maps.defaultPos);
    setShowMarker(false);
  }, []);

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelectAddress = (address) => {
    setIsLoading(true);
    // When user selects a place, we can replace the keyword without request data from API by setting the second parameter to "false"
    // console.log("Address: ", address);
    setValue(address, false);
    setAddress(address);
    clearSuggestions();

    getGeocode({ address })
      .then((results) => {
        const { lat, lng } = getLatLng(results[0]);
        // console.log("Coordinates: ", { lat, lng });
        const zipCode = getZipCode(results[0], false); // By default we use the "long_name" value from API response, you can tell the utility to use "short_name" by setting the second parameter to "true"
        // console.log("ZipCode: ", zipCode);
        setPostcode(zipCode);
        checkArea(zipCode);
        setIsLoading(false);
        panTo({ lat, lng });
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  };

  const getUserLocation = useCallback((e) => {
    setIsLoading(true);
    if (navigator.geolocation)
      navigator.geolocation.getCurrentPosition(
        ({ coords: { latitude: lat, longitude: lng } }) => {
          const pos = { lat, lng };
          getAddressFromPos(pos);
          setIsLoading(false);
          panTo(pos);
        },
        (error) => {
          console.error("Get User Location: ", error);
          if (error.code === 1) {
            // User denied Geolocation
            alertModal.fire(
              "Permission is denied", // Title
              "We unable to get your current location, please enable location access to continue.", // Text
              "error", // Icon
              "Ok" // confirmButtonText
            );
            setIsLoading(false);
          } else if (error.code === 2) {
            console.log("Error: Position is unavailable!");
          } else if (error.code === 3) {
            console.log("Error: Attempt timed out!");
          } else {
            console.log("Error: Geolocation failed due to unknown error.");
          }
        },
        {
          enableHighAccuracy: true,
          maximumAge: 24 * 60 * 60,
        }
      );
    else {
      console.log("Sorry, your browser does not support HTML5 geolocation.");
    }
    // const bounds = new window.google.maps.LatLngBounds(center);
    // map.fitBounds(bounds);
  }, []);

  const onMapClick = useCallback((e) => {
    setIsLoading(true);
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    const pos = { lat, lng };
    getAddressFromPos(pos);
    setIsLoading(false);
    panTo(pos);
  }, []);

  const getAddressFromPos = ({ lat, lng }) => {
    let url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}&addressdetails=1`;
    Get(url)
      .then((response) => {
        setValue(response.display_name, false);
        // console.log("Coordinates: ", { lat, lng });
        // console.log("Address: ", response.display_name);
        // console.log("ZipCode: ", response.address.postcode);
        setAddress(response.display_name);
        setPostcode(response.address.postcode);
        checkArea(response.address.postcode);
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  };

  const panTo = useCallback((pos) => {
    setCenter(pos);
    setZoom(18);
    setShowMarker(true);
  }, []);

  const checkArea = (zipCode) => {
    Post(`/checkarea`, {
      zip_code: zipCode,
    })
      .then((response) => {
        if (response.code_status === 200) {
          alertModal.fire(
            "Congratulation!",
            response.data.message, // Text
            "success", // Icon
            "Ok" // confirmButtonText
          );
        }
      })
      .catch((error) => {
        if (error.code_status === 400) {
          alertModal.fire(
            "Ops!",
            error.message, // Text
            "error", // Icon
            "Ok" // confirmButtonText
          );
        }
      });
  };

  return (
    <div className="relative w-full drop-shadow-md rounded-md overflow-hidden">
      {/* Search bar */}
      <div className="absolute top-0 py-3 px-6 w-full flex justify-center z-20">
        <div className="map-search relative w-full">
          <Combobox onSelect={handleSelectAddress} aria-label="map">
            <ComboboxInput
              className="w-full border border-grey rounded-lg p-2 pl-3 sm:p-3 pr-12 outline-0 "
              value={value}
              onChange={handleInput}
              disabled={!ready}
              placeholder="Enter your full address"
            />
            <ComboboxPopover>
              <ComboboxList>
                {status === "OK" &&
                  data.map(({ id, description }) => (
                    <ComboboxOption key={id} value={description} />
                  ))}
              </ComboboxList>
            </ComboboxPopover>
          </Combobox>
          <button
            className="absolute top-0 right-0 p-3 h-full flex items-center background-none"
            onClick={getUserLocation}
          >
            <MdMyLocation className="text-2xl" />
          </button>
        </div>
      </div>
      <GoogleMap
        mapContainerClassName="w-full aspect-4/3 sm:aspect-video"
        center={center}
        zoom={zoom}
        onLoad={onLoad}
        onUnmount={onUnmount}
        onClick={onMapClick}
        options={options}
      >
        {/* Child components, such as markers, info windows, etc. */}
        {showMarker && <Marker position={center} />}
      </GoogleMap>

      {/* Loading Overlay */}
      {isLoading && (
        <div className="absolute inset-0 w-full h-full flex justify-center items-center bg-black opacity-50 z-30">
          <CgSpinner className="text-2xl text-white animate-spin" />
        </div>
      )}
    </div>
  );
}
