import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import * as yup from "yup";
import Container from "../../components/layout/container";
import TextField from "../../components/elements/form/text-field";
import Checkbox from "../../components/elements/form/checkbox";
import Button from "../../components/elements/button";
import { path } from "../../assets/copy/path";
import { useAuth } from "../../hook/useAuth";
import DateField from "../../components/elements/form/date-field";
import SelectField from "../../components/elements/form/select-field";
import { format } from "date-fns";
import { useRouter } from "../../hook/useRouter";

const initialValues = {
  username: "",
  email: "",
  password: "",
  passwordConfirmation: "",
  phone: "",
  firstName: "",
  lastName: "",
  dob: "",
  gender: "",
  acceptTerms: false,
};

const validationSchema = yup.object().shape({
  username: yup.string().required("Username is required"),
  email: yup
    .string()
    .trim()
    .email("Email is invalid")
    .max(255, "Email must be less than 255 characters")
    .required("Email is required"),
  password: yup
    .string()
    .min(
      8,
      "Password must be at least 8 characters and contain at least one lowercase letter, one uppercase letter, one number, and one special character."
    )
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[0-9]/, "Password must contain at least one number")
    .matches(
      /[!@#$%^&*]/,
      "Password must contain at least one special character (!@#$%^&*)"
    ),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "Password not match")
    .required("Confirm password is required"),
  phone: yup
    .number("Phone number is invalid")
    .typeError("Phone number is invalid")
    .integer("Phone number is invalid")
    .required("Phone Number is required"),
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  dob: yup
    .date("Date of Birth is invalid")
    .required("Date of Birth is required"),
  gender: yup.string().required("Gender is required"),
  acceptTerms: yup
    .bool()
    .oneOf(
      [true],
      "Please agree to the Terms & Conditions and Privacy Policy."
    ),
});

function Register() {
  const auth = useAuth();
  const { query } = useRouter();
  const [isLoading, setIsLoading] = React.useState(true);
  const [referralEmail, setReferralEmail] = React.useState(null);
  const [referralEncryptedEmail, setReferralEncryptedEmail] =
    React.useState(null);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    async function fetchData() {
      if (query?.email) {
        await auth.getEmail(
          query.email,
          setReferralEmail,
          "Invalid referral link. Please try again.",
          path.REGISTER
        );
        setReferralEncryptedEmail(query.email);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);

  const onSubmit = (values, actions) => {
    actions.setSubmitting(true);
    setIsLoading(true);
    const submit = async () => {
      const {
        username,
        email,
        password,
        passwordConfirmation,
        phone,
        firstName,
        lastName,
        dob,
        gender,
      } = values;

      auth.register(
        {
          username: username,
          email: email,
          password: password,
          password_confirmation: passwordConfirmation,
          phone_no: phone,
          first_name: firstName,
          last_name: lastName,
          dob: dob ? format(new Date(dob), "yyyy-MM-dd") : "",
          gender: gender,
          referral: referralEncryptedEmail,
        },
        actions,
        setIsLoading
      );
    };
    submit();
  };

  return (
    <Container loading={isLoading}>
      <div className="py-10 sm:py-16 px-6 sm:px-12 overflow-hidden">
        <div className="w-full max-w-container600 m-auto p-10 relative flex flex-col items-center bg-white rounded-3xl shadow-login">
          <h2 className="text-xl sm:text-2xl font-bold text-black-primary text-center uppercase">
            Register an account
          </h2>
          {referralEmail ? (
            <p className="mt-2 text-center">
              You have been referred by{" "}
              <span className="text-primary font-bold">{referralEmail}</span>
            </p>
          ) : null}
          <div className="w-full mt-8">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ isSubmitting, handleChange, dirty, isValid }) => (
                <Form>
                  <div className="flex flex-col sm:flex-row">
                    <div className="mb-6 w-full sm:w-1/2 sm:mr-2">
                      <TextField
                        label="First name"
                        name="firstName"
                        type="text"
                        placeholder=""
                        autoComplete="off"
                      />
                    </div>
                    <div className="mb-6 w-full sm:w-1/2 sm:ml-2">
                      <TextField
                        label="Last name"
                        name="lastName"
                        type="text"
                        placeholder=""
                        autoComplete="off"
                      />
                    </div>
                  </div>

                  <div className="flex flex-col sm:flex-row">
                    <div className="mb-6 w-full sm:w-1/2 sm:mr-2">
                      <DateField
                        label="Date of Birth"
                        name="dob"
                        placeholder=""
                      />
                    </div>
                    <div className="mb-6 w-full sm:w-1/2 sm:ml-2">
                      <SelectField
                        label="Gender"
                        name="gender"
                        options={[
                          {
                            value: "male",
                            label: "Male",
                          },
                          {
                            value: "female",
                            label: "Female",
                          },
                        ]}
                      />
                    </div>
                  </div>
                  <div className="mb-6">
                    <TextField
                      label="Phone Number"
                      name="phone"
                      type="tel"
                      placeholder="0123456789"
                      autoComplete="off"
                      isNumberField={true}
                    />
                  </div>
                  <div className="mb-6">
                    <TextField
                      label="Email"
                      name="email"
                      type="email"
                      placeholder=""
                      autoComplete="off"
                    />
                  </div>
                  <div className="mb-6">
                    <TextField
                      label="Username"
                      name="username"
                      type="text"
                      placeholder=""
                      autoComplete="off"
                    />
                  </div>
                  <div className="mb-6">
                    <TextField
                      label="Password"
                      name="password"
                      type="password"
                      placeholder=""
                      autoComplete="off"
                      isPasswordField={true}
                    />
                  </div>
                  <div className="mb-6">
                    <TextField
                      label="Confirm password"
                      name="passwordConfirmation"
                      type="password"
                      placeholder=""
                      autoComplete="off"
                      isPasswordField={true}
                    />
                  </div>
                  <div className="mb-6">
                    <Checkbox
                      name="acceptTerms"
                      label={
                        <>
                          I agree to the{" "}
                          <Link
                            className="underline"
                            to={path.TERMS}
                            target="_blank"
                          >
                            Terms & Conditions
                          </Link>{" "}
                          and{" "}
                          <Link
                            className="underline"
                            to={path.PRIVACY}
                            target="_blank"
                          >
                            Privacy Policy
                          </Link>
                          .
                        </>
                      }
                      handleChange={handleChange}
                    />
                  </div>
                  <div className="mt-10">
                    <Button
                      type="submit"
                      name="Register"
                      color="primary"
                      buttonClassName="w-full"
                      disabled={!(dirty && isValid) || isSubmitting}
                    />
                  </div>
                </Form>
              )}
            </Formik>
            <div className="text-sm sm:text-base text-center mt-6">
              Already have an account?&nbsp;
              <Link className="font-bold text-primary" to={path.LOGIN}>
                Login
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default Register;
