import React, { useContext, createContext, useState, useEffect } from "react";
import { path } from "../assets/copy/path";
import { alertModal } from "../components/elements/modal/alert";
import { Delete, Get, Post, Put } from "../services";
import { useAuth } from "./useAuth";
import useLocalStorage from "./useLocalStorage";
import { useRouter } from "./useRouter";

const cartContext = createContext();

// Provider component that wraps your app and makes cart object ...
// ... available to any child component that calls useCart().
export function ProvideCart({ children }) {
  const cart = useProvideCart();
  return <cartContext.Provider value={cart}>{children}</cartContext.Provider>;
}

// Hook for child components to get the cart object ...
// ... and re-render when it changes.
export const useCart = () => {
  return useContext(cartContext);
};

// Provider hook that creates cart object and handles state
function useProvideCart() {
  const auth = useAuth();
  const { location, navigate, state } = useRouter();

  const [cartList, setCartList] = useLocalStorage("cartList", ""); // useState([]);
  const [menuAdded, setMenuAdded] = useLocalStorage("menuAdded", 0); // useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [grandTotalPrice, setGrandTotalPrice] = React.useState(0);
  const [voucherAmount, setVoucherAmount] = useState(null);
  const [voucherType, setVoucherType] = useState(null);
  const [fees, setFees] = React.useState(0);

  const handleError = (error) => {
    console.error("Error: ", error);
    if (error.code_status === 401) {
      auth.logout();
      alertModal
        .fire({
          title: "Session Expired", // Title
          text: "Please login to continue", // Text
          icon: "error", // Icon
          confirmButtonText: "Ok", // confirmButtonText
        })
        .then((result) => {
          if (result.isConfirmed) {
            // Go to login page
            navigate(path.LOGIN, {
              state: {
                ...state,
                redirectTo: location,
              },
            });
          }
        });
    } else {
      alertModal.fire(
        "Error", // Title
        error.message, // Text
        "error", // Icon
        "Ok" // confirmButtonText
      );
    }
  };

  const getCart = (addressId) => {
    Post(`/user/cart/search`, {
      limit: 99999,
      page: 1,
      order_by: {
        created_at: "asc",
      },
      address_id: addressId,
    })
      .then((response) => {
        setCartList(response.data);
        setTotalPrice(Number(response.total_price.replace(",", "")));
        setFees(Number(response.fees));
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const addCart = (title, body, setIsSubmitting, toggle, navigate) => {
    Post(`/user/cart`, body)
      .then((response) => {
        // From Menu;
        if (body.type === "menu") setMenuAdded(menuAdded + 1);
        setCartList(response.data);
        setTotalPrice(Number(response.total_price.replace(",", "")));
        // setFees(Number(response.fees));
        getCart();
        alertModal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          .fire({
            title: `${title} has been successfully added to your cart`,
            icon: "success",
          });
        setIsSubmitting(false);
        if (toggle) toggle();
        if (navigate) navigate(path.HOME);
      })
      .catch((error) => {
        setIsSubmitting(false);
        handleError(error);
      });
  };

  const updateCart = (data, quantity, addressId, addOn, drinks) => {
    Put(`/user/cart/${data.id}`, {
      type: data.type,
      product_id: data.product_id,
      quantity: quantity,
      add_on: addOn,
      drinks: drinks,
    })
      .then(async (response) => {
        // setCartList(response.data);
        // setTotalPrice(Number(response.total_price.replace(",", "")));
        // setFees(Number(response.fees));
        await getCart(addressId);
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const deleteCart = (id, setIsDeleting, addressId) => {
    Delete(`/user/cart/${id}`)
      .then(async (response) => {
        // setCartList(response.data);
        // setTotalPrice(Number(response.total_price.replace(",", "")));
        // setFees(Number(response.fees));
        await getCart(addressId);
        setIsDeleting(false);
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const getAddOn = (setAddOnList, setDrinksList) => {
    Get(`/add_on`)
      .then((response) => {
        setAddOnList(response.data.add_on);
        setDrinksList(response.data.drinks);
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const applyVoucher = (body, handleRemoveVoucher) => {
    Post(`/voucher`, body)
      .then((response) => {
        setVoucherAmount(response.amount);
        setVoucherType(response.type);
      })
      .catch((error) => {
        handleError(error);
        handleRemoveVoucher();
      });
  };

  const resetVoucher = () => {
    setVoucherAmount(null);
    setVoucherType(null);
  };

  const removeCart = () => {
    setCartList("");
  };

  const calculateGrandTotalAmount = () => {
    let total = totalPrice;
    if (voucherType === 1) total -= voucherAmount;
    else if (voucherType === 2) total *= (100 - voucherAmount) / 100;
    total += fees;
    setGrandTotalPrice(parseFloat(total).toFixed(2));
  };

  const convertPriceWithThousandSeparator = (val) => {
    return (+val).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest cart object.
  useEffect(() => {
    if (cartList) setCartList(cartList);
    else setCartList("");
  }, []); // can't add dependency here will trigger re-render

  React.useEffect(() => {
    calculateGrandTotalAmount();
  }, [totalPrice, voucherAmount, voucherType, fees]);

  useEffect(() => {
    if (menuAdded >= 3)
      alertModal
        .fire({
          title: "Subscribe plan to save more", // Title
          text: "Subscribe plan to save more", // Text
          icon: "info", // Icon
          showCancelButton: true,
          confirmButtonText: "Subscribe", // confirmButtonText
          cancelButtonText: "Cancel",
        })
        .then((result) => {
          setMenuAdded(0);
          if (result.isConfirmed) {
            // Go to login page
            navigate(path.SUBSCRIBEPLAN);
          }
        });
  }, [menuAdded]);

  useEffect(() => {
    if (auth.user) {
      getCart();

      // const interval = setInterval(() => {
      //   getCart();
      // }, 10000);

      // return () => clearInterval(interval);
    } else setCartList("");
  }, [auth.user]);

  // Return the user object and cart methods
  return {
    cartList,
    totalPrice,
    voucherAmount,
    voucherType,
    fees,
    grandTotalPrice,
    getCart,
    addCart,
    updateCart,
    deleteCart,
    getAddOn,
    applyVoucher,
    removeCart,
    resetVoucher,
    convertPriceWithThousandSeparator,
  };
}
