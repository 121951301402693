import { format } from "date-fns";
import React from "react";
import { FaCheck } from "react-icons/fa";
import SubscribeMenu from "../dropdown-menu/subscribe-menu";
import Select from "react-select";
import { alertModal } from "../modal/alert";

export default function SubscribeMenuPaxCard({
  cardIndex,
  pax,
  paxIndex,
  paxMenu,
  data,
  selectedDay,
  setSelectedDay,
  maxDay,
  dateSelected,
  setDateSelected,

  date,
  menu,
  defaultMenu,
  addOn,
  drinks,
  calculatePrice,

  selectedAll,
  setSelectedAll,
}) {
  const [selected, setSelected] = React.useState(false);
  const [selectedMenu, setSelectedMenu] = React.useState(
    menu.length > 0 ? menu[0] : []
  );
  const [isDefaultMenu, setIsDefaultMenu] = React.useState(0);
  const [selectedAddOn, setSelectedAddOn] = React.useState([]);
  const [selectedAddOnIds, setSelectedAddOnIds] = React.useState([]);
  const [selectedDrinks, setSelectedDrinks] = React.useState([]);
  const [selectedDrinksIds, setSelectedDrinksIds] = React.useState([]);

  React.useEffect(() => {
    setSelected(false);
    setSelectedMenu(menu[0]);
    setIsDefaultMenu(0);
    setSelectedAddOn([]);
    setSelectedAddOnIds([]);
    setSelectedDrinks([]);
    setSelectedDrinksIds([]);

    let tempAddOnArr = [];
    let tempDrinksArr = [];
    Object.entries(paxMenu).map(([key, value], i) => {
      if (paxIndex === i) {
        paxMenu[`menu[${i}]`].length > 0 &&
          paxMenu[`menu[${i}]`].map((itemMenuList) => {
            if (itemMenuList.index === cardIndex) {
              if (itemMenuList.default) {
                defaultMenu.map((itemDefaultMenu) => {
                  if (itemMenuList.index === cardIndex) {
                    setSelected(true);
                    if (itemMenuList.id === itemDefaultMenu.product_id) {
                      setIsDefaultMenu(1);
                      setSelectedMenu(itemDefaultMenu);
                    }
                  }
                });
              } else {
                menu.map((itemMenu) => {
                  if (itemMenuList.index === cardIndex) {
                    setSelected(true);
                    if (itemMenuList.id === itemMenu.product_id)
                      setSelectedMenu(itemMenu);
                  }
                });
              }

              itemMenuList.add_on?.map((itemMenuListAddOn) => {
                addOn?.map((itemAddOn) => {
                  if (itemMenuListAddOn === itemAddOn.value)
                    tempAddOnArr.push(itemAddOn);
                });
              });

              itemMenuList.drinks?.map((itemMenuListDrinks) => {
                drinks?.map((itemDrinks) => {
                  if (itemMenuListDrinks === itemDrinks.value)
                    tempDrinksArr.push(itemDrinks);
                });
              });

              setSelectedAddOn(tempAddOnArr);
              setSelectedAddOnIds(itemMenuList.add_on);
              setSelectedDrinks(tempDrinksArr);
              setSelectedDrinksIds(itemMenuList.drinks);
            }
          });
      }
    });
  }, [...Object.values(paxMenu)]);

  const handleClickCard = () => {
    setSelectedAll("");
    if (selected) {
      // Remove
      let newDateList = [];
      if (paxIndex === 0) {
        newDateList = dateSelected.filter((item) => {
          return item !== date;
        });
        setDateSelected(newDateList);
      }

      setSelected(false);

      const newArr = paxMenu[`menu[${paxIndex}]`].filter((item) => {
        return item.index !== cardIndex;
      });
      paxMenu[`menu[${paxIndex}]`] = newArr;

      if (pax > 1 && paxIndex === 0 && newDateList.length > 0) {
        // When first pax remove a selected date menu, remove other pax selected date menu too
        Object.entries(paxMenu).map(([key, value], i) => {
          if (i !== 0 && i < pax) {
            const otherPaxMenuNewArr = paxMenu[`menu[${i}]`].filter((item) => {
              return newDateList.includes(item.date);
            });
            paxMenu[`menu[${i}]`] = otherPaxMenuNewArr;
          }
        });
      }
    } else {
      // Select
      let newDateList = [];

      if (selectedDay >= maxDay) {
        alertModal
          .mixin({
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 3000,
          })
          .fire({
            title: `You have selected ${selectedDay}/${maxDay} items`,
            icon: "error",
          });
      } else {
        if (paxIndex === 0) {
          // Set selected date based on first person only
          newDateList = dateSelected.concat(date);
          setDateSelected(newDateList);
        }
        setSelected(true);

        const newArr = paxMenu[`menu[${paxIndex}]`].filter((item) => {
          return item.index !== cardIndex;
        });
        paxMenu[`menu[${paxIndex}]`] = [
          ...newArr,
          {
            index: cardIndex,
            id: selectedMenu.product_id,
            name: selectedMenu.name,
            date: date,
            default: isDefaultMenu,
            add_on: selectedAddOnIds,
            drinks: selectedDrinksIds,
          },
        ];
      }
    }
    calculatePrice();
  };

  const disableChoose = paxIndex !== 0 && !dateSelected.includes(date);

  React.useEffect(() => {
    if (paxIndex !== 0) {
      if (disableChoose) {
        setSelected(false);
        setSelectedMenu(menu[0]);
        setIsDefaultMenu(0);
        setSelectedAddOn([]);
        setSelectedAddOnIds([]);
        setSelectedDrinks([]);
        setSelectedDrinksIds([]);
      } else {
        setSelected(true);
        const newArr = paxMenu[`menu[${paxIndex}]`].filter((item) => {
          return item.index !== cardIndex;
        });
        if (!selectedAll) {
          paxMenu[`menu[${paxIndex}]`] = [
            ...newArr,
            {
              index: cardIndex,
              id: selectedMenu.product_id,
              name: selectedMenu.name,
              date: date,
              default: isDefaultMenu,
              add_on: selectedAddOnIds,
              drinks: selectedDrinksIds,
            },
          ];
        } else {
          let index = menu.findIndex((obj) => obj.cuisine_id === selectedAll);

          paxMenu[`menu[${paxIndex}]`] = [
            ...newArr,
            {
              index: cardIndex,
              id: menu[index].product_id,
              name: menu[index].name,
              date: date,
              default: 0,
              add_on: [],
              drinks: [],
            },
          ];
        }
      }
    }
  }, [disableChoose]);

  React.useEffect(() => {
    setSelectedDay(paxMenu[`menu[${paxIndex}]`].length);
  }, [...Object.values(paxMenu), pax, selected, disableChoose]);

  return (
    <div
      className={`relative p-6 bg-white ${
        selected
          ? "border-4 border-primary p-[21px]"
          : "border-2 border-gray-200"
      } border-solid shadow-md rounded-xl cursor-pointer  ${
        disableChoose ? "opacity-50" : ""
      }`}
      onClick={() => {
        !disableChoose && handleClickCard();
      }}
    >
      <div
        className={`absolute top-0 right-0 border-l-[50px] border-r-[50px] border-b-[50px] border-solid border-l-transparent border-b-transparent ${
          selected
            ? "border-r-primary rounded-tr-md"
            : "border-r-gray-200 rounded-tr-lg"
        } `}
      >
        <FaCheck className="absolute top-2 left-7 text-white" />
      </div>
      <div className="flex flex-col w-full max-w-full">
        <img
          className="w-1/2 aspect-square object-cover object-center mb-4 mx-auto rounded-md overflow-hidden"
          src={selectedMenu.images}
          alt={selectedMenu.name}
        />
        <p className="text-sm sm:text-base font-bold mb-2 text-center">
          {format(new Date(date), "dd-MM-yyyy")}
        </p>
        <SubscribeMenu
          menu={menu}
          defaultMenu={defaultMenu}
          selectedMenu={selectedMenu}
          setSelectedMenu={setSelectedMenu}
          setIsDefaultMenu={setIsDefaultMenu}
          disabled={!selected}
          paxMenu={paxMenu}
          paxIndex={paxIndex}
          cardIndex={cardIndex}
          date={date}
          selectedAddOnIds={selectedAddOnIds}
          selectedDrinksIds={selectedDrinksIds}
          calculatePrice={calculatePrice}
        />
        <div className="flex flex-col w-full mb-4">
          <p className="mb-1">Add On:</p>
          <MultiSelect
            type="addOn"
            options={addOn}
            value={selectedAddOn}
            setValue={setSelectedAddOn}
            setIds={setSelectedAddOnIds}
            disabled={!selected}
            paxMenu={paxMenu}
            paxIndex={paxIndex}
            cardIndex={cardIndex}
            date={date}
            selectedMenu={selectedMenu}
            isDefaultMenu={isDefaultMenu}
            selectedAddOnIds={selectedAddOnIds}
            selectedDrinksIds={selectedDrinksIds}
            calculatePrice={calculatePrice}
          />
        </div>
        <div className="flex flex-col w-full">
          <p className="mb-1">Beverage:</p>
          <MultiSelect
            type="drinks"
            options={drinks}
            value={selectedDrinks}
            setValue={setSelectedDrinks}
            setIds={setSelectedDrinksIds}
            disabled={!selected}
            paxMenu={paxMenu}
            paxIndex={paxIndex}
            cardIndex={cardIndex}
            date={date}
            selectedMenu={selectedMenu}
            isDefaultMenu={isDefaultMenu}
            selectedAddOnIds={selectedAddOnIds}
            selectedDrinksIds={selectedDrinksIds}
            calculatePrice={calculatePrice}
          />
        </div>
      </div>
    </div>
  );
}

const MultiSelect = ({
  type,
  options,
  value,
  setValue,
  setIds,
  disabled,
  paxMenu,
  paxIndex,
  cardIndex,
  date,
  selectedMenu,
  isDefaultMenu,
  selectedAddOnIds,
  selectedDrinksIds,
  calculatePrice,
}) => {
  const handleClick = (option) => {
    const ids = option?.map((item) => item.value);
    setValue(option);
    setIds(ids);

    const newArr = paxMenu[`menu[${paxIndex}]`].filter((item) => {
      return item.index !== cardIndex;
    });
    paxMenu[`menu[${paxIndex}]`] = [
      ...newArr,
      {
        index: cardIndex,
        id: selectedMenu.product_id,
        name: selectedMenu.name,
        date: date,
        default: isDefaultMenu,
        add_on: type === "addOn" ? ids : selectedAddOnIds,
        drinks: type === "drinks" ? ids : selectedDrinksIds,
      },
    ];
    calculatePrice();
  };

  const customStyles = {
    dropdownIndicator: (styles) => ({
      ...styles,
      color: "#656C72!important",
    }),
    control: (styles, state) => ({
      ...styles,
      backgroundColor: "#fff",
      borderRadius: 8,
      boxShadow: "none",
      ":hover": {
        ...styles[":hover"],
        border: "1px solid #CBD0D6",
      },
    }),
    valueContainer: (styles) => ({
      ...styles,
      padding: "0.5rem 0.75rem",
      maxHeight: 48,
      overflow: "scroll",
    }),
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: "#fbe5d8",
      };
    },
    multiValueLabel: (styles) => ({
      ...styles,
      // color: data.color,
    }),
    option: (styles, { isDisabled, isSelected }) => ({
      ...styles,
      backgroundColor: isSelected ? "#fbe5d8" : "#fff",
      color: "#1C1C1C",
      cursor: isDisabled ? "not-allowed" : "pointer",
      ":hover": {
        ...styles[":hover"],
        backgroundColor: "#fdf2eb",
      },
    }),
    menu: (styles) => ({
      ...styles,
      margin: "4px 0",
    }),
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Select
        className="multiselect"
        isMulti
        options={options}
        hideSelectedOptions={false}
        onClick={(e) => e.stopPropagation()}
        value={value}
        onChange={(option) => {
          // setValue(option);
          // setIds(option?.map((item) => item.value));
          handleClick(option);
        }}
        formatOptionLabel={(option) => (
          // <div className="flex flex-row items-center">
          //   <img
          //     className="w-[40px] h-[40px] aspect-square object-cover object-center rounded-md overflow-hidden bg-grey"
          //     src={option.image}
          //     alt={option.label}
          //   />
          <div className="flex flex-col ml-2">
            <p className="text-sm sm:text-base">
              {option.label} (
              <span className="text-xs sm:text-sm">RM {option.price}</span>)
            </p>
          </div>
          // </div>
        )}
        styles={customStyles}
        isDisabled={disabled}
      />
    </div>
  );
};
